import React, { useState, useEffect } from "react";
import { Col, Form, Modal, Row } from "antd";
import {
	ClientData,
	Container,
	Inner,
	InnerForm,
	PublishSettings,
} from "./styles";
import LoadingSkeleton from "./loading-skeleton/LoadingSkeleton";
import { CloseCircleOutlined } from "@ant-design/icons";
import ProductStateSelector from "./product-state-selector/ProductStateSelector";
import { update_order_states } from "../../../../../../api/endpoints/orders";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../../utils/notificationToast";
import { Typo } from "../../../../../layout/theme";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import { CONSTANTS } from "../../../../../../utils/constants";

const GUTTER = 10;

export default function UpdateOrder({
	data,
	orderItems,
	checkUpdated,
	closeUpdateModal,
}) {
	const [form] = Form.useForm();
	const [submitting, setSubmitting] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [items, setItems] = useState(null);

	useEffect(() => {
		const fetch_order_by_id = () => {
			const filteredItems = orderItems?.filter((o) => o.orderId === data.id);
			const orderData = {
				...data,
				items: filteredItems,
			};
			setInitialValues(orderData);
			setItems(filteredItems);
		};

		fetch_order_by_id();
	}, []);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);

			const postData = {
				id: data.id,
				productStates: items.map((i) => ({
					id: i.orderId,
					orderNumId: data.orderNumId,
					distributorId: data.distributorId,
					productEan: i.productEan,
					productStateInOrder: i.productStateInOrder,
				})),
			};

			await update_order_states(data.id, postData);

			checkUpdated((prev) => !prev);

			closeUpdateModal(false);

			openNotificationWithIcon(TYPE.SUCCESS, "Se editó con éxito");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al editar",
				"Un error ocurrio al intentar editar, contacte con soporte a soporte@checkpos.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	const handleBack = () => {
		Modal.confirm({
			title: "¿Seguro que quiere volver sin guardar?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content:
				"Si regresa sin guardar se perderán los datos que hayan sido modificados",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				closeUpdateModal(false);
			},
		});
	};

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendiente";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activa";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviada";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Cancelada";
			case CONSTANTS.ORDER_STATE_PROCESSED:
				return "Procesada";
			case CONSTANTS.ORDER_STATE_BILLED:
				return "Facturada";
			case CONSTANTS.ORDER_STATE_REJECTED:
				return "Rechazada";

			default:
				return;
		}
	};

	return (
		<Inner>
			{!initialValues && !items ? (
				<LoadingSkeleton />
			) : (
				<Form
					form={form}
					name="basic"
					onFinish={onFinish}
					autoComplete="off"
					requiredMark={false}
					layout="vertical"
					initialValues={initialValues}
				>
					<InnerForm>
						<ClientData>
							<Row gutter={GUTTER}>
								<Col span={6}>
									<Form.Item label="N° TRF" name="orderNumId" rules={[]}>
										<Typo>{initialValues?.orderNumId}</Typo>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Farmacia" name="pointOfSaleName" rules={[]}>
										<Typo>{initialValues?.pointOfSaleName}</Typo>
									</Form.Item>
								</Col>
								<Col span={10}>
									<Form.Item
										label="Dirección"
										name="pointOfSaleAddress"
										rules={[]}
									>
										<Typo>{initialValues?.pointOfSaleAddress}</Typo>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={GUTTER}>
								<Col span={6}>
									<Form.Item
										label="Droguería"
										name="distributorName"
										rules={[]}
									>
										<Typo>{initialValues?.distributorName}</Typo>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item label="Estado" name="orderState" rules={[]}>
										<Typo
											type={
												initialValues?.orderSubState
													? initialValues?.orderSubState ===
													  CONSTANTS.ORDER_STATE_PROCESSED
														? "secondary"
														: initialValues?.orderSubState ===
														  CONSTANTS.ORDER_STATE_BILLED
														? "terciary"
														: initialValues?.orderSubState ===
														  CONSTANTS.ORDER_STATE_REJECTED
														? "danger"
														: "muted"
													: "mediumGrey"
											}
											level={7}
										>
											{initialValues?.orderSubState
												? selectStateToShow(initialValues?.orderSubState)
												: selectStateToShow(initialValues?.orderState)}
										</Typo>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={GUTTER}>
								<Col span={24}>
									<Form.Item>
										<ProductStateSelector
											statesValues={items}
											setStatesValues={setItems}
											generalState={initialValues?.orderSubState}
										/>
									</Form.Item>
								</Col>
							</Row>
						</ClientData>
						{/* <PublishSettings>
							<Container>
								<Form.Item>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										block
									>
										Guardar Cambios
									</SignInTemplate.AntdButton>
								</Form.Item>
								<SignInTemplate.AntdButton
									type="Primary"
									loading={submitting}
									onClick={handleBack}
									block
								>
									<Typo type="danger" level={6}>
										Volver sin guardar
									</Typo>
								</SignInTemplate.AntdButton>
							</Container>
						</PublishSettings> */}
					</InnerForm>
				</Form>
			)}
		</Inner>
	);
}

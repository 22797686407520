import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import ListCard from "../../common/product-search-cards/list-card/ListCard";
import Image from "../../common/image/Image";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { FaShoppingCart } from "react-icons/fa";
import { COLORS, Typo } from "../../layout/theme";
import { useCart } from "../../../contexts/cartContext";
import {
	Line,
	Loader,
	ItemsQty,
	CardContainer,
	TextSeparatorForPrice,
	TextPriceOff,
	TextOfferContainer,
	MarketingStar,
	SectionOffer,
	TriggerQtyContainer,
	SpecialOfferContainer,
	OffContainer,
	InputContainer,
	CartButton,
	CartButtonsContainer,
	Container,
	MinQtyContainer,
	PslContainer,
	PslText,
	PslDiscount,
} from "./style";
import { useAuth } from "../../../contexts/authContext";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ProductModal from "../../common/product-modal/ProductModal";
import { numberWithDotAndCommas } from "../../../utils/numberWithDotAndComas";
import { IoMegaphoneOutline } from "react-icons/io5";
import moment from "moment";
import { add_view } from "../../../api/endpoints/products";
import { add_user_interaction } from "../../../api/endpoints/users";
import {
	compareBeforeToday,
	compareWithToday,
} from "../../../utils/compareDates";
import { UseAnalyticsEventTracker } from "../../../hooks/useAnalyticsEventTracker";

export default function ProductSearchCardListContainer({
	item,
	dateInfo = false,
	eventCategory = "Test category",
	eventAction = "add_to_cart",
}) {
	const { addToCart } = useCart();
	const [quantity, setQuantity] = useState(
		item.productQtyInCart
			? item.productQtyInCart
			: item.minQuantity
			? item.minQuantity
			: 1
	);
	const [submitting, setSubmitting] = useState(false);
	const [qtyInCart, setQtyInCart] = useState(
		item.productQtyInCart ? item.productQtyInCart : 0
	);
	const [isHover, setIsHover] = useState(false);
	const { userdata } = useAuth();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showAddToCart, setShowAddToCart] = useState(
		item.productQtyInCart ? true : false
	);

	const handleDecrement = async () => {
		setSubmitting(true);
		let res;
		let newQuantity = quantity;
		if (quantity > item.minQuantity && quantity > 1) {
			newQuantity -= 1;
			setQuantity((prev) => prev - 1);
		}
		if (newQuantity <= item.minQuantity) {
			res = await addToCart(item.productId, item.minQuantity, false);
		} else {
			res = await addToCart(item.productId, 1, false);
		}
		if (res === 0) {
			setQtyInCart(res);
			setQuantity(item.minQuantity > 0 ? item.minQuantity : 1);
			item.productQtyInCart = 0;
			setShowAddToCart(false);
		} else {
			setQtyInCart(res);
			setQuantity(res);
			item.productQtyInCart = res;
			setShowAddToCart(true);
		}
		setSubmitting(false);
	};

	const handleChange = (e) => {
		if (e.target.value <= item.minQuantity) {
			e.target.value = item.minQuantity;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= item.maxQuantity) e.target.value = item.maxQuantity;
		const onlyPositiveInteger = /^[0-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			setQuantity(parseInt(e.target.value));
	};

	const handleIncrement = async (e) => {
		setSubmitting(true);
		setQuantity((prev) => prev + 1);
		if (quantity >= item.maxQuantity) {
			setQuantity((prev) => prev - 1);
		} else {
			const res = await addToCart(item.productId, 1);
			setQtyInCart(res);
			setQuantity(res);
			item.productQtyInCart = res;
			setShowAddToCart(true);
			setSubmitting(false);
		}
	};

	const handleAddToCart = async () => {
		setSubmitting(true);
		const res = await addToCart(item.productId, quantity);
		setQtyInCart(res);
		setQuantity(item.minQuantity);
		item.productQtyInCart = res;
		setShowAddToCart(true);
		UseAnalyticsEventTracker(
			eventCategory,
			eventAction,
			userdata.firstName + " " + userdata.lastName
		);
		setSubmitting(false);
	};

	const handleDetail = async () => {
		setIsModalVisible(true);
		if (!userdata?.isSeller && !userdata?.isOneMarket) {
			await add_view(item.productId);
			await add_user_interaction({ productId: item.productId, inCart: false });
		}
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<ListCard>
			<CardContainer>
				<ListCard.SectionContainer>
					<ListCard.LeftContainer onClick={handleDetail}>
						<ListCard.ImageContainer>
							<Image src={item.photoUrl} width={80} height={80} />
							{/* {item.marketingId && (
								<MarketingStar>
									<IoMegaphoneOutline />
								</MarketingStar>
							)} */}
						</ListCard.ImageContainer>
						<ListCard.DataContainer>
							<ListCard.DataSeparator>
								<ListCard.Laboratory>
									<Image src={item.clientPhotoUrl} width="19px" />
									<Typo type="muted" level={7}>
										{item.clientName.toUpperCase()}
									</Typo>
								</ListCard.Laboratory>
								<Container>
									<Typo type="primary" level={5}>
										{item.title}{" "}
										<Typo type="primary" level={5}>
											{item?.presentationName}
										</Typo>
									</Typo>
								</Container>
								{!dateInfo && (
									<Typo type="terciary" level={6}>
										{item?.activeIngredientsName === "No aplica"
											? ""
											: item?.activeIngredientsName}
									</Typo>
								)}
								{dateInfo && (
									<Typo type="terciary" level={5}>
										Fecha de compra: {item?.created?.split("T")[0]}
									</Typo>
								)}
							</ListCard.DataSeparator>
						</ListCard.DataContainer>
					</ListCard.LeftContainer>
					<Modal
						title={
							<div style={{ height: 30 }}>
								<Image
									src={item.clientPhotoUrl}
									style={{ objectFit: "contain", width: 30 }}
								/>{" "}
								<Typo level={8} type="muted">
									{item.clientName.slice(0, 20).toUpperCase()}
								</Typo>
							</div>
						}
						visible={isModalVisible}
						onOk={handleOk}
						onCancel={handleCancel}
						width={900}
						centered={true}
						footer={null}
					>
						<ProductModal
							id={item.productId}
							quantityInCart={qtyInCart}
							setQuantityInCart={setQtyInCart}
							setIsModalVisible={setIsModalVisible}
							minQuantity={item.minQuantity}
							maxQuantity={item.maxQuantity}
							marketing={item.marketingId}
						/>
					</Modal>
					<ListCard.RigthContainer>
						{!userdata.isSeller && userdata.p_shop ? (
							<>
								<ListCard.PriceContainer>
									{item.offerPrice !== item.price &&
									item.offerPrice !== null &&
									item.offerPrice > 0 ? (
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-end",
											}}
										>
											<div
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													width: "100%",
												}}
											>
												<ListCard.Price>
													<TextSeparatorForPrice>
														<Typo level={7} type="muted">
															{item.clientTypeOfSale}
														</Typo>
													</TextSeparatorForPrice>
													<TextSeparatorForPrice>
														<Line level={5} type="primary">
															${numberWithDotAndCommas(item.price)}
														</Line>
													</TextSeparatorForPrice>
												</ListCard.Price>
											</div>
											<ListCard.PriceAndOffer>
												{item?.discount > 0 &&
													item?.offerFrom &&
													item?.offerTo &&
													compareBeforeToday(item?.offerFrom) &&
													compareWithToday(item?.offerTo) && (
														<>
															<TextPriceOff style={{ color: COLORS.Primary }}>
																PSL
															</TextPriceOff>
															<Typo
																level={4}
																type="success"
																style={{ transform: "translateY(2px)" }}
															>
																-
																{numberWithDotAndCommas(
																	item.discount.toFixed(0)
																)}
																%
															</Typo>
														</>
													)}
												{item.clientTypeOfSale === "PVP" &&
													(!item.isComboProduct ? (
														<>
															<TextPriceOff
																style={{ color: COLORS.Primary }}
																translate="no"
															>
																PVP
															</TextPriceOff>
															<Typo
																level={4}
																type="secondary"
																style={{ transform: "translateY(2px)" }}
															>
																-
																{numberWithDotAndCommas(
																	(
																		100 -
																		(item.offerPrice * 100) / item.price
																	).toFixed(2)
																)}
																%
															</Typo>
														</>
													) : (
														<Typo
															level={5}
															type="success"
															style={{ paddingLeft: "10px" }}
														>
															{item.notes}
														</Typo>
													))}
											</ListCard.PriceAndOffer>
										</div>
									) : (
										<ListCard.PriceAlong>
											{!item.isComboProduct ? (
												<>
													<Typo level={5} type="muted">
														{item.clientTypeOfSale}
													</Typo>
													<Typo
														level={4}
														type="secondary"
														style={{ paddingLeft: "10px" }}
													>
														${numberWithDotAndCommas(item.price)}
													</Typo>
												</>
											) : (
												<Typo
													level={5}
													type="success"
													style={{ paddingLeft: "10px" }}
												>
													{item.notes}
												</Typo>
											)}
										</ListCard.PriceAlong>
									)}
									<SectionOffer
										style={{
											backgroundColor:
												item.triggerQty > 0
													? COLORS.Success
													: item.minQuantity > 1
													? COLORS.White
													: COLORS.White,
											marginLeft: 10,
										}}
									>
										{item.triggerQty > 0 ? (
											<TriggerQtyContainer style={{ color: COLORS.White }}>
												Comprando {item.triggerQty} o más
											</TriggerQtyContainer>
										) : item.minQuantity > 1 ? (
											<MinQtyContainer>
												Compra mínima {item.minQuantity} un.
											</MinQtyContainer>
										) : (
											` `
										)}
										{item.triggerQty > 0 && (
											<SpecialOfferContainer
												style={{
													backgroundColor: COLORS.Success,
													paddingRight: 2,
												}}
											>
												<OffContainer
													style={{ color: COLORS.White }}
													translate="no"
												>
													OFF
												</OffContainer>
												<Typo level={6} type="white">
													{`${numberWithDotAndCommas(
														(
															100 -
															((item.offerPrice -
																(item.offerPrice * item.triggerDiscount) /
																	100) *
																100) /
																item.price
														).toFixed(0)
													)}%`}
												</Typo>
											</SpecialOfferContainer>
										)}
									</SectionOffer>
								</ListCard.PriceContainer>
								<ListCard.CartContainer>
									{submitting === true ? (
										<InputContainer>
											<Loader>
												<Spin
													indicator={
														<LoadingOutlined
															style={{
																fontSize: 10,
																color: COLORS.Primary,
																backgroundColor: "transparent",
															}}
															spin
														/>
													}
												/>
											</Loader>
										</InputContainer>
									) : (
										<InputContainer>
											{!showAddToCart ? (
												<CartButton
													type="primary"
													onClick={handleAddToCart}
													loading={submitting}
												>
													<FaShoppingCart style={{ fontSize: "21px" }} />
												</CartButton>
											) : (
												<CartButtonsContainer>
													<Button
														type="primary"
														onClick={handleDecrement}
														style={{ borderRadius: "10px 0 0 10px" }}
													>
														<MinusOutlined />
													</Button>
													<Input
														value={quantity}
														onChange={handleChange}
														style={{
															pointerEvents: "none",
														}}
													/>
													<Button
														type="primary"
														onClick={handleIncrement}
														style={{ borderRadius: "0 10px 10px 0" }}
													>
														<PlusOutlined />
													</Button>
												</CartButtonsContainer>
											)}
										</InputContainer>
									)}

									{qtyInCart > 0 && (
										<ItemsQty>
											{qtyInCart === 1
												? `${qtyInCart} agregado`
												: `${qtyInCart} agregados`}
										</ItemsQty>
									)}
								</ListCard.CartContainer>
							</>
						) : (
							<ListCard.PriceCenter>
								{item.offerPrice !== item.price &&
								item.offerPrice !== null &&
								item.offerPrice > 0 ? (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-end",
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}
										>
											<ListCard.Price>
												<TextSeparatorForPrice>
													<Typo level={7} type="muted">
														{item.clientTypeOfSale}
													</Typo>
												</TextSeparatorForPrice>
												<TextSeparatorForPrice>
													<Line level={4} type="primary">
														${numberWithDotAndCommas(item.price)}
													</Line>
												</TextSeparatorForPrice>
											</ListCard.Price>
										</div>
										<ListCard.PriceAndOffer>
											{item?.discount > 0 &&
												item?.offerFrom &&
												item?.offerTo &&
												compareBeforeToday(item?.offerFrom) &&
												compareWithToday(item?.offerTo) && (
													<>
														<TextPriceOff style={{ color: COLORS.Primary }}>
															PSL
														</TextPriceOff>
														<Typo
															level={4}
															type="success"
															style={{ transform: "translateY(2px)" }}
														>
															-
															{numberWithDotAndCommas(item.discount.toFixed(0))}
															%
														</Typo>
													</>
												)}
											{item.clientTypeOfSale === "PVP" &&
												(!item.isComboProduct ? (
													<>
														<TextPriceOff
															style={{ color: COLORS.Primary }}
															translate="no"
														>
															PVP
														</TextPriceOff>
														<Typo
															level={4}
															type="secondary"
															style={{ transform: "translateY(2px)" }}
														>
															-
															{numberWithDotAndCommas(
																(
																	100 -
																	(item.offerPrice * 100) / item.price
																).toFixed(2)
															)}
															%
														</Typo>
													</>
												) : (
													<Typo
														level={5}
														type="success"
														style={{ paddingLeft: "10px" }}
													>
														{item.notes}
													</Typo>
												))}
										</ListCard.PriceAndOffer>
									</div>
								) : (
									<ListCard.PriceAlong style={{ display: "flex" }}>
										{!item.isComboProduct ? (
											<>
												<Typo level={5} type="muted">
													{item.clientTypeOfSale}
												</Typo>
												<Typo
													level={4}
													type="secondary"
													style={{ paddingLeft: "10px" }}
												>
													${numberWithDotAndCommas(item.price)}
												</Typo>
											</>
										) : (
											<Typo
												level={5}
												type="success"
												style={{ paddingLeft: "10px" }}
											>
												{item.notes}
											</Typo>
										)}
									</ListCard.PriceAlong>
								)}
								<SectionOffer
									style={{
										backgroundColor:
											item.triggerQty > 0
												? COLORS.Success
												: item.minQuantity > 1
												? COLORS.White
												: COLORS.White,
										marginLeft: 10,
										width: "75%",
									}}
								>
									{item.triggerQty > 0 ? (
										<TriggerQtyContainer style={{ color: COLORS.White }}>
											Comprando {item.triggerQty} o más
										</TriggerQtyContainer>
									) : item.minQuantity > 1 ? (
										<MinQtyContainer>
											Compra mínima {item.minQuantity} un.
										</MinQtyContainer>
									) : (
										` `
									)}
									{item.triggerQty > 0 && (
										<SpecialOfferContainer
											style={{
												backgroundColor: COLORS.Success,
												paddingRight: 2,
											}}
										>
											<OffContainer
												style={{ color: COLORS.White }}
												translate="no"
											>
												OFF
											</OffContainer>
											<Typo level={6} type="white">
												{`${numberWithDotAndCommas(
													(
														100 -
														((item.offerPrice -
															(item.offerPrice * item.triggerDiscount) / 100) *
															100) /
															item.price
													).toFixed(0)
												)}%`}
											</Typo>
										</SpecialOfferContainer>
									)}
								</SectionOffer>
							</ListCard.PriceCenter>
						)}
					</ListCard.RigthContainer>
				</ListCard.SectionContainer>
			</CardContainer>
		</ListCard>
	);
}

import React, { useState } from "react";
import { AntdModal } from "./style";
import PublicitySlide from "../../../../../common/slides/publicity-slide/PublicitySlide";
import FeaturedGroupModal from "../featured-group-modal/FeaturedGroupModal";
import Image from "../../../../../common/image/Image";
import { useAuth } from "../../../../../../contexts/authContext";
import { add_user_interaction } from "../../../../../../api/endpoints/users";

export default function FeaturedGroupSlideContainer({
	id,
	data,
	bannerHeight,
	bannerBorder = 0,
	bannerFit = "cover",
	bannerType = null,
}) {
	const { userdata } = useAuth();
	const [showHotSaleModal, setShowHotSaleModal] = useState(false);

	const handleClick = async () => {
		if (data.products) setShowHotSaleModal(true);
		if (!userdata?.isSeller && !userdata?.isOneMarket && bannerType) {
			await add_user_interaction({
				productId: id,
				inCart: false,
				InteractionType: bannerType,
			});
		}
	};

	const handleOk = () => {
		setShowHotSaleModal(false);
	};

	const handleCancel = () => {
		setShowHotSaleModal(false);
	};

	return (
		<>
			<PublicitySlide onClick={handleClick} bannerHeight={bannerHeight}>
				<PublicitySlide.TopContainer bannerHeight={bannerHeight}>
					<Image
						src={data?.src}
						height="100%"
						width="100%"
						style={{
							objectFit: bannerFit,
							borderRadius: bannerBorder,
						}}
					/>
				</PublicitySlide.TopContainer>
			</PublicitySlide>
			<AntdModal
				title={`Destacado: ${data.title}`}
				visible={showHotSaleModal}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1200}
				centered={true}
				footer={
					null
					// <FeaturedGroupModalFooter
					// 	featuredTitle={data.title}
					// 	featuredConditions={null}
					// />
				}
				destroyOnClose={true}
			>
				<FeaturedGroupModal data={data} />
			</AntdModal>
		</>
	);
}

import React from "react";
import Layout from "../../../../layout/base/Layout";
import Image from "../../../../common/image/Image";
import { Badge, Col, Tooltip } from "antd";
import { COLORS, SIZES, Typo } from "../../../../layout/theme";
import { RiBookletFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { IconContainer } from "./styles";
import MarketLogo from "../../../../../assets/images/Onetransfer-logo.png";
import HotSaleIcon from "../../../../../assets/images/fire-solid.svg";
import CartIcon from "../../../../../assets/images/cart-shopping-solid.svg";
import MenuIcon from "../../../../../assets/images/bars-solid.svg";
import SearchProducts from "../../../../common/search-products/SearchProducts";

export default function PharmacyHeader({
	scrollDirection,
	userdata,
	campaignRegion,
	location,
	bookQty,
	qty,
	setShowDrawer,
	showDrawer,
	browserInfo,
}) {
	return (
		<Layout.LayoutHeader
			justify="space-evenly"
			align="middle"
			scroll={scrollDirection}
		>
			<Col md={3} lg={3} xl={3}>
				<Link to="/">
					<Image src={MarketLogo} alt="Logo Market" height={45} />
				</Link>
			</Col>
			<Col md={7} lg={7} xl={7}>
				<Layout.LayoutHeaderOptions
					style={{
						gap: "20px",
					}}
				>
					{campaignRegion && campaignRegion.totalCampaigns > 0 && (
						<Link to="/hotSale">
							<Layout.HeaderActionParagraph>
								<Tooltip title="Hot Sale">
									<IconContainer src={HotSaleIcon} alt="HotSale" />
								</Tooltip>
							</Layout.HeaderActionParagraph>
						</Link>
					)}
					<Link to="/offers">
						<Layout.HeaderActionParagraph>
							{location?.pathname.includes("/offers") ? (
								<Typo level={6} type="primary" hover="primary">
									Ofertas
								</Typo>
							) : (
								<Typo level={6} type="muted" hover="primary">
									Ofertas
								</Typo>
							)}
						</Layout.HeaderActionParagraph>
					</Link>
					<Link to="/search">
						<Layout.HeaderActionParagraph>
							{location?.pathname.includes("/search") ? (
								<Typo level={6} type="primary" hover="primary">
									Catálogo
								</Typo>
							) : (
								<Typo level={6} type="muted" hover="primary">
									Catálogo
								</Typo>
							)}
						</Layout.HeaderActionParagraph>
					</Link>
					<Link to="/stores">
						<Layout.HeaderActionParagraph>
							{location?.pathname.includes("/stores") ? (
								<Typo level={6} type="primary" hover="primary">
									Tiendas
								</Typo>
							) : (
								<Typo level={6} type="muted" hover="primary">
									Tiendas
								</Typo>
							)}
						</Layout.HeaderActionParagraph>
					</Link>
				</Layout.LayoutHeaderOptions>
			</Col>

			<Col md={6} lg={6} xl={8}>
				<SearchProducts location={location?.pathname} />
			</Col>

			<Col md={3} lg={3} xl={3}>
				{userdata.p_products && userdata.p_shop && (
					<div
						style={{
							display: "flex",
							gap: "10px",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{/* <Link to="/book">
							<Layout.HeaderAction
								style={{
									width: "60px",
									position: "relative",
								}}
							>
								<Layout.HeaderIcon>
									<Badge
										count={bookQty}
										offset={[10, 4]}
										size="large"
										color={COLORS.Default}
									>
										<div
											style={{
												fontSize: "30px",
												position: "relative",
												top: "3px",
											}}
										>
											<RiBookletFill
												style={{
													color: COLORS.Primary,
												}}
											/>
										</div>
										<div
											style={{
												position: "absolute",
												fontSize: SIZES.level8,
												color: COLORS.Primary,
												top: "40px",
												left: "-8px",
											}}
										>
											Reposición
										</div>
									</Badge>
								</Layout.HeaderIcon>
							</Layout.HeaderAction>
						</Link> */}
						<Link to="/cart">
							<Layout.HeaderAction
								style={{
									width: "60px",
									marginLeft: "10px",
								}}
							>
								<Layout.HeaderIcon>
									<Badge
										count={qty}
										offset={[-14, -7]}
										size="large"
										color={COLORS.Default}
									>
										<Image
											src={CartIcon}
											alt="Cart"
											style={{
												width: "50%",
											}}
										/>
									</Badge>
								</Layout.HeaderIcon>
							</Layout.HeaderAction>
						</Link>
					</div>
				)}
			</Col>
			<Col md={2} lg={2} xl={2}>
				<Layout.HeaderAction
					onClick={() => setShowDrawer(!showDrawer)}
					style={{
						width: "100px",
					}}
				>
					<Layout.HeaderMenuIcon>
						<Layout.HeaderMenuIconContainer firefox={browserInfo.firefox}>
							<Image
								src={MenuIcon}
								alt="Menu"
								style={{
									height: "50%",
									width: "50%",
									marginLeft: "10px",
								}}
							/>
							<Layout.HeaderMenuParagraph>Menú</Layout.HeaderMenuParagraph>
						</Layout.HeaderMenuIconContainer>
					</Layout.HeaderMenuIcon>
				</Layout.HeaderAction>
			</Col>
		</Layout.LayoutHeader>
	);
}

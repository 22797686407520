import axiosInstance from "../axiosInstance";

const CONTROLLER = "banner";

const get_banners = async (search, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}?search=${search || ""}&state=${state}`
	);
	return response.data;
};

const get_banner_by_id = async (id) => {
	const response = await axiosInstance.get(`${CONTROLLER}/GetBannerById/${id}`);
	return response.data;
};

const update_banner = async (id, data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/${id}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_banner_state = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateBannerState`,
		state
	);
	return response.data;
};

const create_banner = async (data) => {
	const response = await axiosInstance.post(`${CONTROLLER}`, data, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

const update_banner_url = async (bannerUrl) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateBannerUrl`,
		bannerUrl
	);
	return response.data;
};

const delete_banner = async (data) => {
	const response = await axiosInstance.put(`${CONTROLLER}/DeleteBanner`, data);
	return response.data;
};

const get_market_banners_report = async (
	term,
	skip,
	take,
	monthToSearch,
	yearToSearch
) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketBannersReport`,
		{
			params: {
				searchTerm: term,
				skip: skip,
				take: take,
				monthToSearch: monthToSearch,
				yearToSearch: yearToSearch,
			},
		}
	);
	return response.data;
};

const update_banner_index_order = async (orderData) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateBannerIndexOrder`,
		orderData
	);
	return response.data;
};

const get_banners_by_client_id = async (clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetBannerByClientId`,
		{
			params: {
				clientId: clientId,
				state: state,
			},
		}
	);
	return response.data;
};

const get_banner_by_client_id_header_info = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetBannerByClientIdHeader`
	);
	return response.data;
};

const create_banner_request = async (data) => {
	const response = await axiosInstance.post(
		`${CONTROLLER}/CreateBannerRequest`,
		data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}
	);
	return response.data;
};

const get_market_banners_request = async (clientId, state) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketBannerRequests`,
		{
			params: {
				clientId: clientId,
				state: state,
			},
		}
	);
	return response.data;
};

const get_market_banner_request_header_info = async () => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetMarketBannerRequestHeader`
	);
	return response.data;
};

const update_banner_request = async (state) => {
	const response = await axiosInstance.put(
		`${CONTROLLER}/UpdateBannerRequest`,
		state
	);
	return response.data;
};

export {
	get_banners,
	get_banner_by_id,
	update_banner,
	update_banner_state,
	create_banner,
	update_banner_url,
	delete_banner,
	get_market_banners_report,
	update_banner_index_order,
	get_banners_by_client_id,
	get_banner_by_client_id_header_info,
	create_banner_request,
	get_market_banners_request,
	get_market_banner_request_header_info,
	update_banner_request,
};

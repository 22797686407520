import { Typography } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ButtonContainer, Container, Inner, Section } from "./styles";
import Main from "../../../layout/main/Main";
import { COLORS } from "../../../layout/theme";
import ModuleMarketplace from "./components/module-marketplace/ModuleMarketplace";

const { Title, Paragraph } = Typography;

export default function GeneralConfig({
	userdata,
	clientId,
	clientContract,
	clientName,
}) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Configuración General
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
				<Container>
					<Section>
						<Title style={{ color: COLORS.Primary, marginTop: 30 }} level={3}>
							Configuración General
						</Title>
						<Paragraph>
							<blockquote>
								En esta sección podrás configurar el sistema
							</blockquote>
						</Paragraph>
					</Section>
					<ButtonContainer></ButtonContainer>
				</Container>
			</Main.Header>

			<Inner>
				<ModuleMarketplace
					clientContract={clientContract}
					clientName={clientName}
				/>
			</Inner>
		</Main>
	);
}

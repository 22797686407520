import React, { useEffect, useState } from "react";
import { Form, message, Steps } from "antd";
import {
	Link,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import {
	InnerForm,
	NewMarketing,
	InfoContainer,
	Inner,
	DataContainer,
	StepsContainer,
	AntdSteps,
	IconContainer,
	StepContainer,
	TitleContainer,
} from "./styles";
import moment from "moment";
import {
	get_expo_event_by_id,
	update_expo_event,
} from "../../../../../api/endpoints/event";
import AddProductsTables from "../../../../common/add-products-tables/AddProductsTables";
import { get_provinces } from "../../../../../api/endpoints/region";
import { get_clients_name_list_filtered_expo } from "../../../../../api/endpoints/clients";
import { useAuth } from "../../../../../contexts/authContext";
import EventBasicInfo from "./components/event-basic-info/EventBasicInfo";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import Number1 from "../../../../../assets/images/number-one-icon.svg";
import Number1Filled from "../../../../../assets/images/number-one-solid.svg";
import Number2Grey from "../../../../../assets/images/number-two.svg";
import Number2Filled from "../../../../../assets/images/number-two-solid.svg";
import Number3Grey from "../../../../../assets/images/number-three-icon.svg";
import Number3Filled from "../../../../../assets/images/number-three-icon-selected.svg";
import EventProductsDiscounts from "./components/event-products-discounts/EventProductsDiscounts";

const { Step } = Steps;

export default function MarketUpdateExpoEvent() {
	const [form] = Form.useForm();
	const { id } = useParams();
	const { user } = useAuth();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [prodsForEvent, setProdsForEvent] = useState([]);
	const [imageUrl, setImageUrl] = useState();
	const [imageToSend, setImageToSend] = useState(null);
	const [initialValues, setInitialValues] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [clients, setClients] = useState(null);
	const [idProvincia, setIdProvincia] = useState(null);
	const [listadoProvincias, setListadoProvincias] = useState(null);
	const [listadoLocalidades, setListadoLocalidades] = useState(null);
	const [provincia, setProvincia] = useState(null);
	const [localidad, setLocalidad] = useState();
	const [submitting, setSubmitting] = useState(false);
	const [updateEventSteps, setUpdateEventSteps] = useState(1);
	const [hasChanges, setHasChanges] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list_filtered_expo();
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	useEffect(() => {
		const fetch_region_1_data = async () => {
			const res = await get_provinces();

			setListadoProvincias(
				res
					.map((p) => ({
						id: p.id,
						name: p.title,
						localities: p.localities.map((l) => ({
							localityId: l.locality.id,
							localityName: l.locality.title,
						})),
					}))
					.sort((a, b) => {
						const titleA = a.name.toLowerCase();
						const titleB = b.name.toLowerCase();
						if (titleA < titleB) {
							return -1;
						}
						if (titleA > titleB) {
							return 1;
						}

						return 0;
					})
			);

			setListadoLocalidades(res.filter((p) => p.id === idProvincia));
		};

		fetch_region_1_data();
	}, [idProvincia]);

	const handleChange = (value) => {
		if (value === "...") {
			setSelectedClient(null);
		} else {
			setSelectedClient(value);
		}
		setProdsForEvent([]);
	};

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const disabledDate = (current) => {
		return current && current < moment().add(-1, "days").endOf(-1, "day");
	};

	const fetch_expo_event_by_id = async (id) => {
		const res = await get_expo_event_by_id(id);
		const init = {
			title: res.title,
			publish: [moment(res.publishFrom), moment(res.publishTo)],
			products: res.products,
		};
		setInitialValues(init);
		setImageUrl(res.photoUrl);
		setProdsForEvent(
			res.products.map((i) => ({
				id: i.productId,
				title: i.productTitle,
				presentationName: i.productPresentation,
				ean13: i.productEan13,
				discount: i.productDiscount,
				triggerDiscount: i.productTriggerDiscount,
				triggerQty: i.productTriggerQty,
				photoUrl: i.productPhotoUrl,
				key: i.productId,
				price: i.productPrice,
				clientGeneralDiscount: i.clientGeneralDiscount,
			}))
		);
		setSelectedClient(res.clientId);

		setProvincia(
			res.provinceId
				? {
						key: res.provinceId,
						value: res.provinceId,
						label: res.provinceName,
				  }
				: null
		);

		setLocalidad(
			res.localityId
				? [
						{
							key: res.localityId,
							value: res.localityId,
							label: res.localityName,
						},
				  ]
				: undefined
		);
		setIdProvincia(res.provinceId ? res.provinceId : null);
	};

	useEffect(() => {
		fetch_expo_event_by_id(id);
	}, []);

	const handleImageChange = (info) => {
		try {
			const isJpgOrPng =
				info.file.type === "image/jpeg" || info.file.type === "image/png";

			if (!isJpgOrPng) {
				message.error("You can only upload JPG/PNG file!");
			}

			const isLt2M = info.file.size / 1024 / 1024 < 2;

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!");
			}

			if (!isJpgOrPng || !isLt2M) return;

			getBase64(
				info.fileList[info.fileList.length - 1].originFileObj,
				(url) => {
					setImageUrl(url);
				}
			);
			setImageToSend(info.fileList[info.fileList.length - 1].originFileObj);
		} catch (error) {
			console.log(error);
		}
	};

	const onFinish = async (values) => {
		try {
			const postData = {
				title: initialValues?.title,
				clientId: selectedClient,
				photoUrl: imageUrl,
				products: prodsForEvent.map((p) => ({
					productId: p.id,
					productDiscount: parseFloat(p.discount),
					productTriggerQty: parseInt(p.triggerQty),
					productTriggerDiscount: parseFloat(p.triggerDiscount),
				})),
				publishFrom: initialValues.publish[0].format("YYYY-MM-DD"),
				publishTo: initialValues.publish[1].format("YYYY-MM-DD"),
				region: localidad?.value
					? `ARG-${idProvincia}-${localidad.value}`
					: `ARG-${idProvincia}-`,
				provinceId: idProvincia,
				localityId: localidad?.value ?? null,
			};

			setSubmitting(true);

			const formData = new FormData();

			formData.append("data", JSON.stringify(postData));
			formData.append("file", imageToSend);

			const res = await update_expo_event(id, formData);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Evento actualizado con exito",
				"El evento se actualizo con exito"
			);
			navigate("/admin/expoEventOT?uri=" + uri);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(
				TYPE.ERROR,
				"Error al actualizar el evento",
				"Un error ocurrio al intentar actualizar el evento, contacte con soporte a soporte@onetransfer.com."
			);
		} finally {
			setSubmitting(false);
		}
	};

	if (!initialValues) return <p>Cargando...</p>;

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.BreadcrumbItem>
						<Link to={"/admin/expoEventOT?uri=" + uri}>Eventos</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Actualizar Evento
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<StepsContainer>
				<AntdSteps current={updateEventSteps} onChange={setUpdateEventSteps}>
					<Step
						icon={
							<IconContainer
								src={updateEventSteps === 0 ? Number1Filled : Number1}
								alt="Step 0"
								style={{ width: 30 }}
							/>
						}
						title={
							<StepContainer>
								<TitleContainer>Datos</TitleContainer>
							</StepContainer>
						}
					/>
					<Step
						icon={
							<IconContainer
								src={updateEventSteps === 1 ? Number2Filled : Number2Grey}
								alt="Number1"
								style={{ width: 30 }}
							/>
						}
						title={
							<StepContainer>
								<TitleContainer>Productos</TitleContainer>
							</StepContainer>
						}
					/>
					<Step
						icon={
							<IconContainer
								src={updateEventSteps === 2 ? Number3Filled : Number3Grey}
								alt="Step 3"
								style={{ width: 30 }}
							/>
						}
						title={
							<StepContainer>
								<TitleContainer>Descuentos</TitleContainer>
							</StepContainer>
						}
					/>
				</AntdSteps>
			</StepsContainer>

			<Main.Body>
				<NewMarketing>
					<Form
						form={form}
						name="create-product"
						onFinish={onFinish}
						autoComplete="off"
						requiredMark={false}
						layout="vertical"
						initialValues={initialValues}
					>
						<InnerForm>
							<Inner>
								{updateEventSteps === 0 && (
									<DataContainer>
										<EventBasicInfo
											form={form}
											idProvincia={idProvincia}
											provincia={provincia}
											localidad={localidad}
											setLocalidad={setLocalidad}
											setProvincia={setProvincia}
											setIdProvincia={setIdProvincia}
											listadoLocalidades={listadoLocalidades}
											listadoProvincias={listadoProvincias}
											handleChange={handleChange}
											selectedClient={selectedClient}
											handleImageChange={handleImageChange}
											imageUrl={imageUrl}
											clients={clients}
											disabledDate={disabledDate}
										/>
									</DataContainer>
								)}
								{updateEventSteps === 1 && (
									<InfoContainer>
										<AddProductsTables
											templateProducts={prodsForEvent}
											setTemplateProducts={setProdsForEvent}
											userId={user.uid}
											laboratoryId={selectedClient}
											isExpoSteps={true}
											includeCombo={false}
										/>
									</InfoContainer>
								)}
								{updateEventSteps === 2 && (
									<DataContainer>
										<EventProductsDiscounts
											clientId={selectedClient}
											templateProducts={prodsForEvent}
											setTemplateProducts={setProdsForEvent}
											hasChanges={hasChanges}
											setHasChanges={setHasChanges}
										/>
									</DataContainer>
								)}
							</Inner>
							{updateEventSteps === 0 && (
								<DataContainer style={{ alignItems: "center" }}>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										onClick={() => setUpdateEventSteps(1)}
										loading={submitting}
										style={{ width: "200px" }}
									>
										Agregar productos
									</SignInTemplate.AntdButton>
								</DataContainer>
							)}
							{updateEventSteps === 1 && (
								<DataContainer style={{ alignItems: "center" }}>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										onClick={() => setUpdateEventSteps(2)}
										loading={submitting}
										style={{ width: "200px" }}
									>
										Siguiente paso
									</SignInTemplate.AntdButton>
								</DataContainer>
							)}
							{updateEventSteps === 2 && hasChanges && (
								<DataContainer style={{ alignItems: "center" }}>
									<SignInTemplate.AntdButton
										type="Primary"
										color="white"
										bg="Primary"
										htmlType="submit"
										loading={submitting}
										style={{ width: "200px" }}
									>
										Guardar cambios
									</SignInTemplate.AntdButton>
								</DataContainer>
							)}
						</InnerForm>
					</Form>
				</NewMarketing>
			</Main.Body>
		</Main>
	);
}

import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import Main from "../../../layout/main/Main";
import LoadingHeader from "./components/loading-header/LoadingHeader";
import { Button, DatePicker, Space } from "antd";
import { CompaginationDiv, Filters, TableContainer } from "./styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { export_products_report } from "../../../../api/endpoints/products";
import moment from "moment";
import { get_market_banners_report } from "../../../../api/endpoints/banner";
import ReportBannersTable from "./components/report-banners-table/ReportBannersTable";
import { Typo } from "../../../layout/theme";

export default function ReportBanners() {
	const [data, setData] = useState(null);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [loading, setLoading] = useState(false);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(300);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [dateToSearch, setDateToSearch] = useState(
		moment().format("DD/MM/YYYY")
	);
	const [fileDate, setFileDate] = useState(moment().format("MMMM-YYYY"));
	const [monthToSearch, setMonthToSearch] = useState(moment().month() + 1);
	const [yearToSearch, setYearToSearch] = useState(moment().year());

	const fetch_banners = async (search = "", skipValue = null) => {
		setLoading(true);
		try {
			const res = await get_market_banners_report(
				search,
				skipValue !== null ? skipValue : skip,
				take,
				monthToSearch,
				yearToSearch
			);

			setData(res?.groupedBanners);

			setHeaderInfo(true);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetch_banners();
	}, [dateToSearch]);

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - 100;

			fetch_banners(searchTerm, skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - 100);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + 100;

		fetch_banners(searchTerm, skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + 100);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearchTerm(e.target.value);
		fetch_banners(e.target.value);
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 250), []);

	const handleDateChange = (date) => {
		setDateToSearch(date.format("DD/MM/YYYY").toString());
		setMonthToSearch(moment(date).month() + 1);
		setYearToSearch(moment(date).year());
		setFileDate(date.format("MMMM-YYYY").toString());
	};

	const fetch_export_report = async () => {
		setLoading(true);
		try {
			const res = await export_products_report({
				bannerReportToExport: data,
			});
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const _fileName = "BannersReport_" + fileDate + ".xlsx";
			link.setAttribute("download", _fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Reporte Banners</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>

			<Main.Body>
				<Filters>
					{!headerInfo ? (
						<LoadingHeader />
					) : (
						<Space size="large">
							<DatePicker picker="month" onChange={handleDateChange} />
							{/* <DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="Default"
									bg="Default"
									color="White"
									icon={<CloudDownloadOutlined />}
									onClick={fetch_export_report}
									loading={loading}
									style={{ width: "200px", fontWeight: "400" }}
								>
									Exportar reporte
								</SignInTemplate.AntdButton>
							</DropShadow> */}
						</Space>
					)}
				</Filters>
				{data && data.length === 0 && (
					<Typo level={6} type="primary">
						No hay resultados para la fecha seleccionada
					</Typo>
				)}
				<TableContainer>
					{data &&
						data.map((banners) => {
							const groupData = [
								{
									title: banners.title,
									totalCount: banners.totalCount,
								},
							];

							return (
								<ReportBannersTable
									data={groupData}
									expandableData={banners.bannersReport}
								/>
							);
						})}
				</TableContainer>
				{data && data.length > 0 && (
					<CompaginationDiv>
						{currentPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						{data.length === 100 && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={data.length < 100 ? true : false}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</Main.Body>
		</Main>
	);
}

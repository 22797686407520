import moment from "moment";
import { COLORS, Typo } from "../../../../layout/theme";
import { AntdTable, Container } from "./styles";
import { Spin } from "antd";

export default function PreviewTable({ data, isLoading }) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Orden</div>,
			dataIndex: "orderNumMobile",
			width: 90,
			fixed: "left",
			render: (value) => (
				<Typo type="terciary" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Farmacia</div>,
			width: 120,
			dataIndex: "pharmacyCuit",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Empresa</div>,
			dataIndex: "productClient",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean</div>,
			width: 140,
			dataIndex: "productEan13",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			render: (value) => (
				<div
					style={{
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
				>
					<Typo type="muted" fontWeight={600} fontSize={14}>
						{value}
					</Typo>
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Presentacion</div>,
			width: 120,
			dataIndex: "productPresentation",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>TRF</div>,
			width: 80,
			dataIndex: "unitsTRF",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Fact</div>,
			width: 80,
			dataIndex: "unitsBilled",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Pend</div>,
			width: 80,
			dataIndex: "unitsPending",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Estado</div>,
			dataIndex: "state",
			width: 120,
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14} translate="no">
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Creado</div>,
			dataIndex: "created",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(value).format("DD/MM/YYYY")}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Modificado</div>,
			dataIndex: "lastUpdate",
			render: (value) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(value).format("DD/MM/YYYY")}
				</Typo>
			),
		},
	];

	return (
		<Container>
			{data ? (
				<AntdTable
					columns={columns}
					dataSource={data.items}
					pagination={false}
					loading={isLoading}
					scroll={{
						y: 450,
					}}
				/>
			) : (
				<Spin />
			)}
		</Container>
	);
}

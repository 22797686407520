import React, { useEffect, useMemo, useState } from "react";
import {
	CompaginationDiv,
	Container,
	FilterContainer,
	GridContainer,
	Inner,
	ResultContainer,
} from "./styles";
import StoresSearchCardContainer from "../../../../containers/stores-search-cards/StoresSearchCardContainer";
import { get_stores_with_contracts } from "../../../../../api/endpoints/stores";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import debounce from "lodash/debounce";
import { CONSTANTS } from "../../../../../utils/constants";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactGA from "react-ga4";

export default function Stores() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("ALL");
	const [skip, setSkip] = useState(0);
	const [page, setPage] = useState(CONSTANTS.PAGE_DOBLE_BIG_SIZE);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(null);
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/stores",
			title: "Tiendas",
		});
	}, []);

	const fetch_stores = async (skipValue) => {
		setLoading(true);
		const res = await get_stores_with_contracts(search, skipValue, page);
		if (res) {
			setLoading(false);
			setData(res[0]?.laboratoryStore);
			setTotalCount(res[0]?.laboratoryStore?.length);
		}
	};

	const handleDecrement = () => {
		if (currentPage > 1) {
			const cp = currentPage - 1;
			const skipValue = skip - CONSTANTS.PAGE_MEDIUM_SIZE;

			fetch_stores(skipValue);

			setCurrentPage(cp);
			setSkip((prev) => prev - CONSTANTS.PAGE_MEDIUM_SIZE);
		}
	};

	const handleIncrement = () => {
		const cp = currentPage + 1;
		const skipValue = skip + CONSTANTS.PAGE_MEDIUM_SIZE;

		fetch_stores(skipValue);

		setCurrentPage(cp);
		setSkip((prev) => prev + CONSTANTS.PAGE_MEDIUM_SIZE);
	};

	useEffect(() => {
		setSkip(0);
		setPage(CONSTANTS.PAGE_MEDIUM_SIZE);
		setCurrentPage(1);
		fetch_stores(0);
	}, [search]);

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		setSearch(e.target.value || "ALL");
	};

	const debouncedEventHandler = useMemo(() => debounce(eventHandler, 650), []);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (!data) return <LoadingSkeleton />;

	return (
		<Inner>
			<ResultContainer>
				<GridContainer>
					{data &&
						data.length > 0 &&
						data.map((s) => <StoresSearchCardContainer key={s.id} item={s} />)}
				</GridContainer>
				{data && data.length > 0 && (
					<CompaginationDiv>
						{currentPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						{data.length >= CONSTANTS.PAGE_MEDIUM_SIZE ? (
							<div style={{ display: "flex", alignItems: "center" }}>
								<span>
									{/* {currentPage * CONSTANTS.PAGE_MEDIUM_SIZE > totalCount
											? totalCount
											: currentPage * CONSTANTS.PAGE_MEDIUM_SIZE}
										/ {totalCount} */}
									{currentPage}
								</span>
							</div>
						) : (
							<></>
						)}
						{data.length === CONSTANTS.PAGE_MEDIUM_SIZE && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									data.length < CONSTANTS.PAGE_MEDIUM_SIZE ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
			</ResultContainer>
		</Inner>
	);
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
	CalendarButton,
	RadioOptions,
	CompaginationDiv,
	AntdInput,
	Inner,
	Container,
	ActionButton,
	AntdModal,
} from "./styles";
import { Button, DatePicker, Dropdown, Menu, Modal, Select } from "antd";
import {
	CloseCircleOutlined,
	LeftOutlined,
	RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import OrdersTable from "./components/orders-table/OrdersTable";
import Main from "../../../layout/main/Main";
import { BsThreeDotsVertical } from "react-icons/bs";
import UpdateOrder from "./components/update-order/UpdateOrder";
import { useFilter } from "../../../../contexts/filterContext";
import { CONSTANTS } from "../../../../utils/constants";
import { COLORS, Typo } from "../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";
import { update_order_states } from "../../../../api/endpoints/orders";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import {
	get_order_statuses_admin_CT,
	get_order_statuses_CT,
} from "../../../../api/endpoints/sales";

const { confirm } = Modal;

export default function ModuleOrderStatesCheckpos({
	userdata,
	clientContract,
	clientId,
}) {
	const { ordersLabSellerId, setOrdersLabSellerId } = useFilter();
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [orderDetails, setOrderDetails] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [headerInfo, setHeaderInfo] = useState(null);
	const [laboratoryId, setLaboratoryId] = useState(clientId);
	const [distributorId, setDistributorId] = useState(null);
	const [pharmacyId, setPharmacyId] = useState(null);
	const [provinceId, setProvinceId] = useState(null);
	const [typeOfSale, setTypeOfSale] = useState(null);
	const [orderState, setOrderState] = useState(null);
	const [dateToSearch, setDateToSearch] = useState(null);
	const [page, setPage] = useState(CONSTANTS.PAGE_LARGE_SIZE);
	const [actualPage, setActualPage] = useState(1);
	const [paginationInputValue, setPaginationInputValue] = useState(null);
	const [searchInfo, setSearchInfo] = useState(null);
	const [users, setUsers] = useState(null);
	const [orderTypes, setOrderTypes] = useState(null);
	const [distributors, setDistributors] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [orderStates, setOrderStates] = useState(null);
	const [key, setKey] = useState(null);
	const [orderItems, setOrderItems] = useState([]);
	const [orderUpdated, setOrderUpdated] = useState(false);
	const [showUpdateOrderModal, setShowUpdateOrderModal] = useState(false);

	const columns = [
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						N° TRF
					</div>
				);
			},
			dataIndex: "orderNumId",
			render: (f, record) => (
				<Typo
					type="terciary"
					fontWeight={600}
					fontSize={14}
					onMouseOver={() => setKey(record)}
					onClick={handleUpdateOrderClick}
					style={{ cursor: "pointer" }}
				>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Fecha
					</div>
				);
			},
			dataIndex: "created",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{moment(f).format("DD/MM/YYYY")}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Farmacia
					</div>
				);
			},
			dataIndex: "pointOfSaleName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Dirección
					</div>
				);
			},
			dataIndex: "pointOfSaleAddress",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Provincia
					</div>
				);
			},
			dataIndex: "provinceName",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Items
					</div>
				);
			},
			dataIndex: "qtyItems",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Un.
					</div>
				);
			},
			dataIndex: "qtyUnits",
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Droguería
					</div>
				);
			},
			dataIndex: "distributorName",
			render: (f, record) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					{f}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Total
					</div>
				);
			},
			dataIndex: "total",
			width: 130,
			render: (f) => (
				<Typo type="muted" fontWeight={600} fontSize={14}>
					$ {numberWithDotAndCommas(f)}
				</Typo>
			),
		},
		{
			title: () => {
				return (
					<div
						style={{
							color: COLORS.White,
						}}
					>
						Estado
					</div>
				);
			},
			dataIndex: "orderState",
			render: (fila, record) => (
				<>
					{record.orderSubState ? (
						<>
							{record.orderSubState === "Processed" && (
								<Typo type="secondary" fontWeight={600} fontSize={14}>
									Procesada
								</Typo>
							)}
							{record.orderSubState === "Billed" && (
								<Typo type="success" fontWeight={600} fontSize={14}>
									Facturada
								</Typo>
							)}
							{record.orderSubState === "Rejected" && (
								<Typo type="danger" fontWeight={600} fontSize={14}>
									Rechazada
								</Typo>
							)}
						</>
					) : (
						<Typo type="muted" fontWeight={600} fontSize={14}>
							{fila === "Pending" && "Pendiente"}
							{fila === "Active" && "Activa"}
							{fila === "Sended" && "Enviada a Droguería"}
							{fila === "Canceled" && "Cancelada"}
						</Typo>
					)}
				</>
			),
		},
		{
			title: "",
			key: "id",
			dataIndex: "id",
			className: "noBackground",
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={
							r.orderSubState === "Processed"
								? menuProcessed
								: r.orderSubState === "Billed"
								? menuBilled
								: r.orderSubState === "Rejected"
								? menuRejected
								: menu
						}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleProcessed(key)}>Procesada</Typo>,
				},
				{
					key: "2",
					label: <Typo onClick={() => handleBilled(key)}>Facturada</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleRejected(key)}>Rechazada</Typo>,
				},
			]}
		/>
	);

	const menuProcessed = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleBilled(key)}>Facturada</Typo>,
				},
				{
					key: "2",
					label: <Typo onClick={() => handleRejected(key)}>Rechazada</Typo>,
				},
			]}
		/>
	);

	const menuBilled = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleProcessed(key)}>Procesada</Typo>,
				},
				{
					key: "2",
					label: <Typo onClick={() => handleRejected(key)}>Rechazada</Typo>,
				},
			]}
		/>
	);

	const menuRejected = (
		<Menu
			items={[
				{
					key: "1",
					label: <Typo onClick={() => handleProcessed(key)}>Procesada</Typo>,
				},
				{
					key: "2",
					label: <Typo onClick={() => handleBilled(key)}>Facturada</Typo>,
				},
			]}
		/>
	);

	const handleProcessed = (key) => {
		confirm({
			title: "¿Seguro que quiere cambiar el estado?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La orden se cambiará a Procesada",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PROCESADO");
			},
		});
	};

	const handleBilled = (key) => {
		confirm({
			title: "¿Seguro que quiere cambiar el estado?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La orden se cambiará a Facturada",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "FACTURADO");
			},
		});
	};

	const handleRejected = (key) => {
		confirm({
			title: "¿Seguro que quiere cambiar el estado?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La orden se cambiará a Rechazada",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "RECHAZADO");
			},
		});
	};

	const handleAction = async (key, state) => {
		setIsLoading(true);
		try {
			const postData = {
				id: key.id,
				productStates: orderDetails
					?.filter((o) => o.orderNumId === key.orderNumId)
					?.map((i) => ({
						id: i.orderId,
						orderNumId: key.orderNumId,
						distributorId: key.distributorId,
						productEan: i.productEan,
						productStateInOrder: state,
					})),
			};
			await update_order_states(key.id, postData);
			fetch_sales(1);
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Estado actualizado correctamente"
			);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_sales = async (skipValue) => {
		setIsLoading(true);
		try {
			const res = await get_order_statuses_admin_CT(
				laboratoryId,
				distributorId,
				ordersLabSellerId,
				provinceId,
				dateToSearch,
				typeOfSale,
				orderState,
				skipValue,
				page
			);
			const resTypesOfOrders = JSON.parse(res?.paginationInfo?.typesOfOrders);
			const resStatesOfOrders = JSON.parse(res?.paginationInfo?.statesOfOrders);
			const resProvinceOfOrders = JSON.parse(
				res?.paginationInfo?.provinceOfOrders
			);

			setData(res?.orders);
			setOrderDetails(res?.detailOrders);

			setSearchInfo(res?.paginationInfo);
			setPaginationInputValue(res?.paginationInfo?.actualPage);
			setUsers(
				res?.users?.map((r) => ({
					value: r.sellerId,
					label: r.sellerFirstName + " " + r.sellerLastName,
				}))
			);
			setOrderTypes(
				resTypesOfOrders?.map((r) => ({
					value: r.TypeOfSale,
					label: r.TypeOfSale,
				}))
			);
			setOrderStates(
				resStatesOfOrders?.map((r) => ({
					value: r.OrderState,
					label: selectStateToShow(r.OrderState),
				}))
			);
			setProvinces(
				resProvinceOfOrders?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);
			setDistributors(
				res?.distributors?.map((r) => ({
					value: r.distributorId,
					label: r.distributorName,
				}))
			);
			setHeaderInfo({
				totalActive: res?.paginationInfo.totalActive,
				totalPending: res?.paginationInfo.totalPending,
				totalSended: res?.paginationInfo.totalSended,
				totalCanceled: res?.paginationInfo.totalCanceled,
			});
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setActualPage(1);
		setPage(CONSTANTS.PAGE_LARGE_SIZE);
		fetch_sales(1);
	}, [
		orderState,
		typeOfSale,
		dateToSearch,
		ordersLabSellerId,
		provinceId,
		distributorId,
		orderUpdated,
	]);

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendientes";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activas";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviadas";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Canceladas";

			default:
				return;
		}
	};

	const handleDeleteDates = () => {
		setDateToSearch(null);
		setDistributorId(null);
		setOrdersLabSellerId(null);
		setTypeOfSale(null);
		setOrderState(null);
		fetch_sales(1);
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_sales(cp);
			setActualPage(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_sales(cp);
			setActualPage(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_sales(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleDateChange = (date) => {
		setDateToSearch(date.format("DD/MM/YYYY").toString());
	};

	const selectProvince = (e) => {
		setProvinceId(e);
	};

	const selectUser = (e) => {
		setOrdersLabSellerId(e);
	};

	const selectTypeOfSale = (e) => {
		setTypeOfSale(e);
	};

	const selectDistributor = (e) => {
		setDistributorId(e);
	};

	const selectState = (e) => {
		setOrderState(e);
	};

	const handleUpdateOrderClick = () => {
		if (data) {
			setShowUpdateOrderModal(true);
		}
	};

	const handleOk = () => {
		setShowUpdateOrderModal(false);
	};

	const handleCancel = () => {
		setShowUpdateOrderModal(false);
	};

	return (
		<Main>
			<Main.Breadcrumb>
				<Main.BreadcrumbItem>
					{uri ? (
						<Link to={"/" + uri}>Configuración General</Link>
					) : (
						<Link to="/">Home</Link>
					)}
				</Main.BreadcrumbItem>
				<Main.BreadcrumbSeparator />
				<Main.ActiveBreadcrumbItem>
					Estado general pedidos
				</Main.ActiveBreadcrumbItem>
			</Main.Breadcrumb>
			<Inner></Inner>
			<Main.Body>
				<RadioOptions>
					<DatePicker
						picker="month"
						onChange={handleDateChange}
						placeholder="fecha"
					/>
					<Select
						showSearch
						allowClear
						placeholder="provincia"
						optionFilterProp="children"
						onChange={(e) => selectProvince(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
					<Select
						showSearch
						allowClear
						placeholder="origen"
						optionFilterProp="children"
						onChange={(e) => selectTypeOfSale(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={orderTypes}
						style={{ width: 120 }}
					/>
					<Select
						showSearch
						allowClear
						placeholder="usuario"
						optionFilterProp="children"
						onChange={(e) => selectUser(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={users}
						style={{ width: 200 }}
					/>
					<Select
						showSearch
						allowClear
						placeholder="droguería"
						optionFilterProp="children"
						onChange={(e) => selectDistributor(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
					<Select
						showSearch
						allowClear
						placeholder="estado"
						optionFilterProp="children"
						onChange={(e) => selectState(e)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={orderStates}
						style={{ width: 180 }}
					/>
					<CalendarButton
						type="primary"
						color="white"
						onClick={handleDeleteDates}
						loading={isLoading}
					>
						Eliminar filtros
					</CalendarButton>
				</RadioOptions>
				<OrdersTable
					data={data}
					columns={columns}
					loading={isLoading}
					pagination={false}
				/>
				{searchInfo && searchInfo.totalOrders > 0 && (
					<CompaginationDiv>
						{searchInfo.actualPage !== 1 && (
							<Button size="small" shape="circle" onClick={handleDecrement}>
								<LeftOutlined />
							</Button>
						)}
						<AntdInput
							step={1}
							min={1}
							max={searchInfo.totalPages}
							onPressEnter={(e) => handlePageChange(e)}
							defaultValue={paginationInputValue.toString()}
							addonAfter={`/ ${searchInfo.totalPages}`}
							style={{
								width: ` ${searchInfo.totalPages > 99 ? "76px" : "66px"}`,
							}}
						/>
						{searchInfo.actualPage < searchInfo.totalPages && (
							<Button
								size="small"
								shape="circle"
								onClick={handleIncrement}
								disabled={
									searchInfo.actualPage === searchInfo.totalPages ? true : false
								}
							>
								<RightOutlined />
							</Button>
						)}
					</CompaginationDiv>
				)}
				<AntdModal
					title={`Orden: ${key?.orderNumId}`}
					visible={showUpdateOrderModal}
					onOk={handleOk}
					onCancel={handleCancel}
					width={1200}
					centered={true}
					footer={false}
					destroyOnClose={true}
				>
					<UpdateOrder
						data={key}
						orderItems={orderDetails}
						checkUpdated={setOrderUpdated}
						closeUpdateModal={setShowUpdateOrderModal}
					/>
				</AntdModal>
			</Main.Body>
		</Main>
	);
}

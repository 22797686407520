import { Button, Input, Modal, Radio } from "antd";
import styled from "styled-components";
import { COLORS, WEIGHT } from "../../../layout/theme";

export const CalendarButton = styled(Button)`
	border-radius: 5px;
`;

export const RadioOptions = styled.div`
	margin-bottom: 20px;
	display: flex;
	gap: 20px;
	padding-right: 55px;
`;

export const AntdRadioButton = styled(Radio.Button)`
	border: none;
	font-weight: ${WEIGHT.Bold};
`;

export const CompaginationDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;

export const AntdInput = styled(Input)`
	.ant-input-group .ant-input {
		border: 1px solid ${COLORS.Grey};
		border-radius: 10px;
		background-color: transparent;
		text-align: end;
		padding: 5px;
	}

	.ant-input-group .ant-input:hover {
		border: 1px solid ${COLORS.Secondary};
		border-radius: 10px;
	}

	.ant-input-group .ant-input:focus {
		border-radius: 10px;
	}

	.ant-input-group-addon {
		border: 0;
		background-color: transparent;
		padding: 5px;
	}
`;

export const Inner = styled.div`
	display: flex;
	align-items: flex-end;
	padding: 20px 20px 20px 0;
`;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const Container = styled.div`
	cursor: pointer;
`;

export const AntdModal = styled(Modal)`
	.ant-modal-content {
		border-radius: 10px;
	}

	.ant-modal-header {
		border-radius: 10px 10px 0 0;
	}
	.ant-modal-body {
		background-color: ${COLORS.LightGrey};
		padding: 1rem 5rem 1rem 5rem;
		border-radius: 0 0 10px 10px;
	}
	.ant-modal-footer {
		text-align: left;
		height: 80px;
	}
`;

import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../../../../layout/main/Main";
import {
	Space,
	Radio,
	Row,
	Col,
	Modal,
	Dropdown,
	Menu,
	Select,
	Button,
	Checkbox,
} from "antd";
import {
	CloseCircleOutlined,
	CloudDownloadOutlined,
	InfoCircleOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import {
	ActionButton,
	AntdOption,
	AntdRadioButton,
	Container,
	Filters,
	Line,
	OfferPrice,
	OptionContainer,
	Price,
	RadioOptions,
	UnP,
} from "./styles";
import LoadingHeader from "./components/LoadingHeader";
import Image from "../../../../../common/image/Image";
import { FaImages } from "react-icons/fa";
import { COLORS, Typo } from "../../../../../layout/theme";
import DropShadow from "../../../../../common/drop-shadow/DropShadow";
import SignInTemplate from "../../../../../layout/sign-in-template/SignInTemplate";
import DataTable from "../../../../../common/data-table/DataTable";
import {
	get_market_products_page_CT,
	get_products_header_info_CT,
	update_market_product_state_CT,
	update_masive_product_state_CT,
} from "../../../../../../api/endpoints/products";
import debounce from "lodash/debounce";
import ProductModal from "../../../../../common/product-modal/ProductModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import { get_clients_name_list } from "../../../../../../api/endpoints/clients";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import AntdSearch from "../../../../../common/antd-search/AntdSearch";
import AntdSelect from "../../../../../common/antd-select/AntdSelect";
import {
	TYPE,
	openNotificationWithIcon,
} from "../../../../../../utils/notificationToast";
import moment from "moment";
import {
	calculatePercentage,
	calculatePrice,
} from "../../../../../../utils/calculatePrice";

const { confirm } = Modal;

export default function MarketProductsListCT({
	selectedClient,
	setSelectedClient,
	uri,
}) {
	const navigate = useNavigate();
	const [headerInfo, setHeaderInfo] = useState(null);
	const [data, setData] = useState(null);
	const [state, setState] = useState("PUBLISHED");
	const [originalClients, setOriginalClients] = useState(null);
	const [clients, setClients] = useState(null);
	const [show, setShow] = useState(false);
	const [modalID, setModalID] = useState(null);
	const [key, setKey] = useState("null");
	const [loading, setLoading] = useState(false);
	const [loadingBulkPublish, setLoadingBulkPublish] = useState(false);
	const [isApprovedFilter, setIsApprovedFilter] = useState(false);
	const [eanFilter, setEanFilter] = useState(null);
	const [titleFilter, setTitleFilter] = useState(null);

	const get_selected_client = () => {
		const clientFromStorage = localStorage.getItem("selectedClient");
		setSelectedClient(clientFromStorage ?? "");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		get_selected_client();
	}, [selectedClient]);

	const handleDetail = (id) => {
		setShow(true);
		setModalID(id);
	};

	const update_all = async (clientId) => {
		setLoadingBulkPublish(true);
		try {
			if (clientId === "null" || clientId === null) {
				openNotificationWithIcon(TYPE.ERROR, "Seleccione un cliente primero");
				return;
			}

			const res = await update_masive_product_state_CT(clientId);
			fetch_market_products_header();
			fetch_market_products(1, "");
			openNotificationWithIcon(
				TYPE.SUCCESS,
				"Productos creado con exito",
				"Los productos se crearon con exito"
			);
		} catch (err) {
			console.log(err);
			openNotificationWithIcon(TYPE.ERROR, err?.response?.data?.title);
		} finally {
			setLoadingBulkPublish(false);
		}
	};

	const columns = [
		{
			title: (algo) => {
				return <FaImages style={{ color: COLORS.White }} />;
			},
			width: 100,
			align: "center",
			dataIndex: "photoUrl",
			render: (value, record, index) => (
				<div key={`${value}`}>
					{value && (
						<>
							<Image
								width={40}
								height={40}
								src={value}
								alt={record.title}
								onClick={() => handleDetail(record.id)}
							/>
						</>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "title",
			ellipsis: true,
			width: 300,
			render: (value, record) => (
				<>
					<div
						style={{
							color: COLORS.Default,
							fontWeight: "600",
							margin: "0",
							padding: "0",
						}}
					>
						<UnP>
							{value}{" "}
							<Typo type="muted" level={5}>
								{record?.presentation?.name}
							</Typo>
						</UnP>
					</div>
					<Row gutter={5}>
						<Col>
							{record.mobilePaused &&
								!record.mobileDraft &&
								!record.deleted && <Typo type="warning">Producto pausado</Typo>}
							{!record.mobilePaused &&
								record.mobileDraft &&
								!record.deleted && <Typo type="muted">Producto borrador</Typo>}
						</Col>
					</Row>
				</>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Ean</div>,
			dataIndex: "ean13",
			filters: eanFilter,
			onFilter: (value, record) => record.ean13.includes(value),
			filterSearch: true,
			render: (fila) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>{fila}</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Laboratorio</div>,
			dataIndex: "clientName",
			render: (fila) => (
				<div
					style={{
						color: COLORS.Grey,
						fontWeight: "600",
					}}
				>
					{fila}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Precio</div>,
			dataIndex: "price",
			render: (fila, record, index) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>
					{record.offerPrice !== fila && !record.isComboProduct ? (
						<>
							<Line>${fila}</Line>
							<OfferPrice>
								$
								{numberWithDotAndCommas(
									calculatePrice(
										fila,
										record.client.generalDiscount,
										record.mobileDiscount,
										0,
										0
									).toFixed(2)
								)}
							</OfferPrice>
						</>
					) : (
						<Price>${fila}</Price>
					)}
				</div>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Descuento</div>,
			dataIndex: "price",
			sorter: (a, b) => a.discount - b.discount,
			render: (value, record, index) => (
				<div style={{ color: COLORS.Grey, fontWeight: "600" }}>
					{record.isComboProduct ? (
						<Typo level={5} type="green">
							{record.notes}
						</Typo>
					) : (
						<Typo level={5} type="green">
							{record.offerPrice !== value
								? `${numberWithDotAndCommas(
										calculatePercentage(
											value,
											record.client.generalDiscount,
											record.mobileDiscount,
											0,
											0
										)
								  )}%`
								: ""}
						</Typo>
					)}
				</div>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			className: "noBackground",
			width: 10,
			render: (f, r, i) => (
				<>
					<Dropdown
						overlay={
							state === "PUBLISHED"
								? menuActive
								: state === "PAUSED"
								? menuPaused
								: state === "DRAFT"
								? menuDraft
								: state === "FUTURE"
								? menuFuture
								: menuDeleted
						}
						placement="bottomRight"
					>
						<ActionButton key={`${f}`} onMouseOver={() => setKey(r)}>
							<BsThreeDotsVertical />
						</ActionButton>
					</Dropdown>
				</>
			),
		},
	];

	const menuActive = (
		<Menu
			items={[
				{
					key: "2",
					label: <Typo onClick={() => handlePause(key.id)}>Pausar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDelete(key.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuFuture = (
		<Menu
			items={[
				{
					key: "2",
					label: <Typo onClick={() => handlePause(key.id)}>Pausar</Typo>,
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDelete(key.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuPaused = (
		<Menu
			items={[
				{
					key: "2",
					label: (
						<Typo
							onClick={() => handleActivate(key.id, key.title, key.offerPrice)}
						>
							Publicar
						</Typo>
					),
				},
			]}
		/>
	);

	const menuDraft = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link
							to={`/admin/marketProductsIndex/${
								key.isComboProduct ? "marketComboProduct" : "marketProduct"
							}/${key.id}?uri=${uri}`}
						>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo
							onClick={() => handleActivate(key.id, key.title, key.offerPrice)}
						>
							Publicar
						</Typo>
					),
				},
				{
					key: "3",
					label: <Typo onClick={() => handleDelete(key.id)}>Eliminar</Typo>,
				},
			]}
		/>
	);

	const menuDeleted = (
		<Menu
			items={[
				{
					key: "1",
					label: (
						<Link
							to={`/admin/marketProductsIndex/${
								key.isComboProduct ? "marketComboProduct" : "marketProduct"
							}/${key.id}?uri=${uri}`}
							onClick={() => {
								setSelectedClient(null);
							}}
						>
							<Typo type="secondary">Editar</Typo>
						</Link>
					),
				},
				{
					key: "2",
					label: (
						<Typo
							onClick={() => handleActivate(key.id, key.title, key.offerPrice)}
						>
							Publicar
						</Typo>
					),
				},
			]}
		/>
	);

	const fetch_market_products = async (
		page,
		search,
		type = state,
		client = selectedClient
	) => {
		client = client !== null ? client : "";
		const res = await get_market_products_page_CT(search, type, page, client);
		setData({
			...res,
			items: res.items?.map((r) => ({
				...r,
				clientName: r.client.name,
			})),
		});
		setEanFilter(
			res.items.map((r) => ({
				text: r.ean13,
				value: r.ean13,
			}))
		);
		setTitleFilter(
			res.items.map((r) => ({
				text: r.title + " " + r.presentation.name,
				value: r.title + " " + r.presentation.name,
			}))
		);
	};

	const fetch_market_products_header = async () => {
		const res = await get_products_header_info_CT(selectedClient, false);
		setHeaderInfo(res);
	};

	useEffect(() => {
		const fetch_market_client_list = async () => {
			const res = await get_clients_name_list();
			setOriginalClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
			setClients(
				res
					?.filter((c) => c.isSeller)
					?.map((r) => ({
						value: r.id,
						label: r.name,
						isApproved: r.isApproved,
					}))
			);
		};

		fetch_market_client_list();
	}, []);

	useEffect(() => {
		if (selectedClient) {
			setLoading(true);
			fetch_market_products_header();
			fetch_market_products(1, "", state, selectedClient);
			setLoading(false);
		}
	}, [state, selectedClient]);

	const handleChange = (value) => {
		setLoading(true);
		if (value === "...") {
			setSelectedClient(null);
			localStorage.setItem("selectedClient", null);
		} else {
			setSelectedClient(value);
			localStorage.setItem("selectedClient", value);
		}
		setLoading(false);
	};

	useEffect(() => {
		return () => {
			debouncedEventHandler.cancel();
		};
	}, []);

	const eventHandler = (e) => {
		fetch_market_products(1, e.target.value, state, selectedClient);
	};

	const debouncedEventHandler = useMemo(
		() => debounce(eventHandler, 650),
		[selectedClient, state]
	);

	const handleDelete = (key) => {
		confirm({
			title: "¿Seguro que quiere eliminar el producto?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "La eliminación del producto se aplicará de inmediato",
			okText: "Sí",
			okType: "danger",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "DELETED");
			},
		});
	};

	const handlePause = (key) => {
		confirm({
			title: "¿Seguro que quiere pausar el producto?",
			icon: <CloseCircleOutlined style={{ color: "red" }} />,
			content: "Pausar el producto se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PAUSED");
			},
		});
	};

	const handleActivate = (key, nombre, precio) => {
		confirm({
			title: `¿Seguro que quiere publicar ${nombre} al precio de $${precio}?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "La publicación del producto se aplicará de inmediato",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				handleAction(key, "PUBLISHED");
			},
		});
	};

	const handleAction = async (id, state) => {
		setLoading(true);
		const _state = { id: id, state: state };
		await update_market_product_state_CT(id, _state);
		fetch_market_products_header();
		fetch_market_products(1, "");
		setLoading(false);
	};

	const handleIsApprovedFilter = (value) => {
		setIsApprovedFilter(value);
		if (value) {
			const filteredClients = clients.filter((client) => client.isApproved);
			setClients(filteredClients);
		} else {
			setClients(originalClients);
		}
	};

	// const fetch_export_products_with_discount = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const res = await export_products_with_discount();
	// 		const href = URL.createObjectURL(res);

	// 		const link = document.createElement("a");
	// 		link.href = href;

	// 		const fileName =
	// 			"ProductWithDiscountList_" + moment().format("YYYYMMDD") + ".xlsx";
	// 		link.setAttribute("download", fileName);
	// 		document.body.appendChild(link);
	// 		link.click();

	// 		document.body.removeChild(link);
	// 		URL.revokeObjectURL(href);
	// 	} catch (error) {
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	return (
		<Main.Body>
			<Filters>
				{!headerInfo && !clients ? (
					<LoadingHeader />
				) : (
					<OptionContainer>
						<Container>
							<Select
								showSearch
								placeholder="Elegi un cliente"
								optionFilterProp="children"
								onChange={handleChange}
								value={selectedClient !== "null" ? selectedClient : "..."}
								filterOption={(input, option) =>
									(option?.label ?? "")
										.toLowerCase()
										.includes(input.toLowerCase())
								}
								options={clients}
								style={{ width: 200 }}
							/>
							<Checkbox
								checked={isApprovedFilter}
								onChange={(e) => handleIsApprovedFilter(e.target.checked)}
								style={{ width: 195 }}
							>
								Clientes con Contrato
							</Checkbox>
							{/* <DropShadow type="drop">
								<SignInTemplate.AntdButton
									type="Default"
									bg="Default"
									color="White"
									icon={<CloudDownloadOutlined />}
									loading={loading}
									onClick={fetch_export_products_with_discount}
									style={{ width: "260px", fontWeight: "400" }}
								>
									Exportar productos con descuento
								</SignInTemplate.AntdButton>
							</DropShadow> */}
						</Container>
						{/* <Container>
							<AntdSearch
								width={400}
								allowClear
								placeholder="Buscar producto"
								onChange={debouncedEventHandler}
							/>
							<SignInTemplate.AntdButton
								type="primary"
								bg="Primary"
								color="White"
								icon={<PlusCircleOutlined />}
								loading={loading}
								onClick={() =>
									navigate(
										"/admin/marketProductsIndex/createMarketProduct?uri=" + uri
									)
								}
								style={{ width: "180px", fontWeight: "400" }}
							>
								Nuevo producto
							</SignInTemplate.AntdButton>
							<SignInTemplate.AntdButton
								type="primary"
								bg="Secondary"
								color="White"
								icon={<PlusCircleOutlined />}
								loading={loading}
								onClick={() =>
									navigate(
										"/admin/marketProductsIndex/createMarketComboProduct?uri=" +
											uri
									)
								}
								style={{ width: "180px", fontWeight: "400" }}
							>
								Nuevo combo
							</SignInTemplate.AntdButton>
						</Container> */}
					</OptionContainer>
				)}
			</Filters>
			<RadioOptions>
				<Radio.Group value={state} onChange={(e) => setState(e.target.value)}>
					<AntdRadioButton value="PUBLISHED">
						Publicados ({headerInfo?.totalPublished})
					</AntdRadioButton>
					<AntdRadioButton value="FUTURE">
						Futuros ({headerInfo?.totalFuture})
					</AntdRadioButton>
					<AntdRadioButton value="PAUSED">
						Pausados ({headerInfo?.totalPaused})
					</AntdRadioButton>
					<AntdRadioButton value="DRAFT">
						Borrador ({headerInfo?.totalDraft})
					</AntdRadioButton>
					<AntdRadioButton value="DELETED">
						Eliminados ({headerInfo?.totalDeleted})
					</AntdRadioButton>
					{state === "DRAFT" && (
						<Button
							onClick={() => update_all(selectedClient)}
							style={{ marginRight: "60px" }}
							loading={loadingBulkPublish}
						>
							Publicar Todos
						</Button>
					)}
				</Radio.Group>
			</RadioOptions>
			<DataTable
				data={data}
				fetch={fetch_market_products}
				columns={columns}
				loading={loading}
			/>
		</Main.Body>
	);
}

import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { AntdLayout, Inner } from "./styles";
import { CONSTANTS } from "../../../../utils/constants";
import { BiBox, BiPackage } from "react-icons/bi";
import ModuleOrders from "./components/module-orders/ModuleOrders";
import ModuleProducts from "./components/module-products/ModuleProducts";
import DashboardSeller from "../dashboard/DashboardSeller";
import { useNavigate } from "react-router-dom";
const { Sider } = Layout;

export default function ModuleMarketplace({
	userdata,
	clientId,
	clientContract,
	clientUser,
}) {
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState(false);
	const [pharmacyModuleDisplay, setPharmacyModuleDisplay] = useState(
		CONSTANTS.PHARMACY_MODULE_ORDERS
	);

	const handleMenuClick = ({ key }) => {
		setPharmacyModuleDisplay(key);
	};

	return (
		<AntdLayout style={{ minHeight: "100vh" }}>
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				// onMouseEnter={() => setCollapsed(false)}
				// onMouseLeave={() => setCollapsed(true)}
			>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.PHARMACY_MODULE_ORDERS]}
					style={{ paddingTop: 50 }}
					onClick={handleMenuClick}
				>
					<Menu.Item
						key={CONSTANTS.PHARMACY_MODULE_ORDERS}
						icon={<BiBox />}
						onClick={() => {
							// setCollapsed(false);
							setPharmacyModuleDisplay(CONSTANTS.PHARMACY_MODULE_ORDERS);
						}}
					>
						Pedidos
					</Menu.Item>
					{/* <Menu.Item
						key={CONSTANTS.PHARMACY_MODULE_DASHBOARDS}
						icon={<BiPackage />}
						onClick={() => {
							// setCollapsed(true);
							setPharmacyModuleDisplay(CONSTANTS.PHARMACY_MODULE_DASHBOARDS);
						}}
					>
						Dashboards
					</Menu.Item> */}
				</Menu>
			</Sider>
			<AntdLayout className="site-layout">
				<Inner>
					{pharmacyModuleDisplay === CONSTANTS.PHARMACY_MODULE_ORDERS && (
						<ModuleOrders clientId={clientId} />
					)}
					{pharmacyModuleDisplay === CONSTANTS.PHARMACY_MODULE_PRODUCTS && (
						<ModuleProducts clientId={clientId} />
					)}
					{/* {
						pharmacyModuleDisplay === CONSTANTS.PHARMACY_MODULE_DASHBOARDS &&
							navigate("/admin/dashboard")
						// <DashboardSeller clientId={clientId} clientUser={clientUser} />
					} */}
				</Inner>
			</AntdLayout>
		</AntdLayout>
	);
}

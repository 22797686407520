import React, { useEffect, useState } from "react";
import { COLORS, Typo } from "../../../layout/theme";
import { DrawerContainer, FilterContainerButton, Inner } from "./styles";
import {
	export_dashboard_pharmacies,
	export_dashboard_pos_table_conversion,
	get_buys_table_conversion_dashboard,
	get_dinamic_mobile_dashboard,
	get_dinamic_sales_dashboard,
	get_distributor_dashboard,
	get_general_dashboard,
	get_laboratory_dashboard,
	get_pharmacy_general_dashboard,
	get_region_dashboard,
} from "../../../../api/endpoints/dashboard";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import Dashboard1 from "./components/dashboards/Dashboard1";
import Dashboard2 from "./components/dashboards/Dashboard2";
import { CONSTANTS } from "../../../../utils/constants";
import moment from "moment";
import { numberWithDotAndCommas } from "../../../../utils/numberWithDotAndComas";
import { Layout, Menu, Tooltip } from "antd";
import {
	BarChartOutlined,
	MobileOutlined,
	PieChartOutlined,
	CloseCircleOutlined,
	PercentageOutlined,
} from "@ant-design/icons";
import { TiPlusOutline, TiWorld } from "react-icons/ti";
import Dashboard3 from "./components/dashboards/Dashboard3";
import Dashboard4 from "./components/dashboards/Dashboard4";
import { useFilter } from "../../../../contexts/filterContext";
import { FaInfo, FaTruck } from "react-icons/fa";
import { GiMicroscope, GiRugbyConversion } from "react-icons/gi";
import checkBrowser from "../../../../utils/checkBrowser";
import Dashboard5 from "./components/dashboards/Dashboard5";
import { numberToMillions } from "../../../../utils/numberToMillions";
import Dashboard6 from "./components/dashboards/Dashboard6";
import Dashboard7 from "./components/dashboards/Dashboard7";
import Dashboard8 from "./components/dashboards/Dashboard8";
const { Sider } = Layout;

export default function DashboardOneMarket() {
	const {
		selectedDistributor,
		setSelectedDistributor,
		selectedLaboratory,
		setSelectedLaboratory,
		selectedPharmacy,
		setSelectedPharmacy,
		selectedPointOfSale,
		setSelectedPointOfSale,
		selectedProvince,
		setSelectedProvince,
		rangeDate,
		setRangeDate,
		dateFrom,
		setDateFrom,
		dateTo,
		setDateTo,
		mobilePeriod,
		setMobilePeriod,
		selectedFilters,
		setSelectedFilters,
		setMarketOrdersPharmacyId,
		selectedTypeOfSale,
		setSelectedTypeOfSale,
	} = useFilter();
	const [collapsed, setCollapsed] = useState(true);
	const [openPanel, setOpenPanel] = useState([]);
	const [display, setDisplay] = useState(CONSTANTS.DASHBOARD_SALES);
	const [distributorsD1, setDistributorsD1] = useState(null);
	const [provincesD1, setProvincesD1] = useState(null);
	const [generalInfoD1, setGeneralInfoD1] = useState(null);
	const [pieDataD1, setPieDataD1] = useState(null);
	const [timePharmacyGraphD1, setTimePharmacyGraphD1] = useState(null);
	const [timePosGraphD1, setTimePosGraphD1] = useState(null);
	const [timeOrdersGraphD1, setTimeOrdersGraphD1] = useState(null);
	const [pharmacyByRegionD1, setPharmacyByRegionD1] = useState(null);
	const [timeTotalSalesD1, setTimeTotalSalesD1] = useState(null);
	const [distributorSalesGraphD1, setDistributorSalesGraphD1] = useState(null);
	const [pharmacyPieInfoSelector, setPharmacyPieInfoSelector] =
		useState("TOTAL");
	const [timeSalesAgainstOrdersD1, setTimeSalesAgainstOrdersD1] =
		useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [distributorsD2, setDistributorsD2] = useState(null);
	const [provincesD2, setProvincesD2] = useState(null);
	const [laboratoriesD2, setLaboratoriesD2] = useState(null);
	const [generalInfoD2, setGeneralInfoD2] = useState(null);
	const [pieDataD2, setPieDataD2] = useState(null);
	const [secondPieDataD2, setSecondPieDataD2] = useState(null);
	const [timeSalesGraphD2, setTimeSalesGraphD2] = useState(null);
	const [timePharmacyOrdersGraphD2, setTimePharmacyOrdersGraphD2] =
		useState(null);
	const [timeOrdersGraphD2, setTimeOrdersGraphD2] = useState(null);
	const [timeUnitsGraphD2, setTimeUnitsGraphD2] = useState(null);
	const [laboratorySalesGraphD2, setLaboratorySalesGraphD2] = useState(null);
	const [laboratoryUnitsGraphD2, setLaboratoryUnitsGraphD2] = useState(null);
	const [laboratoryGraphSelectorD2, setLaboratoryGraphSelectorD2] =
		useState("SALES");
	const [productSalesGraphD2, setProductSalesGraphD2] = useState(null);
	const [productUnitsGraphD2, setProductUnitsGraphD2] = useState(null);
	const [productGraphSelectorD2, setProductGraphSelectorD2] = useState("SALES");
	const [regionSalesGraphD2, setRegionSalesGraphD2] = useState(null);
	const [distributorSalesGraphD2, setDistributorSalesGraphD2] = useState(null);
	const [typesOfSalesD2, setTypesOfSalesD2] = useState(null);
	const [VSInfoSelector, setVSInfoSelector] = useState("SALES");
	const [timeVSSalesGraphD2, setTimeVSSalesGraphD2] = useState(null);
	const [timeVSUnitsGraphD2, setTimeVSUnitsGraphD2] = useState(null);
	const [timeVSOrdersGraphD2, setTimeVSOrdersGraphD2] = useState(null);

	const [distributorsD3, setDistributorsD3] = useState(null);
	const [provincesD3, setProvincesD3] = useState(null);
	const [laboratoriesD3, setLaboratoriesD3] = useState(null);
	const [usersD3, setUsersD3] = useState(null);
	const [selectedUserD3, setSelectedUserD3] = useState(null);
	const [dateFromD3, setDateFromD3] = useState(
		moment().startOf("month").format("MM-DD-YYYY").toString()
	);
	const [dateToD3, setDateToD3] = useState(
		moment().endOf("month").format("MM-DD-YYYY").toString()
	);
	const [rangeDateD3, setRangeDateD3] = useState([
		moment().startOf("month"),
		moment().endOf("month"),
	]);
	const [generalInfoD3, setGeneralInfoD3] = useState(null);
	const [timePharmacyOrdersGraphD3, setTimePharmacyOrdersGraphD3] =
		useState(null);
	const [sellerSalesTableD3, setSellerSalesTableD3] = useState(null);
	const [timeOrdersGraphD3, setTimeOrdersGraphD3] = useState(null);
	const [distributorSalesGraphD3, setDistributorSalesGraphD3] = useState(null);
	const [distributorOrdersGraphD3, setDistributorOrdersGraphD3] =
		useState(null);
	const [distributorUnitsGraphD3, setDistributorUnitsGraphD3] = useState(null);
	const [distributorGraphSelector, setDistributorGraphSelector] =
		useState("SALES");
	const [productSalesGraphD3, setProductSalesGraphD3] = useState(null);
	const [productUnitsGraphD3, setProductUnitsGraphD3] = useState(null);
	const [productGraphSelectorD3, setProductGraphSelectorD3] = useState("SALES");

	const [distributorsD4, setDistributorsD4] = useState(null);
	const [laboratoriesD4, setLaboratoriesD4] = useState(null);
	const [pharmaciesD4, setPharmaciesD4] = useState(null);
	const [provincesD4, setProvincesD4] = useState(null);
	const [generalInfoD4, setGeneralInfoD4] = useState(null);
	const [pieDataD4, setPieDataD4] = useState(null);
	const [timeTotalSalesD4, setTimeTotalSalesD4] = useState(null);
	const [laboratoryUnitsGraphD4, setLaboratoryUnitsGraphD4] = useState(null);
	const [laboratorySalesGraphD4, setLaboratorySalesGraphD4] = useState(null);
	const [productUnitsGraphD4, setProductUnitsGraphD4] = useState(null);
	const [productSalesGraphD4, setProductSalesGraphD4] = useState(null);
	const [laboratoryGraphSelector, setLaboratoryGraphSelector] =
		useState("SALES");
	const [productGraphSelector, setProductGraphSelector] = useState("SALES");
	const [dealsInfoSelector, setDealsInfoSelector] = useState("SALES");
	const [pharmaciesTable, setPharmaciesTable] = useState(null);
	const [ordersInfoSelector, setOrdersInfoSelector] = useState("SALES");
	const [distributorsPieInfoSelector, setDistributorsPieInfoSelector] =
		useState("UNITS");
	const [openFilterList, setOpenFilterList] = useState(false);

	const [distributorsD5, setDistributorsD5] = useState(null);
	const [laboratoriesD5, setLaboratoriesD5] = useState(null);
	const [provincesD5, setProvincesD5] = useState(null);
	const [generalInfoD5, setGeneralInfoD5] = useState(null);
	const [regionTable, setRegionTable] = useState(null);
	const [regionInfoSelector, setRegionInfoSelector] = useState("SALES");

	const [distributorsD6, setDistributorsD6] = useState(null);
	const [laboratoriesD6, setLaboratoriesD6] = useState(null);
	const [provincesD6, setProvincesD6] = useState(null);
	const [generalInfoD6, setGeneralInfoD6] = useState(null);
	const [
		evolutionDistributorClientsGraph,
		setEvolutionDistributorClientsGraph,
	] = useState(null);
	const [timeDistributorClientsGraph, setTimeDistributorClientsGraph] =
		useState(null);
	const [distributorInfoGraph, setDistributorInfoGraph] = useState(null);
	const [distributorTable, setDistributorTable] = useState(null);
	const [distributorInfoSelector, setDistributorInfoSelector] =
		useState("SALES");
	const [distributorGraphSelectorD6, setDistributorGraphSelectorD6] =
		useState("EVOLUTION");

	const [distributorsD7, setDistributorsD7] = useState(null);
	const [laboratoriesD7, setLaboratoriesD7] = useState(null);
	const [provincesD7, setProvincesD7] = useState(null);
	const [generalInfoD7, setGeneralInfoD7] = useState(null);

	const [laboratoryTable, setLaboratoryTable] = useState(null);
	const [laboratoryInfoSelector, setLaboratoryInfoSelector] = useState("SALES");
	const [laboratoryGraphSelectorD7, setLaboratoryGraphSelectorD7] =
		useState("EVOLUTION");

	const [buysTableConversion, setBuysTableConversion] = useState(null);
	const [typesOfSalesD8, setTypesOfSalesD8] = useState(null);

	const fetch_general_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_general_dashboard(
				selectedDistributor,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD1 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD1 = JSON.parse(res?.generalInfo?.provinceList);

			setDistributorsD1(
				resDistributorsD1?.map((r) => ({
					value: r.Id,
					label: r.Name,
				}))
			);

			setProvincesD1(
				resProvincesD1?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setGeneralInfoD1(res?.generalInfo);

			setPieDataD1({
				chart: {
					type: "variablepie",
					height: "85%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 50,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "100",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["50%", "30%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Grey,
							COLORS.Default,
							COLORS.Danger,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Tipo de Farmacia",
						data: [
							{ name: "Cadenas", y: res?.generalInfo.totalChains, z: 100 },
							{
								name: "Independientes",
								y: res?.generalInfo.totalIndependents,
								z: 100,
							},
						],
					},
				],
			});

			setTimePharmacyGraphD1({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.pharmaciesByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.monthCreated]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.pharmaciesByYearAndMonth.map((p) => p.totalPharmacies),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.pharmaciesByYearAndMonth[
										res.pharmaciesByYearAndMonth.length - 1
									]?.monthCreated === res.generalInfo.actualMonth
										? res?.pharmaciesByYearAndMonth.length - 2
										: res?.pharmaciesByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimePosGraphD1({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.posByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.monthCreated]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.posByYearAndMonth.map((p) => p.totalPointsOfSale),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.posByYearAndMonth[res.posByYearAndMonth.length - 1]
										?.monthCreated === res.generalInfo.actualMonth
										? res?.posByYearAndMonth.length - 2
										: res?.posByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimeOrdersGraphD1({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Success,
							lineWidth: 2,
							lineColor: COLORS.Success,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setTimeSalesAgainstOrdersD1({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				series: [
					{
						name: "Δ Ventas",
						valuePrefix: "",
						data: res?.ordersByYearAndMonth.map(
							(p) => p.totalSales / p.totalOrders
						),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Δ Unidades",
						valueSuffix: "",
						data: res?.ordersByYearAndMonth.map(
							(p) => p.totalUnits / p.totalOrders
						),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setPharmacyByRegionD1({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.provincePos.map((p) => p.provinceName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.provincePos.map((p) => p.totalProvincePOS),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setTimeTotalSalesD1({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas $",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Unidades",
						valueSuffix: "un.",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD1({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.name),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalSales),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_sales_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_dinamic_sales_dashboard(
				selectedDistributor,
				selectedLaboratory,
				selectedProvince,
				dateFrom,
				dateTo,
				selectedTypeOfSale
			);
			const resDistributorsD2 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD2 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD2 = JSON.parse(res?.generalInfo?.laboratoryList);
			const slicedLaboratorySales = res?.laboratorySales.slice(0, 15);
			const slicedLaboratoryUnits = res?.laboratorySales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 15);
			const slicedProductSales = res?.productSales.slice(0, 15);
			const slicedProductUnits = res?.productSales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 15);

			setDistributorsD2(
				resDistributorsD2?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD2(
				resProvincesD2?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD2(
				resLaboratoriesD2?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setTypesOfSalesD2([
				{
					value: null,
					label: "Total",
				},
				{
					value: "WEB",
					label: "Web",
				},
				{
					value: "MOBILE",
					label: "Mobile",
				},
			]);

			setGeneralInfoD2(res?.generalInfo);

			setPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["50%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorOrders?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setSecondPieDataD2({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "150",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["50%", "18%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorSales?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setTimeSalesGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return numberToMillions(this.total);
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setTimePharmacyOrdersGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.pharmacyOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.pharmacyOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.pharmacyOrdersByYearAndMonth[
										res.pharmacyOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.pharmacyOrdersByYearAndMonth.length - 2
										: res?.pharmacyOrdersByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setTimeOrdersGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Success,
							lineWidth: 2,
							lineColor: COLORS.Success,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setTimeUnitsGraphD2({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return this.total;
							} else {
								return null;
							}
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Secondary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setLaboratorySalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLaboratorySales.map((p) => p.laboratoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLaboratorySales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setLaboratoryUnitsGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLaboratoryUnits.map((p) => p.laboratoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLaboratoryUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setRegionSalesGraphD2({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.regionSales.map((p) => p.provinceName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.regionSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			//Mapeo para completar meses sin ventas mobile
			const mobileSalesMap = res?.mobileOrdersByYearAndMonth?.reduce(
				(acc, curr) => {
					acc[curr.saleMonth] = curr.totalSales;
					return acc;
				},
				{}
			);

			const mobileSalesData = res?.ordersByYearAndMonth?.map((p) => {
				const mobileSales = mobileSalesMap[p.saleMonth] || 0;
				return mobileSales;
			});

			const mobileUnitsMap = res?.mobileOrdersByYearAndMonth?.reduce(
				(acc, curr) => {
					acc[curr.saleMonth] = curr.totalUnits;
					return acc;
				},
				{}
			);

			const mobileUnitsData = res?.ordersByYearAndMonth?.map((p) => {
				const mobileUnits = mobileUnitsMap[p.saleMonth] || 0;
				return mobileUnits;
			});

			const mobileOrdersMap = res?.mobileTotalOrdersByYearAndMonth?.reduce(
				(acc, curr) => {
					acc[curr.saleMonth] = curr.totalOrders;
					return acc;
				},
				{}
			);

			const mobileOrdersData = res?.totalOrdersByYearAndMonth?.map((p) => {
				const mobileOrders = mobileOrdersMap[p.saleMonth] || 0;
				return mobileOrders;
			});

			setTimeVSSalesGraphD2({
				chart: { type: "spline" },
				title: { text: undefined },
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: { enabled: false },
				colors: [COLORS.Primary, COLORS.Default],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						visible: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [{ color: COLORS.LightGrey, width: 1, value: 0 }],
						stackLabels: {
							enabled: true,
							style: { color: COLORS.MediumGrey, textOutline: 0 },
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						visible: false,
						opposite: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
					},
				],
				plotOptions: {
					series: { stacking: "normal" },
				},
				series: [
					{
						name: "Ventas Totales",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.Primary,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{ color: COLORS.LightSecondary },
						],
						yAxis: 0,
					},
					{
						name: "Ventas Mobile",
						data: mobileSalesData,
						type: "area",
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.Default,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 2,
						},
						yAxis: 0,
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{ color: COLORS.LightDefault },
						],
					},
				],
			});

			setTimeVSUnitsGraphD2({
				chart: { type: "spline" },
				title: { text: undefined },
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: { enabled: false },
				colors: [COLORS.Primary, COLORS.Default],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						visible: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [{ color: "#FF0000", width: 1, value: 0 }],
						stackLabels: {
							enabled: true,
							style: { color: COLORS.MediumGrey, textOutline: 0 },
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return this.total;
								} else {
									return null;
								}
							},
						},
					},
					{
						visible: false,
						opposite: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
					},
				],
				plotOptions: {
					series: { stacking: "normal" },
				},
				series: [
					{
						name: "Unidades Totales",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.Primary,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{ color: COLORS.LightSecondary },
						],
						yAxis: 0,
					},
					{
						name: "Unidades Mobile",
						data: mobileUnitsData,
						type: "area",
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 2,
						},
						yAxis: 0,
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{ color: COLORS.LightDefault },
						],
					},
				],
			});

			setTimeVSOrdersGraphD2({
				chart: { type: "spline" },
				title: { text: undefined },
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: { enabled: false },
				colors: [COLORS.Primary, COLORS.Default],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						visible: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [{ color: "#FF0000", width: 1, value: 0 }],
						stackLabels: {
							enabled: true,
							style: { color: COLORS.MediumGrey, textOutline: 0 },
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return this.total;
								} else {
									return null;
								}
							},
						},
					},
					{
						visible: false,
						opposite: true,
						title: { text: null },
						labels: { enabled: true },
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
					},
				],
				plotOptions: {
					series: { stacking: "normal" },
				},
				series: [
					{
						name: "Ordenes Totales",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalOrders),
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.Primary,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{ color: COLORS.LightSecondary },
						],
						yAxis: 0,
					},
					{
						name: "Ordenes Mobile",
						data: mobileOrdersData,
						type: "area",
						lineWidth: 2,
						lineColor: COLORS.Secondary,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 2,
						},
						yAxis: 0,
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{ color: COLORS.LightDefault },
						],
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_mobile_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_dinamic_mobile_dashboard(
				selectedDistributor,
				selectedLaboratory,
				selectedUserD3,
				selectedProvince,
				dateFromD3,
				dateToD3
			);
			const resDistributorsD3 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD3 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD3 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resSellersD3 = JSON.parse(res?.generalInfo?.sellerList);
			const slicedProductSales = res?.productSales.slice(0, 25);
			const slicedProductUnits = res?.productSales
				.sort((a, b) => b.totalUnits - a.totalUnits)
				.slice(0, 25);
			const distributorsUnits = res?.distributorSales.sort(
				(a, b) => b.totalUnits - a.totalUnits
			);

			setDistributorsD3(
				resDistributorsD3?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD3(
				resProvincesD3?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD3(
				resLaboratoriesD3?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setUsersD3(
				resSellersD3?.map((r) => ({
					value: r.SellerId,
					label: r.SellerFirstName + " " + r.SellerLastName,
				}))
			);

			setGeneralInfoD3(res?.generalInfo);

			setTimePharmacyOrdersGraphD3({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br>" + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.posOrderingByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							if (
								this.x ===
									this.axis.series[0]?.xData[
										this.axis.series[0]?.xData.length - 2
									] &&
								this.axis.series[0]?.data[this.axis.series[0]?.data.length - 1]
									?.category ===
									CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
							) {
								return numberToMillions(this.total);
							} else {
								return null;
							}
						},
					},
				},
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "",
						data: res?.posOrderingByYearAndMonth.map((p) => p.totalPosOrdering),
						lineWidth: 2,
						lineColor: COLORS.Success,
						marker: {
							fillColor: COLORS.Secondary,
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.posOrderingByYearAndMonth[
										res.posOrderingByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.posOrderingByYearAndMonth.length - 2
										: res?.posOrderingByYearAndMonth.length,
								color: COLORS.Success,
							},
							{
								color: COLORS.LightSuccess,
							},
						],
					},
				],
			});

			setSellerSalesTableD3(res?.sellerSales);

			setTimeOrdersGraphD3({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.ordersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas $",
						valuePrefix: "$",
						data: res?.ordersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Unidades",
						valueSuffix: "un.",
						data: res?.ordersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.ordersByYearAndMonth[res.ordersByYearAndMonth.length - 1]
										?.saleMonth === res.generalInfo.actualMonth
										? res?.ordersByYearAndMonth.length - 2
										: res?.ordersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setDistributorSalesGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorSales.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setDistributorUnitsGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: distributorsUnits.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: distributorsUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setDistributorOrdersGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: res?.distributorOrders.map((p) => p.distributorName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: res?.distributorOrders.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductSalesGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD3({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y + " un.";
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map(
						(p) => p.productTitle + " (" + p.productEan13 + ")"
					),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalUnits),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_pharmacy_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_pharmacy_general_dashboard(
				selectedDistributor,
				selectedLaboratory,
				selectedPharmacy,
				selectedPointOfSale,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD4 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD4 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD4 = JSON.parse(res?.generalInfo?.laboratoryList);
			const resPharmaciesD4 = JSON.parse(res?.generalInfo?.pharmacyList);
			const slicedLaboratoryUnits = res?.laboratoryOrders.slice(0, 25);
			const slicedLaboratorySales = res?.laboratorySales.slice(0, 25);
			const slicedProductUnits = res?.productOrders.slice(0, 25);
			const slicedProductSales = res?.productSales.slice(0, 25);
			const slicedPharmacyTable = res?.pharmacyOrdersList.slice(0, 50);

			setDistributorsD4(
				resDistributorsD4?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD4(
				resProvincesD4?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD4(
				resLaboratoriesD4?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setPharmaciesD4(
				resPharmaciesD4?.map((r) => ({
					value: r.PharmacyId,
					label: r.PharmacyName,
				}))
			);

			setGeneralInfoD4(res?.generalInfo);

			setPieDataD4({
				chart: {
					type: "variablepie",
					height: "100%",
				},
				title: {
					style: { display: "none" },
				},
				credits: { enabled: false },
				tooltip: {
					pointFormat: "<b>{point.y}</b>",
				},
				legend: {
					layout: "vertical",
					align: "right",
					verticalAlign: "top",
					x: 0,
					y: 10,
					floating: false,
				},
				plotOptions: {
					variablepie: {
						size: "100",
						cursor: "pointer",
						dataLabels: {
							enabled: false,
						},
						showInLegend: true,
						center: ["40%", "15%"],
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
				},
				series: [
					{
						type: "variablepie",
						zMin: 0,
						name: "Droguerias",
						data: res?.distributorSales?.map((dist, idx) => ({
							name: dist.distributorName,
							y: dist.totalInOrders,
							z: 100,
						})),
					},
				],
			});

			setTimeTotalSalesD4({
				chart: { type: "spline" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Primary, COLORS.Secondary],
				credits: { enabled: false },
				xAxis: {
					categories: res?.totalOrdersByYearAndMonth.map(
						(p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]
					),
				},
				yAxis: [
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: true,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -8,
							overflow: "allow",
							crop: false,
							formatter: function () {
								if (
									this.x ===
										this.axis.series[0]?.xData[
											this.axis.series[0]?.xData.length - 2
										] &&
									this.axis.series[0]?.data[
										this.axis.series[0]?.data.length - 1
									]?.category ===
										CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
								) {
									return numberToMillions(this.total);
								} else {
									return null;
								}
							},
						},
					},
					{
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						gridLineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: "transparent",
						visible: false,
						gridLineColor: "#197F07",
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						stacking: "normal",
					},
				},
				series: [
					{
						name: "Ventas $",
						valuePrefix: "$",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalSales),
						yAxis: 0,
						lineWidth: 2,
						lineColor: COLORS.Primary,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Primary,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
					{
						name: "Unidades",
						valueSuffix: "un.",
						data: res?.totalOrdersByYearAndMonth.map((p) => p.totalUnits),
						yAxis: 1,
						lineWidth: 2,
						lineColor: COLORS.Default,
						marker: {
							fillColor: COLORS.White,
							lineWidth: 2,
							lineColor: COLORS.Default,
							symbol: "circle",
							radius: 3,
						},
						zoneAxis: "x",
						zones: [
							{
								value:
									res?.totalOrdersByYearAndMonth[
										res.totalOrdersByYearAndMonth.length - 1
									]?.saleMonth === res.generalInfo.actualMonth
										? res?.totalOrdersByYearAndMonth.length - 2
										: res?.totalOrdersByYearAndMonth.length,
								color: COLORS.Default,
							},
							{
								color: COLORS.LightSecondary,
							},
						],
					},
				],
			});

			setLaboratorySalesGraphD4({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLaboratorySales.map((p) => p.laboratoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLaboratorySales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setLaboratoryUnitsGraphD4({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedLaboratoryUnits.map((p) => p.laboratoryName),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedLaboratoryUnits.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductSalesGraphD4({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return (
							"<b>" + this.x + "</b></br>$ " + numberWithDotAndCommas(this.y)
						);
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductSales.map((p) => p.productTitle),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -8,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return numberToMillions(this.total);
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductSales.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setProductUnitsGraphD4({
				chart: { type: "column" },
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						return "<b>" + this.x + "</b></br> " + this.y;
					},
				},
				legend: {
					enabled: false,
				},
				colors: [COLORS.Default, COLORS.Grey],
				credits: { enabled: false },
				xAxis: {
					categories: slicedProductUnits.map((p) => p.productTitle),
				},
				yAxis: {
					visible: true,
					title: {
						text: null,
					},
					labels: {
						enabled: true,
					},
					gridLineColor: "#197F07",
					gridLineWidth: 0,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
						verticalAlign: "top",
						y: -5,
						overflow: "allow",
						crop: false,
						formatter: function () {
							return this.total;
						},
					},
				},
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: [
							COLORS.Primary,
							COLORS.Secondary,
							COLORS.Success,
							COLORS.StrongGreen,
							COLORS.Danger,
							COLORS.Brown,
							COLORS.Warning,
							COLORS.Violet,
							COLORS.Purple,
							COLORS.Grey,
						],
					},
					column: {
						stacking: "normal",
						pointPadding: 0,
						groupPadding: 0,
						dataLabels: {
							enabled: false,
						},
					},
				},
				series: [
					{
						name: "",
						data: slicedProductUnits.map((p) => p.totalInOrders),
						lineWidth: 2,
						lineColor: COLORS.Secondary,
					},
				],
			});

			setPharmaciesTable(slicedPharmacyTable);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_region_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_region_dashboard(
				selectedDistributor,
				selectedLaboratory,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD5 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD5 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD5 = JSON.parse(res?.generalInfo?.laboratoryList);

			setDistributorsD5(
				resDistributorsD5?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD5(
				resProvincesD5?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD5(
				resLaboratoriesD5?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setGeneralInfoD5(res?.generalInfo);

			const auxTable = res?.regionTotalPosList.map((r, idx) => ({
				province: r.provinceName,
				totalPos: r.totalProvincePOS,
				salesGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						pointFormat:
							'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
						valueDecimals: 0,
						split: false,
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Primary, COLORS.Secondary],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.totalRegionByYearAndMonth.filter(
								(s) => s.provinceName === r.provinceName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: [
						{
							title: {
								text: null,
							},
							labels: {
								enabled: false,
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							lineColor: "transparent",
							visible: false,
							gridLineColor: "#197F07",
							lineWidth: 1,
							plotLines: [
								{
									color: "#FF0000",
									width: 1,
									value: 0,
								},
							],
							stackLabels: {
								enabled: true,
								style: {
									color: COLORS.MediumGrey,
									textOutline: 0,
								},
								y: -10,
								formatter: function () {
									if (
										this.x ===
											this.axis.series[0]?.xData[
												this.axis.series[0]?.xData.length - 2
											] &&
										this.axis.series[0]?.data[
											this.axis.series[0]?.data.length - 1
										]?.category ===
											CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
									) {
										return numberToMillions(this.total);
									} else {
										return null;
									}
								},
							},
						},
						{
							title: {
								text: null,
							},
							labels: {
								enabled: false,
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							lineColor: "transparent",
							visible: false,
							gridLineColor: "#197F07",
							lineWidth: 1,
							plotLines: [
								{
									color: "#FF0000",
									width: 1,
									value: 0,
								},
							],
						},
					],
					plotOptions: {
						series: {
							stacking: "normal",
						},
					},
					series: [
						{
							name: "Ventas $",
							valuePrefix: "$",
							data: (
								res?.totalRegionByYearAndMonth.filter(
									(s) => s.provinceName === r.provinceName
								) || []
							).map((p) => p.totalSales),
							yAxis: 0,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							marker: {
								fillColor: COLORS.White,
								lineWidth: 2,
								lineColor: COLORS.Primary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.totalRegionByYearAndMonth.filter(
											(s) => s.provinceName === r.provinceName
										)[
											res.totalRegionByYearAndMonth.filter(
												(s) => s.provinceName === r.provinceName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.totalRegionByYearAndMonth.filter(
													(s) => s.provinceName === r.provinceName
											  ).length - 2
											: res?.totalRegionByYearAndMonth.filter(
													(s) => s.provinceName === r.provinceName
											  ).length,
									color: COLORS.Primary,
								},
								{
									color: COLORS.LightSecondary,
								},
							],
						},
					],
				},
				ordersGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						formatter: function () {
							return "<b>" + this.x + "</b></br>" + this.y;
						},
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Default, COLORS.Grey],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.regionOrdersList.filter(
								(s) => s.provinceName === r.provinceName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: true,
						},
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					series: [
						{
							name: "",
							data: (
								res?.regionOrdersList.filter(
									(s) => s.provinceName === r.provinceName
								) || []
							).map((p) => p.totalOrders),
							lineWidth: 2,
							lineColor: COLORS.Success,
							marker: {
								fillColor: COLORS.Secondary,
								lineWidth: 2,
								lineColor: COLORS.Secondary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.regionOrdersList.filter(
											(s) => s.provinceName === r.provinceName
										)[
											res.regionOrdersList.filter(
												(s) => s.provinceName === r.provinceName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.regionOrdersList.filter(
													(s) => s.provinceName === r.provinceName
											  ).length - 2
											: res?.regionOrdersList.filter(
													(s) => s.provinceName === r.provinceName
											  ).length,
									color: COLORS.Success,
								},
								{
									color: COLORS.LightSuccess,
								},
							],
						},
					],
				},
				totals:
					res?.regionTotalsList.filter(
						(s) => s.provinceName === r.provinceName
					)[0] || [],
			}));

			setRegionTable(
				auxTable
					.filter((r) => r.totals.totalSales && r.totals.totalSales > 0)
					.sort((a, b) => b.totals.totalSales - a.totals.totalSales)
			);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_distributor_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_distributor_dashboard(
				selectedDistributor,
				selectedLaboratory,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD6 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD6 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD6 = JSON.parse(res?.generalInfo?.laboratoryList);

			setDistributorsD6(
				resDistributorsD6?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD6(
				resProvincesD6?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD6(
				resLaboratoriesD6?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setGeneralInfoD6(res?.generalInfo);

			let cumulativeCategories = [];
			let cumulativeSeries = [];

			const allCumulativeDates = res?.accumulatedClientsByYearAndMonth
				?.map(
					({ relationYear, relationMonth }) =>
						`${relationYear}-${String(relationMonth).padStart(2, "0")}`
				)
				?.sort();

			const cumulativeUniqueDates = [...new Set(allCumulativeDates)];

			let cumulativeDataByDistributor = res?.distributorsInfoList
				?.map((d) => d.distributorName)
				?.reduce((acc, name) => {
					acc[name] = cumulativeUniqueDates.map(() => 0);
					return acc;
				}, {});

			res?.accumulatedClientsByYearAndMonth?.forEach(
				({ relationYear, relationMonth, accumulatedClients, name }) => {
					if (cumulativeDataByDistributor[name]) {
						const date = `${relationYear}-${String(relationMonth).padStart(
							2,
							"0"
						)}`;
						const index = cumulativeUniqueDates.indexOf(date);
						if (index !== -1) {
							cumulativeDataByDistributor[name][index] = accumulatedClients;
						}
					}
				}
			);

			cumulativeCategories = cumulativeUniqueDates;
			cumulativeSeries = Object.keys(cumulativeDataByDistributor)?.map(
				(name, index) => ({
					name,
					data: cumulativeDataByDistributor[name],
					lineColor: COLORS.ChartColors[index],
					color: COLORS.ChartColors[index],
					lineWidth: 2,
					marker: {
						fillColor: "white",
						lineWidth: 2,
						lineColor: COLORS.ChartColors[index],
						symbol: "circle",
						radius: 3,
					},
				})
			);

			setEvolutionDistributorClientsGraph({
				chart: { type: "spline", zoomType: "xy" },
				title: { text: undefined },
				credits: { enabled: false },
				xAxis: { categories: cumulativeCategories, min: 0 },
				yAxis: {
					title: { text: "Clientes activos" },
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
					},
					gridLineColor: COLORS.LightGrey,
					gridLineWidth: 1,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				tooltip: {
					shared: false,
					useHTML: true,
					formatter: function () {
						return `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.point.y}</b><br/>`;
					},
				},
				plotLines: [
					{
						color: "#FF0000",
						width: 1,
						value: 0,
					},
				],
				plotOptions: {
					series: {
						states: {
							hover: {
								enabled: true,
							},
						},
						marker: {
							enabled: true,
						},
					},
				},
				series: cumulativeSeries,
			});

			let categories = [];
			let series = [];

			const allDates = res?.totalClientsByYearAndMonth
				?.map(
					({ relationYear, relationMonth }) =>
						`${relationYear}-${String(relationMonth).padStart(2, "0")}`
				)
				?.sort();

			const uniqueDates = [...new Set(allDates)];

			let dataByDistributor = res?.distributorsInfoList
				?.map((d) => d.distributorName)
				?.reduce((acc, name) => {
					acc[name] = uniqueDates.map(() => 0);
					return acc;
				}, {});

			res?.totalClientsByYearAndMonth?.forEach(
				({ relationYear, relationMonth, activeClients, name }) => {
					if (dataByDistributor[name]) {
						const date = `${relationYear}-${String(relationMonth).padStart(
							2,
							"0"
						)}`;
						const index = uniqueDates.indexOf(date);
						if (index !== -1) {
							dataByDistributor[name][index] = activeClients;
						}
					}
				}
			);

			categories = uniqueDates;
			series = Object.keys(dataByDistributor)?.map((name, index) => ({
				name,
				data: dataByDistributor[name],
				lineColor: COLORS.ChartColors[index],
				color: COLORS.ChartColors[index],
				lineWidth: 2,
				marker: {
					fillColor: "white",
					lineWidth: 2,
					lineColor: COLORS.ChartColors[index],
					symbol: "circle",
					radius: 3,
				},
			}));

			setTimeDistributorClientsGraph({
				chart: { type: "spline" },
				title: { text: undefined },
				credits: { enabled: false },
				xAxis: { categories: categories, min: 0 },
				yAxis: {
					title: { text: "Clientes activos" },
					stackLabels: {
						enabled: true,
						style: {
							color: COLORS.MediumGrey,
							textOutline: 0,
						},
					},
					gridLineColor: COLORS.LightGrey,
					gridLineWidth: 1,
					lineWidth: 1,
					plotLines: [
						{
							color: "#FF0000",
							width: 1,
							value: 0,
						},
					],
				},
				tooltip: {
					shared: false,
					useHTML: true,
					formatter: function () {
						return `<span style="color:${this.point.color}">\u25CF</span> ${this.series.name}: <b>${this.point.y}</b><br/>`;
					},
				},
				plotLines: [
					{
						color: "#FF0000",
						width: 1,
						value: 0,
					},
				],
				plotOptions: {
					series: {
						states: {
							hover: {
								enabled: true,
							},
						},
						marker: {
							enabled: true,
						},
					},
				},
				series,
			});

			const distributorInfoCategories = res?.distributorsInfoList?.map(
				(d) => d.distributorName
			);
			const distributorInfoTotalSales = res?.distributorsInfoList?.map(
				(d) => d.totalSales
			);
			const distributorInfoTotalClients = res?.distributorsInfoList?.map(
				(d) => d.totalClients || 0
			);
			const distributorInfoActiveClients = res?.distributorsInfoList?.map(
				(d) => d.totalBuyers
			);

			setDistributorInfoGraph({
				chart: {
					zoomType: "xy",
				},
				title: {
					text: undefined,
				},
				tooltip: {
					shared: true,
					pointFormat:
						'<span style="color:{"#0A4997"}">{series.name}</span><b> {point.y}</b><br/>',
					valueDecimals: 0,
					split: false,
				},
				// legend: {
				// 	enabled: true,
				// },
				credits: { enabled: false },
				xAxis: [
					{
						categories: distributorInfoCategories,
						crosshair: true,
					},
				],
				yAxis: [
					{
						labels: {
							formatter: function () {
								return this.value === 0
									? 0
									: (this.value / 1000000).toFixed(2) + "M";
							},
							style: {
								color: COLORS.Primary,
							},
						},
						visible: true,
						title: {
							text: "Ventas totales",
							style: {
								color: COLORS.Primary,
							},
						},
						gridLineColor: COLORS.LightGrey,
						gridLineWidth: 1,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
						stackLabels: {
							enabled: true,
							style: {
								color: COLORS.MediumGrey,
								textOutline: 0,
							},
							y: -2,
							overflow: "allow",
							crop: false,
							formatter: function () {
								return numberToMillions(this.total);
							},
						},
					},
					{
						title: {
							text: "Farmacias asociadas",
							style: {
								color: COLORS.Secondary,
							},
						},
						visible: true,
						labels: {
							style: {
								color: COLORS.Secondary,
							},
						},
						opposite: true,
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					{
						title: {
							text: "Active clients",
							style: {
								color: COLORS.Default,
							},
						},
						visible: false,
						labels: {
							style: {
								color: COLORS.Default,
							},
						},
						opposite: true,
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
				],
				plotOptions: {
					series: {
						pointWidth: 20,
						colorByPoint: true,
						colors: COLORS.ChartColors,
						stacking: "normal",
					},
				},
				//colors: COLORS.ChartColors,
				series: [
					{
						name: "Ventas totales",
						type: "column",
						yAxis: 0,
						data: distributorInfoTotalSales,
						colorByPoint: true,
						colors: COLORS.ChartColors,
						tooltip: {
							valuePrefix: "$",
						},
						color: COLORS.Black,
					},
					{
						name: "Farmacias asociadas",
						type: "spline",
						yAxis: 1,
						data: distributorInfoTotalClients,
						lineColor: COLORS.DarkGrey,
						color: COLORS.DarkGrey,
						lineWidth: 2,
						dashStyle: "ShortDash",
						marker: {
							fillColor: "white",
							lineWidth: 2,
							lineColor: COLORS.DarkGrey,
							symbol: "circle",
							radius: 3,
						},
						//colorByPoint: false,
					},
					{
						name: "Farmacias compradoras",
						visible: false,
						type: "spline",
						yAxis: 1,
						data: distributorInfoActiveClients,
						lineColor: COLORS.MediumGrey,
						color: COLORS.MediumGrey,
						lineWidth: 2,
						marker: {
							fillColor: "white",
							lineWidth: 2,
							lineColor: COLORS.MediumGrey,
							symbol: "circle",
							radius: 3,
						},
						//colorByPoint: false,
					},
				],
			});

			const auxTable = res?.distributorsInfoList?.map((r, idx) => ({
				photoUrl: r.distributorPhotoUrl,
				distributor: r.distributorName,
				totalClients: r.totalClients,
				salesGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						pointFormat:
							'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
						valueDecimals: 0,
						split: false,
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Primary, COLORS.Secondary],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.distributorSalesByYearAndMonth.filter(
								(s) => s.distributorName === r.distributorName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: [
						{
							title: {
								text: null,
							},
							labels: {
								enabled: false,
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							lineColor: "transparent",
							visible: false,
							gridLineColor: "#197F07",
							lineWidth: 1,
							plotLines: [
								{
									color: "#FF0000",
									width: 1,
									value: 0,
								},
							],
							stackLabels: {
								enabled: true,
								style: {
									color: COLORS.MediumGrey,
									textOutline: 0,
								},
								y: -10,
								formatter: function () {
									if (
										this.x ===
											this.axis.series[0]?.xData[
												this.axis.series[0]?.xData.length - 2
											] &&
										this.axis.series[0]?.data[
											this.axis.series[0]?.data.length - 1
										]?.category ===
											CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
									) {
										return numberToMillions(this.total);
									} else {
										return null;
									}
								},
							},
						},
						{
							title: {
								text: null,
							},
							labels: {
								enabled: false,
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							lineColor: "transparent",
							visible: false,
							gridLineColor: "#197F07",
							lineWidth: 1,
							plotLines: [
								{
									color: "#FF0000",
									width: 1,
									value: 0,
								},
							],
						},
					],
					plotOptions: {
						series: {
							stacking: "normal",
						},
					},
					series: [
						{
							name: "Ventas $",
							valuePrefix: "$",
							data: (
								res?.distributorSalesByYearAndMonth.filter(
									(s) => s.distributorName === r.distributorName
								) || []
							).map((p) => p.totalSales),
							yAxis: 0,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							marker: {
								fillColor: COLORS.White,
								lineWidth: 2,
								lineColor: COLORS.Primary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.distributorSalesByYearAndMonth.filter(
											(s) => s.distributorName === r.distributorName
										)[
											res.distributorSalesByYearAndMonth.filter(
												(s) => s.distributorName === r.distributorName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.distributorSalesByYearAndMonth.filter(
													(s) => s.distributorName === r.distributorName
											  ).length - 2
											: res?.distributorSalesByYearAndMonth.filter(
													(s) => s.distributorName === r.distributorName
											  ).length,
									color: COLORS.Primary,
								},
								{
									color: COLORS.LightSecondary,
								},
							],
						},
					],
				},
				unitsGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						formatter: function () {
							return "<b>" + this.x + "</b></br>" + this.y;
						},
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Default, COLORS.Grey],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.distributorSalesByYearAndMonth.filter(
								(s) => s.distributorName === r.distributorName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: true,
						},
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					series: [
						{
							name: "",
							data: (
								res?.distributorSalesByYearAndMonth.filter(
									(s) => s.distributorName === r.distributorName
								) || []
							).map((p) => p.totalUnits),
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							marker: {
								fillColor: COLORS.White,
								lineWidth: 2,
								lineColor: COLORS.Secondary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.distributorSalesByYearAndMonth.filter(
											(s) => s.distributorName === r.distributorName
										)[
											res.distributorSalesByYearAndMonth.filter(
												(s) => s.distributorName === r.distributorName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.distributorSalesByYearAndMonth.filter(
													(s) => s.distributorName === r.distributorName
											  ).length - 2
											: res?.distributorSalesByYearAndMonth.filter(
													(s) => s.distributorName === r.distributorName
											  ).length,
									color: COLORS.Secondary,
								},
								{
									color: COLORS.LightSecondary,
								},
							],
						},
					],
				},
				ordersGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						formatter: function () {
							return "<b>" + this.x + "</b></br>" + this.y;
						},
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Default, COLORS.Grey],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.distributorSalesByYearAndMonth.filter(
								(s) => s.distributorName === r.distributorName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: true,
						},
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					series: [
						{
							name: "",
							data: (
								res?.distributorSalesByYearAndMonth.filter(
									(s) => s.distributorName === r.distributorName
								) || []
							).map((p) => p.totalOrders),
							lineWidth: 2,
							lineColor: COLORS.Success,
							marker: {
								fillColor: COLORS.Secondary,
								lineWidth: 2,
								lineColor: COLORS.Secondary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.distributorSalesByYearAndMonth.filter(
											(s) => s.distributorName === r.distributorName
										)[
											res.distributorSalesByYearAndMonth.filter(
												(s) => s.distributorName === r.distributorName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.distributorSalesByYearAndMonth.filter(
													(s) => s.distributorName === r.distributorName
											  ).length - 2
											: res?.distributorSalesByYearAndMonth.filter(
													(s) => s.distributorName === r.distributorName
											  ).length,
									color: COLORS.Success,
								},
								{
									color: COLORS.LightSuccess,
								},
							],
						},
					],
				},
				totals:
					res?.distributorsInfoList.filter(
						(s) => s.distributorName === r.distributorName
					)[0] || [],
			}));

			setDistributorTable(
				auxTable
					.filter((r) => r.totals.totalSales && r.totals.totalSales > 0)
					.sort((a, b) => b.totals.totalSales - a.totals.totalSales)
			);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_laboratory_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_laboratory_dashboard(
				selectedDistributor,
				selectedLaboratory,
				selectedProvince,
				dateFrom,
				dateTo
			);
			const resDistributorsD7 = JSON.parse(res?.generalInfo?.distributorList);
			const resProvincesD7 = JSON.parse(res?.generalInfo?.provinceList);
			const resLaboratoriesD7 = JSON.parse(res?.generalInfo?.laboratoryList);

			setDistributorsD7(
				resDistributorsD7?.map((r) => ({
					value: r.DistributorId,
					label: r.DistributorName,
				}))
			);

			setProvincesD7(
				resProvincesD7?.map((r) => ({
					value: r.ProvinceName,
					label: r.ProvinceName,
				}))
			);

			setLaboratoriesD7(
				resLaboratoriesD7?.map((r) => ({
					value: r.LaboratoryId,
					label: r.LaboratoryName,
				}))
			);

			setGeneralInfoD7(res?.generalInfo);

			const auxTable = res?.laboratoriesInfoList?.map((r, idx) => ({
				photoUrl: r.laboratoryPhotoUrl,
				laboratory: r.laboratoryName,
				salesGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						pointFormat:
							'<span style="color:{series.color}">{series.name}</span><b> {point.y}</b><br/>',
						valueDecimals: 0,
						split: false,
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Primary, COLORS.Secondary],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.laboratorySalesByYearAndMonth.filter(
								(s) => s.laboratoryName === r.laboratoryName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: [
						{
							title: {
								text: null,
							},
							labels: {
								enabled: false,
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							lineColor: "transparent",
							visible: false,
							gridLineColor: "#197F07",
							lineWidth: 1,
							plotLines: [
								{
									color: "#FF0000",
									width: 1,
									value: 0,
								},
							],
							stackLabels: {
								enabled: true,
								style: {
									color: COLORS.MediumGrey,
									textOutline: 0,
								},
								y: -10,
								formatter: function () {
									if (
										this.x ===
											this.axis.series[0]?.xData[
												this.axis.series[0]?.xData.length - 2
											] &&
										this.axis.series[0]?.data[
											this.axis.series[0]?.data.length - 1
										]?.category ===
											CONSTANTS.CALENDAR_MONTHS[res.generalInfo.actualMonth]
									) {
										return numberToMillions(this.total);
									} else {
										return null;
									}
								},
							},
						},
						{
							title: {
								text: null,
							},
							labels: {
								enabled: false,
							},
							gridLineWidth: 0,
							minorGridLineWidth: 0,
							lineColor: "transparent",
							visible: false,
							gridLineColor: "#197F07",
							lineWidth: 1,
							plotLines: [
								{
									color: "#FF0000",
									width: 1,
									value: 0,
								},
							],
						},
					],
					plotOptions: {
						series: {
							stacking: "normal",
						},
					},
					series: [
						{
							name: "Ventas $",
							valuePrefix: "$",
							data: (
								res?.laboratorySalesByYearAndMonth.filter(
									(s) => s.laboratoryName === r.laboratoryName
								) || []
							).map((p) => p.totalSales),
							yAxis: 0,
							lineWidth: 2,
							lineColor: COLORS.Primary,
							marker: {
								fillColor: COLORS.White,
								lineWidth: 2,
								lineColor: COLORS.Primary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.laboratorySalesByYearAndMonth.filter(
											(s) => s.laboratoryName === r.laboratoryName
										)[
											res.laboratorySalesByYearAndMonth.filter(
												(s) => s.laboratoryName === r.laboratoryName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.laboratorySalesByYearAndMonth.filter(
													(s) => s.laboratoryName === r.laboratoryName
											  ).length - 2
											: res?.laboratorySalesByYearAndMonth.filter(
													(s) => s.laboratoryName === r.laboratoryName
											  ).length,
									color: COLORS.Primary,
								},
								{
									color: COLORS.LightSecondary,
								},
							],
						},
					],
				},
				unitsGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						formatter: function () {
							return "<b>" + this.x + "</b></br>" + this.y;
						},
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Default, COLORS.Grey],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.laboratorySalesByYearAndMonth.filter(
								(s) => s.laboratoryName === r.laboratoryName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: true,
						},
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					series: [
						{
							name: "",
							data: (
								res?.laboratorySalesByYearAndMonth.filter(
									(s) => s.laboratoryName === r.laboratoryName
								) || []
							).map((p) => p.totalUnits),
							lineWidth: 2,
							lineColor: COLORS.Secondary,
							marker: {
								fillColor: COLORS.White,
								lineWidth: 2,
								lineColor: COLORS.Secondary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.laboratorySalesByYearAndMonth.filter(
											(s) => s.laboratoryName === r.laboratoryName
										)[
											res.laboratorySalesByYearAndMonth.filter(
												(s) => s.laboratoryName === r.laboratoryName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.laboratorySalesByYearAndMonth.filter(
													(s) => s.laboratoryName === r.laboratoryName
											  ).length - 2
											: res?.laboratorySalesByYearAndMonth.filter(
													(s) => s.laboratoryName === r.laboratoryName
											  ).length,
									color: COLORS.Secondary,
								},
								{
									color: COLORS.LightSecondary,
								},
							],
						},
					],
				},
				ordersGraph: {
					chart: { type: "spline" },
					title: {
						text: undefined,
					},
					tooltip: {
						shared: true,
						formatter: function () {
							return "<b>" + this.x + "</b></br>" + this.y;
						},
					},
					legend: {
						enabled: false,
					},
					colors: [COLORS.Default, COLORS.Grey],
					credits: { enabled: false },
					xAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: false,
						},
						lineWidth: 0,
						categories: (
							res?.laboratorySalesByYearAndMonth.filter(
								(s) => s.laboratoryName === r.laboratoryName
							) || []
						).map((p) => CONSTANTS.CALENDAR_MONTHS[p.saleMonth]),
					},
					yAxis: {
						visible: false,
						title: {
							text: null,
						},
						labels: {
							enabled: true,
						},
						gridLineColor: "#197F07",
						gridLineWidth: 0,
						lineWidth: 1,
						plotLines: [
							{
								color: "#FF0000",
								width: 1,
								value: 0,
							},
						],
					},
					series: [
						{
							name: "",
							data: (
								res?.laboratorySalesByYearAndMonth.filter(
									(s) => s.laboratoryName === r.laboratoryName
								) || []
							).map((p) => p.totalOrders),
							lineWidth: 2,
							lineColor: COLORS.Success,
							marker: {
								fillColor: COLORS.Secondary,
								lineWidth: 2,
								lineColor: COLORS.Secondary,
								symbol: "circle",
								radius: 3,
							},
							zoneAxis: "x",
							zones: [
								{
									value:
										res?.laboratorySalesByYearAndMonth.filter(
											(s) => s.laboratoryName === r.laboratoryName
										)[
											res.laboratorySalesByYearAndMonth.filter(
												(s) => s.laboratoryName === r.laboratoryName
											).length - 1
										]?.saleMonth === res.generalInfo.actualMonth
											? res?.laboratorySalesByYearAndMonth.filter(
													(s) => s.laboratoryName === r.laboratoryName
											  ).length - 2
											: res?.laboratorySalesByYearAndMonth.filter(
													(s) => s.laboratoryName === r.laboratoryName
											  ).length,
									color: COLORS.Success,
								},
								{
									color: COLORS.LightSuccess,
								},
							],
						},
					],
				},
				totals:
					res?.laboratoriesInfoList.filter(
						(s) => s.laboratoryName === r.laboratoryName
					)[0] || [],
			}));

			setLaboratoryTable(
				auxTable
					.filter((r) => r.totals.totalSales && r.totals.totalSales > 0)
					.sort((a, b) => b.totals.totalSales - a.totals.totalSales)
			);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const fetch_buys_table_conversion_dashboard = async () => {
		setIsLoading(true);
		try {
			const res = await get_buys_table_conversion_dashboard(selectedTypeOfSale);
			setBuysTableConversion(res.pharmaciesFirstBuyList);
		} catch (error) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_GENERAL) fetch_general_dashboard();
	}, [selectedDistributor, selectedProvince, dateFrom, dateTo, display]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_SALES) fetch_sales_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
		selectedTypeOfSale,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_MOBILE) fetch_mobile_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedUserD3,
		selectedProvince,
		dateFromD3,
		dateToD3,
		display,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_PHARMACY) fetch_pharmacy_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedPharmacy,
		selectedPointOfSale,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_REGION) fetch_region_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_DISTRIBUTOR)
			fetch_distributor_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_LABORATORY)
			fetch_laboratory_dashboard();
	}, [
		selectedDistributor,
		selectedLaboratory,
		selectedProvince,
		dateFrom,
		dateTo,
		display,
	]);

	useEffect(() => {
		if (display === CONSTANTS.DASHBOARD_CONVERSION)
			fetch_buys_table_conversion_dashboard();
	}, [selectedTypeOfSale, display]);

	const handleSelectDistributor = (e, o) => {
		setSelectedDistributor(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Distributor" },
		]);
	};

	const handleSelectProvince = (e, o) => {
		setSelectedProvince(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Province" },
		]);
	};

	const handleSelectedDate = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: "Fecha", key: "DateRange" },
		]);
	};

	const handleDeleteFilters = () => {
		setSelectedDistributor(null);
		setSelectedProvince(null);
		setSelectedLaboratory(null);
		setSelectedPharmacy(null);
		setSelectedUserD3(null);
		setDateFrom(null);
		setDateTo(null);
		setRangeDate(null);
		setSelectedFilters([]);
		setMobilePeriod(CONSTANTS.PERIOD_CURRENT);
		setDateFromD3(moment().startOf("month").format("MM-DD-YYYY").toString());
		setDateToD3(moment().endOf("month").format("MM-DD-YYYY").toString());
		setRangeDateD3(null);
		setSelectedTypeOfSale(null);
		onCloseFilterList();
	};

	const handleSelectLaboratory = (e, o) => {
		setSelectedLaboratory(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Laboratory" },
		]);
	};

	const handleSelectSellerD3 = (e, o) => {
		setSelectedUserD3(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Seller" },
		]);
	};

	const handleSelectedDateD3 = (dateRange) => {
		const firstDate = moment(dateRange[0]).format("MM-DD-YYYY").toString();
		const lastDate = moment(dateRange[1]).format("MM-DD-YYYY").toString();
		setDateFromD3(firstDate);
		setDateToD3(lastDate);
		setDateFrom(firstDate);
		setDateTo(lastDate);
		setRangeDate([dateRange[0], dateRange[1]]);
	};

	const handleDistributorGraphSelect = (value) => {
		setDistributorGraphSelector(value);
	};

	const handleDistributorGraphSelectD6 = (value) => {
		setDistributorGraphSelectorD6(value);
	};

	const handlePeriodChange = (value) => {
		console.log(value);
		setMobilePeriod(value);
		if (value === CONSTANTS.PERIOD_CURRENT) {
			const startOfMonth = moment()
				.startOf("month")
				.format("MM-DD-YYYY")
				.toString();
			const endOfMonth = moment()
				.endOf("month")
				.format("MM-DD-YYYY")
				.toString();
			setDateFromD3(startOfMonth);
			setDateToD3(endOfMonth);
			setRangeDateD3([moment().startOf("month"), moment().endOf("month")]);
		}

		if (value === CONSTANTS.PERIOD_HISTORICAL) {
			setDateFromD3(dateFrom);
			setDateToD3(dateTo);
			setRangeDateD3(rangeDate);
		}
	};

	const handleSelectPharmacy = (e, o) => {
		setSelectedPharmacy(e);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "Pharmacy" },
		]);
	};

	const handleLaboratoryGraphSelect = (value) => {
		setLaboratoryGraphSelector(value);
	};

	const handleProductGraphSelect = (value) => {
		setProductGraphSelector(value);
	};

	const handleDealsInfoSelect = (value) => {
		setDealsInfoSelector(value);
	};

	const handleOrdersInfoSelect = (value) => {
		setOrdersInfoSelector(value);
	};

	const handleDistributorsPieInfoSelect = (value) => {
		setDistributorsPieInfoSelector(value);
	};

	const handlePharmacyPieInfoSelect = (value) => {
		setPharmacyPieInfoSelector(value);
	};

	const handleMenuClick = ({ key }) => {
		setDisplay(key);
	};

	const handleRegionInfoSelect = (value) => {
		setRegionInfoSelector(value);
	};

	const handleDistributorInfoSelect = (value) => {
		setDistributorInfoSelector(value);
	};

	const handleLaboratoryInfoSelect = (value) => {
		setLaboratoryInfoSelector(value);
	};

	const handleVSGraphD2 = (value) => {
		setVSInfoSelector(value);
	};

	const handleLaboratoryGraphSelectD2 = (value) => {
		setLaboratoryGraphSelectorD2(value);
	};

	const handleProductGraphSelectD2 = (value) => {
		setProductGraphSelectorD2(value);
	};

	const handleProductGraphSelectD3 = (value) => {
		setProductGraphSelectorD3(value);
	};

	const handleTypeOfSaleD2 = (value, o) => {
		setSelectedTypeOfSale(value);
		setSelectedFilters((prevFilters) => [
			...prevFilters,
			{ label: o?.label, key: "TypeOfSale" },
		]);
	};

	const handleFilterRemove = (filterToRemove) => {
		setSelectedFilters((prevFilters) =>
			prevFilters.filter((filter) => filter !== filterToRemove)
		);
		//onCloseFilterList();

		switch (filterToRemove.key) {
			case "Distributor":
				setSelectedDistributor(null);
				break;
			case "Province":
				setSelectedProvince(null);
				break;
			case "Laboratory":
				setSelectedLaboratory(null);
				break;
			case "DateRange":
				setDateFrom(null);
				setDateTo(null);
				setRangeDate(null);
				break;
			case "Seller":
				setSelectedUserD3(null);
				break;
			case "Pharmacy":
				setSelectedPharmacy(null);
				break;
			case "TypeOfSale":
				setSelectedTypeOfSale(null);
				break;
			default:
				break;
		}
	};

	const showFilterList = (value) => {
		setOpenFilterList(value);
	};

	const onCloseFilterList = () => {
		setOpenFilterList(false);
	};

	const handleDownloadPharmacyTable = async () => {
		setIsLoading(true);
		try {
			const dashboardData = {
				pharmaciesToExport: pharmaciesTable,
			};
			const res = await export_dashboard_pharmacies(dashboardData);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"PharmacyDashboard_" + moment().format("DDMMYYYYhhmmss") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDownloadPosConversionTable = async () => {
		setIsLoading(true);
		try {
			const dashboardData = {
				pharmaciesToExport: buysTableConversion,
			};
			const res = await export_dashboard_pos_table_conversion(dashboardData);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"PosTableBuysConversion_" + moment().format("DDMMYYYYhhmmss") + ".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Sider
				theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}
				// onMouseEnter={() => setCollapsed(false)}
				//onMouseLeave={() => setCollapsed(true)}
			>
				<div></div>
				<Menu
					theme="light"
					mode="inline"
					defaultSelectedKeys={[CONSTANTS.DASHBOARD_SALES]}
					style={{ paddingTop: 20 }}
					onClick={handleMenuClick}
				>
					<Tooltip
						placement="right"
						title={"Filtros aplicados"}
						trigger={
							selectedFilters.length > 0
								? checkBrowser().safari
									? ""
									: "hover"
								: ""
						}
						style={{ width: "100%" }}
					>
						<FilterContainerButton
							key={CONSTANTS.DASHBOARD_FILTERS}
							onClick={
								selectedFilters.length > 0
									? (prev) => showFilterList((prev) => !prev)
									: null
							}
							conditionFullfilled={selectedFilters.length > 0}
							height={45}
						>
							{selectedFilters.length > 0 ? (
								<FaInfo color={COLORS.Default} size={16} />
							) : null}
						</FilterContainerButton>{" "}
					</Tooltip>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_SALES}
						icon={<BarChartOutlined />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_SALES)}
					>
						Ventas
					</Menu.Item>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_GENERAL}
						icon={<PieChartOutlined />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_GENERAL)}
					>
						General
					</Menu.Item>
					{/* <Menu.Item
						key={CONSTANTS.DASHBOARD_MOBILE}
						icon={<MobileOutlined />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_MOBILE)}
					>
						Fuerza de venta
					</Menu.Item> */}
					<Menu.Item
						key={CONSTANTS.DASHBOARD_PHARMACY}
						icon={<TiPlusOutline />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_PHARMACY)}
					>
						Farmacias
					</Menu.Item>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_REGION}
						icon={<TiWorld />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_REGION)}
					>
						Región
					</Menu.Item>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_DISTRIBUTOR}
						icon={<FaTruck />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_DISTRIBUTOR)}
					>
						Droguerías
					</Menu.Item>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_LABORATORY}
						icon={<GiMicroscope />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_LABORATORY)}
					>
						Laboratorios
					</Menu.Item>
					<Menu.Item
						key={CONSTANTS.DASHBOARD_CONVERSION}
						icon={<PercentageOutlined />}
						onClick={() => setDisplay(CONSTANTS.DASHBOARD_CONVERSION)}
					>
						Conversiones
					</Menu.Item>
				</Menu>
				{selectedFilters.length > 0 && (
					<DrawerContainer
						title={
							<Typo type="primary" level={7}>
								Filtros aplicados
							</Typo>
						}
						placement="left"
						closable={false}
						onClose={onCloseFilterList}
						open={openFilterList}
						getContainer={false}
					>
						<div style={{ height: 170 }}>
							{selectedFilters.map((filter, index) => (
								<div
									key={index}
									onClick={() => handleFilterRemove(filter)}
									style={{
										cursor: "pointer",
										height: 30,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typo level={7} type="muted">
										{filter.label}
									</Typo>
									<CloseCircleOutlined style={{ color: COLORS.Default }} />
								</div>
							))}
						</div>
						<div
							onClick={() => onCloseFilterList()}
							style={{
								position: "absolute",
								bottom: 5,
								display: "flex",
								justifyContent: "center",
								alignItems: "flex-end",
								width: "75%",
								cursor: "pointer",
							}}
						>
							<Typo type="muted" level={7}>
								Cerrar
							</Typo>
						</div>
					</DrawerContainer>
				)}
			</Sider>
			<Layout className="site-layout">
				<Inner>
					{display === CONSTANTS.DASHBOARD_GENERAL &&
						(generalInfoD1 ? (
							<Dashboard1
								dashboard={generalInfoD1}
								distributorList={distributorsD1}
								provinceList={provincesD1}
								optionsPie={pieDataD1}
								timePharmacyGraph={timePharmacyGraphD1}
								timePosGraph={timePosGraphD1}
								timeOrderGraph={timeOrdersGraphD1}
								posByRegionGraph={pharmacyByRegionD1}
								timeSalesGraph={timeTotalSalesD1}
								distributorSalesGraph={distributorSalesGraphD1}
								distributors={distributorsD1}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD1}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								rangeDate={rangeDate}
								pharmacyInfoSelector={pharmacyPieInfoSelector}
								selectPharmacyInfo={handlePharmacyPieInfoSelect}
								timeSalesAgainstOrders={timeSalesAgainstOrdersD1}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_SALES &&
						(generalInfoD2 ? (
							<Dashboard2
								dashboard={generalInfoD2}
								distributorList={distributorsD2}
								provinceList={provincesD2}
								optionsPie={pieDataD2}
								distributors={distributorsD2}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD2}
								selectProvince={handleSelectProvince}
								laboratories={laboratoriesD2}
								selectLaboratory={handleSelectLaboratory}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								timeSalesGraph={timeSalesGraphD2}
								timePharmacyOrdersGraph={timePharmacyOrdersGraphD2}
								timeOrderGraph={timeOrdersGraphD2}
								distributorSalesGraph={distributorSalesGraphD2}
								timeUnitsGraph={timeUnitsGraphD2}
								selectedLaboratory={selectedLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								laboratorySalesGraph={laboratorySalesGraphD2}
								laboratoryUnitsGraph={laboratoryUnitsGraphD2}
								productSalesGraph={productSalesGraphD2}
								productUnitsGraph={productUnitsGraphD2}
								regionSalesGraph={regionSalesGraphD2}
								rangeDate={rangeDate}
								ordersInfoSelector={ordersInfoSelector}
								selectOrdersInfo={handleOrdersInfoSelect}
								distributorsInfoSelector={distributorsPieInfoSelector}
								selectDistributorsInfo={handleDistributorsPieInfoSelect}
								optionsPie2={secondPieDataD2}
								timeVSSalesGraph={timeVSSalesGraphD2}
								timeVSUnitsGraph={timeVSUnitsGraphD2}
								timeVSOrdersGraph={timeVSOrdersGraphD2}
								selectVSInfo={handleVSGraphD2}
								VSInfoSelector={VSInfoSelector}
								typeOfSales={typesOfSalesD2}
								selectedTypeOfSale={selectedTypeOfSale}
								handleTypeOfSale={handleTypeOfSaleD2}
								productGraphSelector={productGraphSelectorD2}
								selectProductGraph={handleProductGraphSelectD2}
								laboratoryGraphSelector={laboratoryGraphSelectorD2}
								selectLaboratoryGraph={handleLaboratoryGraphSelectD2}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{/* {display === CONSTANTS.DASHBOARD_MOBILE &&
						(generalInfoD3 ? (
							<Dashboard3
								dashboard={generalInfoD3}
								distributorList={distributorsD3}
								provinceList={provincesD3}
								distributors={distributorsD3}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD3}
								selectProvince={handleSelectProvince}
								laboratories={laboratoriesD3}
								selectLaboratory={handleSelectLaboratory}
								sellers={usersD3}
								selectSeller={handleSelectSellerD3}
								handleDateChange={handleSelectedDateD3}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								posOrderingGraph={timePharmacyOrdersGraphD3}
								sellersTable={sellerSalesTableD3}
								timeOrdersGraph={timeOrdersGraphD3}
								distributorSalesGraph={distributorSalesGraphD3}
								distributorUnitsGraph={distributorUnitsGraphD3}
								distributorOrdersGraph={distributorOrdersGraphD3}
								selectedLaboratory={selectedLaboratory}
								selectedSeller={selectedUserD3}
								distributorGraphSelector={distributorGraphSelector}
								selectDistributorGraph={handleDistributorGraphSelect}
								selectPeriod={handlePeriodChange}
								rangeDate={rangeDateD3}
								mobilePeriod={mobilePeriod}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								productSalesGraph={productSalesGraphD3}
								productUnitsGraph={productUnitsGraphD3}
								productGraphSelector={productGraphSelectorD3}
								selectProductGraph={handleProductGraphSelectD3}
							/>
						) : (
							<LoadingSkeleton />
						))} */}
					{display === CONSTANTS.DASHBOARD_PHARMACY &&
						(generalInfoD4 ? (
							<Dashboard4
								dashboard={generalInfoD4}
								provinceList={provincesD4}
								pharmacies={pharmaciesD4}
								selectPharmacy={handleSelectPharmacy}
								distributors={distributorsD4}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD4}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								laboratories={laboratoriesD4}
								selectLaboratory={handleSelectLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedLaboratory={selectedLaboratory}
								selectedPharmacy={selectedPharmacy}
								isLoading={isLoading}
								optionsPie={pieDataD4}
								timeTotalSales={timeTotalSalesD4}
								laboratorySalesGraph={laboratorySalesGraphD4}
								laboratoryUnitsGraph={laboratoryUnitsGraphD4}
								laboratoryGraphSelector={laboratoryGraphSelector}
								selectLaboratoryGraph={handleLaboratoryGraphSelect}
								productSalesGraph={productSalesGraphD4}
								productUnitsGraph={productUnitsGraphD4}
								productGraphSelector={productGraphSelector}
								selectProductGraph={handleProductGraphSelect}
								dealsInfoSelector={dealsInfoSelector}
								selectDealsInfo={handleDealsInfoSelect}
								pharmaciesTable={pharmaciesTable}
								rangeDate={rangeDate}
								setMarketOrdersPharmacy={setMarketOrdersPharmacyId}
								handleDownloadPharmacyTable={handleDownloadPharmacyTable}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_REGION &&
						(generalInfoD5 ? (
							<Dashboard5
								dashboard={generalInfoD5}
								provinceList={provincesD5}
								distributors={distributorsD5}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD5}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								laboratories={laboratoriesD5}
								selectLaboratory={handleSelectLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedLaboratory={selectedLaboratory}
								isLoading={isLoading}
								rangeDate={rangeDate}
								regionTable={regionTable}
								regionInfoSelector={regionInfoSelector}
								selectRegionInfo={handleRegionInfoSelect}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_DISTRIBUTOR &&
						(generalInfoD6 ? (
							<Dashboard6
								dashboard={generalInfoD6}
								provinceList={provincesD6}
								distributors={distributorsD6}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD6}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								laboratories={laboratoriesD6}
								selectLaboratory={handleSelectLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedLaboratory={selectedLaboratory}
								isLoading={isLoading}
								rangeDate={rangeDate}
								distributorTable={distributorTable}
								distributorInfoSelector={distributorInfoSelector}
								selectDistributorInfo={handleDistributorInfoSelect}
								timeDistributorClientGraph={timeDistributorClientsGraph}
								distributorsInfoGraph={distributorInfoGraph}
								evolutionDistributorClientGraph={
									evolutionDistributorClientsGraph
								}
								distributorGraphSelector={distributorGraphSelectorD6}
								selectDistributorGraph={handleDistributorGraphSelectD6}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_LABORATORY &&
						(generalInfoD7 ? (
							<Dashboard7
								dashboard={generalInfoD7}
								provinceList={provincesD7}
								distributors={distributorsD7}
								selectDistributor={handleSelectDistributor}
								provinces={provincesD7}
								selectProvince={handleSelectProvince}
								handleDateChange={handleSelectedDate}
								deleteFilters={handleDeleteFilters}
								laboratories={laboratoriesD7}
								selectLaboratory={handleSelectLaboratory}
								selectedDistributor={selectedDistributor}
								selectedProvince={selectedProvince}
								selectedLaboratory={selectedLaboratory}
								isLoading={isLoading}
								rangeDate={rangeDate}
								laboratoryTable={laboratoryTable}
								laboratoryInfoSelector={laboratoryInfoSelector}
								selectLaboratoryInfo={handleLaboratoryInfoSelect}
							/>
						) : (
							<LoadingSkeleton />
						))}
					{display === CONSTANTS.DASHBOARD_CONVERSION &&
						(buysTableConversion ? (
							<Dashboard8
								dashboard={buysTableConversion}
								typeOfSales={typesOfSalesD2}
								selectedTypeOfSale={selectedTypeOfSale}
								handleTypeOfSale={handleTypeOfSaleD2}
								deleteFilters={handleDeleteFilters}
								isLoading={isLoading}
								handleDownloadPosTable={handleDownloadPosConversionTable}
							/>
						) : (
							<LoadingSkeleton />
						))}
				</Inner>
			</Layout>
		</Layout>
	);
}

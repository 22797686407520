import React from "react";
import { Container, TableContainer } from "./styles";
import { Select, Table } from "antd";
import { FaImages } from "react-icons/fa";
import { CONSTANTS } from "../../../../../../../utils/constants";
import { COLORS, Typo } from "../../../../../../layout/theme";
import Image from "../../../../../../common/image/Image";

export default function ProductStateSelector({
	statesValues,
	setStatesValues,
	generalState,
}) {
	const orderStates = [
		CONSTANTS.ORDER_ITEM_STATE_PROCESSED,
		CONSTANTS.ORDER_ITEM_STATE_BILLED,
		CONSTANTS.ORDER_ITEM_STATE_STOCK,
		CONSTANTS.ORDER_ITEM_STATE_WITHOUT_STOCK,
		CONSTANTS.ORDER_ITEM_STATE_UNEXIST,
		CONSTANTS.ORDER_ITEM_STATE_REJECTED,
	];

	const orderGeneralStatesDictionary = [
		{ value: CONSTANTS.ORDER_STATE_PROCESSED, label: "PROCESADO" },
		{ value: CONSTANTS.ORDER_STATE_BILLED, label: "FACTURADO" },
		{ value: CONSTANTS.ORDER_STATE_REJECTED, label: "RECHAZADO" },
	];

	const handleSelect = (id, state) => {
		const _items = statesValues.map((d) =>
			d.id === id ? { ...d, productStateInOrder: state } : { ...d }
		);
		setStatesValues(_items);
	};

	const handleSelectAll = (state) => {
		const _items = statesValues.map((d) => ({
			...d,
			productStateInOrder: state,
		}));
		setStatesValues(_items);
	};

	const columns = [
		{
			title: (algo) => {
				return (
					<div
						style={{
							fontSize: "18px",
						}}
					>
						<FaImages style={{ color: COLORS.DarkGrey }} />
					</div>
				);
			},
			dataIndex: "productPhotoUrl",
			key: "productPhotoUrl",
			width: 80,
			align: "center",
			render: (value, record, index) => (
				<Image width={30} height={30} src={value} alt={record.productTitle} />
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>EAN</div>,
			dataIndex: "productEan",
			key: "productEan",
			render: (value, record, index) => (
				<Typo level={7} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Producto</div>,
			dataIndex: "productTitle",
			key: "productTitle",
			render: (value, record, index) => (
				<Typo level={7} type="mediumGrey">
					{value} {record.productPresentation}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Estado </div>,
			dataIndex: "productStateInOrder",
			key: "productStateInOrder",
			width: 150,
			render: (value, record, index) => (
				<Select defaultValue={record.productStateInOrder} disabled={true}>
					{orderStates?.map((i) => (
						<Select.Option value={i} key={i}>
							{i}
						</Select.Option>
					))}
				</Select>
			),
		},
	];

	return (
		<TableContainer>
			<Container>
				<Typo level={6} weight="SemiBold">
					Productos
				</Typo>
				{/* <Select
					style={{ marginBottom: 16, width: 220 }}
					placeholder="Seleccionar estado general"
					onChange={handleSelectAll}
					defaultValue={generalState}
				>
					{orderGeneralStatesDictionary?.map((state, idx) => (
						<Select.Option key={idx} value={state.value}>
							{state.label}
						</Select.Option>
					))}
				</Select> */}
			</Container>
			<Table
				size="small"
				columns={columns}
				dataSource={statesValues || []}
				pagination={false}
			/>
		</TableContainer>
	);
}

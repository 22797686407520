import { Skeleton } from "antd";

export default function LoadingSkeleton() {
	return (
		<Skeleton
			active
			size="default"
			paragraph={{
				rows: 5,
			}}
		/>
	);
}

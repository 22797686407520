import React, { useEffect, useRef, useState } from "react";
import { get_search } from "../../../../api/endpoints/search";
import {
	AntdInput,
	CompaginationDiv,
	ContainerDiv,
	FilterSection,
	Inner,
	ResultsSection,
	SectionBanners,
} from "./styles";
import { useParams, useSearchParams } from "react-router-dom";
import FilterContainer from "../../../containers/filter/FilterContainer";
import ProductSearchCardContainer from "../../../containers/product-search-cards/ProductSearchCardContainer";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import ProductSearchCardListContainer from "../../../containers/product-search-cards-list/ProductSearchCardListContainer";
import Slider from "../../../common/slider/Slider";
import { SwiperSlide } from "swiper/react";
import PublicitySlideContainer from "../../../containers/product-slide/PublicitySlideContainer";
import { get_banners } from "../../../../api/endpoints/banner";
import { CONSTANTS } from "../../../../utils/constants";
import { Button, Input } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactGA from "react-ga4";

export default function Offers() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [skip, setSkip] = useState(1);
	const [page, setPage] = useState(CONSTANTS.PAGE_MEDIUM_SIZE);
	const [searchInfo, setSearchInfo] = useState(null);
	const params = useParams();
	const [display, setDisplay] = useState("LIST");
	const [offerBannerImages, setOfferBannerImages] = useState(null);
	const [width, setWidth] = useState(window.innerWidth);
	const [paginationInputValue, setPaginationInputValue] = useState(null);

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/offers", title: "Ofertas" });
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const fetch_banners = async () => {
		const res = await get_banners("", "ACTIVE");
		setOfferBannerImages(
			res
				.filter((b) => b.title === "OFFERBANNER" && b.isActive)
				.map((b) => ({
					id: b.id,
					title: b.title,
					src: b.photoUrl,
					bannerUrl: b.bannerUrl,
				}))
		);
	};

	useEffect(() => {
		fetch_banners();
	}, []);

	const fetch_search = async (skipValue) => {
		setLoading(true);

		const res = await get_search(
			params.s,
			searchParams.get("c") ? searchParams.get("c") : "",
			searchParams.get("i") ? searchParams.get("i") : "",
			searchParams.get("l") ? searchParams.get("l") : "",
			searchParams.get("r") ? searchParams.get("r") : "",
			searchParams.get("t") ? searchParams.get("t") : "",
			searchParams.get("p") ? searchParams.get("p") : "",
			searchParams.get("b") ? searchParams.get("b") : "",
			searchParams.get("d") ? searchParams.get("d") : "",
			searchParams.get("h") ? searchParams.get("h") : "",
			"OFFER",
			skipValue,
			page
		);
		if (res) {
			setLoading(false);
			setData(res);
			setSearchInfo(res.searchInfo);
			setPaginationInputValue(res.searchInfo?.actualPage);
		}
	};

	const handleDecrement = () => {
		if (searchInfo.actualPage > 1) {
			const cp = searchInfo.actualPage - 1;
			fetch_search(cp);
			setSkip(cp);
		}
	};

	const handleIncrement = () => {
		if (searchInfo.actualPage < searchInfo.totalPages) {
			const cp = searchInfo.actualPage + 1;
			fetch_search(cp);
			setSkip(cp);
		}
	};

	const handlePageChange = (e) => {
		const selectedPage = parseInt(e.target.value);
		if (
			!isNaN(selectedPage) &&
			selectedPage >= 1 &&
			selectedPage <= searchInfo.totalPages
		) {
			fetch_search(selectedPage);
			setPaginationInputValue(selectedPage);
		} else {
			setPaginationInputValue(searchInfo.actualPage);
		}
	};

	const handleInputChange = (e) => {
		setPaginationInputValue(e.target.value);
	};

	useEffect(() => {
		setSkip(1);
		setPage(CONSTANTS.PAGE_MEDIUM_SIZE);
		fetch_search(1);
	}, [params.s, searchParams]);

	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	if (!data) return <LoadingSkeleton />;

	return (
		<div style={{ paddingTop: 20 }}>
			<Inner>
				<FilterSection>
					{data && (
						<FilterContainer
							searchTerm={data.term}
							appliedFilters={data?.selectedFilters}
							notAppliedFilters={data?.filters}
							resultsQty={data.quantityResults}
							setDisplay={setDisplay}
						/>
					)}
				</FilterSection>
				<ContainerDiv>
					<SectionBanners>
						<Slider
							loading={loading}
							slidesPerView={1}
							spaceBetween={3}
							navigation={false}
						>
							{offerBannerImages &&
								offerBannerImages.map((d, idx) => (
									<SwiperSlide key={`publicityOffers_slide_${idx}`}>
										<PublicitySlideContainer
											id={d.id}
											title={d.title}
											image={d.src}
											bannerUrl={d.bannerUrl}
											bannerHeight={"120px"}
											bannerBorder={"10px"}
										/>
									</SwiperSlide>
								))}
						</Slider>
					</SectionBanners>
					<ResultsSection display={display}>
						{data &&
							display === "CARD" &&
							data?.productSearch?.map((i, idx) => (
								<ProductSearchCardContainer
									key={`card_${i.productId}_${idx}`}
									item={i}
									display={display}
								/>
							))}
						{data &&
							display === "LIST" &&
							data?.productSearch?.map((i, idx) => (
								<ProductSearchCardListContainer
									key={`cardList_${i.productId}_${idx}`}
									item={i}
									display={display}
									eventCategory="OfferProducts"
									eventAction="add_to_cart"
								/>
							))}
					</ResultsSection>
					{searchInfo && searchInfo.totalProducts > 0 && (
						<CompaginationDiv>
							{searchInfo.actualPage !== 1 && (
								<Button size="small" shape="circle" onClick={handleDecrement}>
									<LeftOutlined />
								</Button>
							)}
							<AntdInput
								step={1}
								min={1}
								max={searchInfo.totalPages}
								onChange={handleInputChange}
								onPressEnter={(e) => handlePageChange(e)}
								value={
									paginationInputValue ? paginationInputValue.toString() : ""
								}
								addonAfter={`/ ${searchInfo.totalPages}`}
								style={{
									width: ` ${searchInfo.totalPages > 99 ? "106px" : "66px"}`,
								}}
							/>
							{searchInfo.actualPage < searchInfo.totalPages && (
								<Button
									size="small"
									shape="circle"
									onClick={handleIncrement}
									disabled={
										searchInfo.actualPage === searchInfo.totalPages
											? true
											: false
									}
								>
									<RightOutlined />
								</Button>
							)}
						</CompaginationDiv>
					)}
				</ContainerDiv>
			</Inner>
		</div>
	);
}

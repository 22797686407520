import React, { useEffect, useState } from "react";
import CartProducts from "./components/step-1-products/CartProducts";
import CartCheckout from "./components/step-2-checkout/CartCheckout";
import { Spin, Steps } from "antd";
import {
	AntdSteps,
	Body,
	ClientSelect,
	Container,
	IconContainer,
	Inner,
	StepsContainer,
	TitleContainer,
} from "./styles";
import StepCartIcon from "../../../../assets/images/cart-shopping-solid-step.svg";
import StepTruckIcon from "../../../../assets/images/truck-solid.svg";
import StepCartIconSelected from "../../../../assets/images/cart-shopping-solid-step-selected.svg";
import StepTruckIconSelected from "../../../../assets/images/truck-solid-selected.svg";
import Number1 from "../../../../assets/images/number-one-icon.svg";
import Number1Filled from "../../../../assets/images/number-one-solid.svg";
import Number2Grey from "../../../../assets/images/number-two.svg";
import Number2Filled from "../../../../assets/images/number-two-solid.svg";
import { useAuth } from "../../../../contexts/authContext";
import { get_pharmacies_with_totalPos_list_dapper } from "../../../../api/endpoints/clients";
import { useCart } from "../../../../contexts/cartContext";
import ZeroPointOfSale from "./components/zero-point-of-sale/ZeroPointOfSale";
import ReactGA from "react-ga4";
import { UseAnalyticsEventTracker } from "../../../../hooks/useAnalyticsEventTracker";
const { Step } = Steps;

export default function Cart() {
	const { userdata } = useAuth();
	const { selectedClient, userId, marketSellerClient } = useCart();
	const [step, setStep] = useState(0);
	const [selectedPointsOfSale, setSelectedPointsOfSale] = useState(null);
	const [selector, setSelector] = useState(null);
	const [pointOfSaleDistributors, setPointOfSaleDistributors] = useState(null);
	const [products, setProducts] = useState(null);
	const [checkoutDetail, setCheckoutDetail] = useState(null);
	const [clients, setClients] = useState(null);
	const [clientName, setClientName] = useState(null);
	const [pointOfSaleQty, setPointOfSaleQty] = useState(1);
	const [skip, setSkip] = useState(0);
	const [take, setTake] = useState(300);

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: "/cart", title: "Carro" });
	}, []);

	useEffect(() => {
		const fetch_clients = async () => {
			const res = await get_pharmacies_with_totalPos_list_dapper(
				"",
				skip,
				take,
				"ACTIVE"
			);
			setClients(res?.items);

			if (selectedClient) {
				setClientName(
					res?.items.filter((c) => c.id === selectedClient)[0]?.name
				);
				setPointOfSaleQty(
					res?.items.filter((c) => c.id === selectedClient)[0]?.pointOfSaleCount
				);
			}
		};

		fetch_clients();
	}, []);

	const handleStepSubmit = () => {
		UseAnalyticsEventTracker(
			"Cart",
			step === 1 ? "purchase" : "begin_checkout",
			userdata.firstName + " " + userdata.lastName
		);
		setStep((prev) => prev + 1);
	};

	const handleClientSelect = (value) => {
		setSelectedPointsOfSale(null);
		setClientName(clients.filter((c) => c.id === value)[0].name);
		marketSellerClient({ clientId: value });
		setPointOfSaleQty(
			clients.filter((c) => c.id === value)[0]?.pointOfSaleCount
		);
	};

	const renderStep = () => {
		switch (step) {
			case 0:
				return (
					<CartProducts
						onSubmitSuccess={handleStepSubmit}
						selectedPointsOfSale={selectedPointsOfSale}
						setSelectedPointsOfSale={setSelectedPointsOfSale}
						pointOfSaleDistributors={pointOfSaleDistributors}
						setPointOfSaleDistributors={setPointOfSaleDistributors}
						selector={selector}
						setSelector={setSelector}
						products={products}
						setProducts={setProducts}
						setCheckoutDetail={setCheckoutDetail}
						userId={userId}
						userdata={userdata}
					/>
				);
			case 1:
				return (
					<CartCheckout
						onSubmitSuccess={handleStepSubmit}
						selectedPointsOfSale={selectedPointsOfSale}
						products={products}
						checkoutDetail={checkoutDetail}
						userId={userId}
						userdata={userdata}
						clientName={clientName}
						selector={selector}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<Inner>
			<Body>
				<StepsContainer>
					<AntdSteps current={step} onChange={setStep}>
						<Step
							icon={
								<IconContainer
									src={step === 0 ? Number1Filled : Number1}
									alt="Number1"
									style={{ width: 30 }}
								/>
							}
							title={
								<Container>
									<IconContainer
										src={step === 0 ? StepCartIconSelected : StepCartIcon}
										alt="Step 1"
										style={{ width: 20 }}
									/>
									<TitleContainer>Pedido</TitleContainer>
								</Container>
							}
						/>
						<Step
							icon={
								<IconContainer
									src={step === 1 ? Number2Filled : Number2Grey}
									alt="Number2"
									style={{ width: 30 }}
								/>
							}
							title={
								<Container>
									<IconContainer
										src={step === 1 ? StepTruckIconSelected : StepTruckIcon}
										alt="Step 2"
										style={{ width: 20 }}
									/>
									<TitleContainer>Confirmación</TitleContainer>
								</Container>
							}
						/>
					</AntdSteps>
				</StepsContainer>
				{step === 0 &&
					((userdata.isOneMarket && userdata.p_shop && selectedClient) ||
						(!userdata.isOneMarket &&
							userdata.isSeller &&
							userdata.p_shop &&
							selectedClient)) && (
						<ClientSelect
							onChange={handleClientSelect}
							style={{ width: 260 }}
							placeholder="Seleccione una Farmacia"
							showSearch
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							options={clients?.map((c) => ({
								value: c.id,
								label: `Farmacia: ${c.name}`,
							}))}
							defaultValue={selectedClient}
							disabled={
								!userdata.isOneMarket &&
								userdata.isSeller &&
								userdata.p_shop &&
								selectedClient
							}
						>
							{clients?.map((item) => (
								<ClientSelect.Option key={item.id} value={item.id}>
									{item.name}
								</ClientSelect.Option>
							))}
						</ClientSelect>
					)}
				{pointOfSaleQty > 0 ? renderStep() : <ZeroPointOfSale />}
			</Body>
		</Inner>
	);
}

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Modal, Space } from "antd";
import {
	InnerForm,
	OrderData,
	InfoText,
	LineBreak,
	TitleContainer,
	SubTitleContainer,
	InfoContainer,
	TitleBanner,
	HeaderContainer,
	InfoTableContainer,
	OptionsButtonsContainer,
	InfoPriceContainer,
	InfoSubSection,
	Divider,
	InfoTitle,
	InfoTitleTotal,
	InfoSubTitle,
	Inner,
	InfoIva,
	PriceContainer,
	Container,
	ButtonContainer,
} from "./styles";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../../utils/notificationToast";
import Main from "../../../../layout/main/Main";
import LoadingSkeleton from "./components/loading-skeleton/LoadingSkeleton";
import COLORS from "../../../../layout/theme/colors/Colors";
import SignInTemplate from "../../../../layout/sign-in-template/SignInTemplate";
import {
	get_order_by_id_with_status,
	update_order_state,
} from "../../../../../api/endpoints/orders";
import OrderTable from "./components/order-table/OrderTable";
import OneTransferLogo from "../../../../../assets/images/Onetransfer-logo-box.png";
import Image from "../../../../common/image/Image";
import { Typo } from "../../../../layout/theme";
import { numberWithDotAndCommas } from "../../../../../utils/numberWithDotAndComas";
import moment from "moment";
import { CONSTANTS } from "../../../../../utils/constants";
import { InfoCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export default function MarketOrderDetail() {
	const { id } = useParams();
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [submitting, setSubmitting] = useState(false);
	const [imageUrl, setImageUrl] = useState();
	const [initialValues, setInitialValues] = useState(null);
	const [loading, setLoading] = useState(false);
	const [orderStateChange, setOrderStateChange] = useState(false);

	useEffect(() => {
		setLoading(true);
		const fetch_order_by_id = async () => {
			const data = await get_order_by_id_with_status(id);
			const order = {
				id: data.order.id,
				created: moment(data.order.created).format("dddd, DD MMMM YYYY"),
				client: data.order.clientName,
				photoUrl: data.order.clientPhotoUrl,
				name: data.order.userFirstName + " " + data.order.userLastName,
				email: data.order.userEmail,
				distributorId: data.order.distributorId,
				distributorName: data.order.distributorName,
				distributorPhoto: data.order.distributorPhotoUrl,
				pointOfSaleId: data.order.pointOfSaleId,
				pointOfSaleName: data.order.pointOfSaleName,
				pointOfSaleAddress: data.order.pointOfSaleAddress,
				quantityItems: data.order.quantityItems,
				subTotal: data.order.subtotal,
				total: data.order.total,
				orderNumber: data.order.orderNumber,
				orderNumId: data.order.orderNumId,
				orderState: data.order.orderState,
				orderSubState: data.order.orderSubState,
				pointOfSaleProvince: data.order.pointOfSaleProvince,
				pointOfSaleLocality: data.order.pointOfSaleLocality,
				items: data.items.map((p) => ({
					key: p.id,
					id: p.id,
					orderId: p.orderId,
					orderNumber: p.orderNumber,
					productBrand: p.productBrand,
					productClient: p.productClient,
					productDeleted: p.productDeleted,
					productDiscount: p.productDiscount,
					productDraft: p.productDraft,
					productEan13: p.productEan13,
					productGeneralDiscount: p.productGeneralDiscount,
					productId: p.productId,
					productOfferPrice: p.productOfferPrice,
					productPaused: p.productPaused,
					productPhotoUrl: p.productPhotoUrl,
					productPrice: p.productPrice,
					productQuantity: p.productQuantity,
					productStateInOrder: p.productStateInOrder,
					productStateInOrderDate: p.productStateInOrderDate,
					productTitle: p.productTitle,
					productTriggerDiscount: p.productTriggerDiscount,
					productTriggerQty: p.productTriggerQty,
					productUnitsBilled: p.productUnitsBilled,
					productUnitsPending: p.productUnitsPending,
					productUnitsTRF: p.productUnitsTRF,
					productComboId: p.productComboId,
					productComboName: p.productComboName,
				})),
			};

			setImageUrl(data.order.clientPhotoUrl);

			setInitialValues(order);
			setLoading(false);
		};

		if (id) {
			fetch_order_by_id();
		}
	}, [orderStateChange]);

	const onFinish = async (values) => {
		try {
			setSubmitting(true);
			navigate("/admin/marketOrders");
		} catch (error) {
			console.log(error);
			openNotificationWithIcon(TYPE.ERROR, "Error");
		} finally {
			setSubmitting(false);
		}
	};

	const selectStateToShow = (state) => {
		switch (state) {
			case CONSTANTS.ORDER_STATE_PENDING:
				return "Pendiente";
			case CONSTANTS.ORDER_STATE_ACTIVE:
				return "Activa";
			case CONSTANTS.ORDER_STATE_SENDED:
				return "Enviada a Droguería";
			case CONSTANTS.ORDER_STATE_CANCELED:
				return "Cancelada";
			case CONSTANTS.ORDER_STATE_PROCESSED:
				return "Procesada por droguería";

			default:
				return;
		}
	};

	const handleActiveOrder = () => {
		confirm({
			title: `¿Seguro que quiere activar esta orden?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El pedido será activado",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				activeOrderAction();
			},
		});
	};

	const handlePauseOrder = () => {
		confirm({
			title: `¿Seguro que quiere pausar esta orden?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El pedido será pausado",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				pauseOrderAction();
			},
		});
	};

	const handleCancelOrder = () => {
		confirm({
			title: `¿Seguro que quiere cancelar esta orden?`,
			icon: <InfoCircleOutlined style={{ color: "blue" }} />,
			content: "El pedido será anulado",
			okText: "Sí",
			okType: "default",
			cancelText: "No",
			onOk: () => {
				cancelOrderAction();
			},
		});
	};

	const cancelOrderAction = async () => {
		const _state = {
			id: initialValues.id,
			state: CONSTANTS.ORDER_STATE_CANCELED,
		};
		await update_order_state(initialValues.id, _state);
		setOrderStateChange((prev) => !prev);
	};

	const pauseOrderAction = async () => {
		const _state = {
			id: initialValues.id,
			state: CONSTANTS.ORDER_STATE_PENDING,
		};
		await update_order_state(initialValues.id, _state);
		setOrderStateChange((prev) => !prev);
	};

	const activeOrderAction = async () => {
		const _state = {
			id: initialValues.id,
			state: CONSTANTS.ORDER_STATE_ACTIVE,
		};
		await update_order_state(initialValues.id, _state);
		setOrderStateChange((prev) => !prev);
	};

	if (!id) navigate("/admin/compras");

	return (
		<div style={{ backgroundColor: COLORS.LightGrey }}>
			<HeaderContainer>
				<TitleBanner>
					<Typo type={"white"} fontWeight={600} fontSize={17}>
						GRACIAS POR TU PEDIDO
					</Typo>
				</TitleBanner>
				<TitleContainer>
					<Image
						src={OneTransferLogo}
						style={{ width: "50px", height: "50px" }}
					/>
				</TitleContainer>
			</HeaderContainer>
			<Main>
				<Main.Body>
					{!initialValues ? (
						<LoadingSkeleton />
					) : (
						<Form
							form={form}
							name="basic"
							onFinish={onFinish}
							autoComplete="off"
							requiredMark={false}
							layout="vertical"
						>
							<InnerForm>
								<OrderData>
									<SubTitleContainer>
										<Typo
											fontWeight={600}
											fontSize={16}
											style={{ color: "#6e6d6d" }}
										>
											Nombre de Comercio
										</Typo>
									</SubTitleContainer>
									<InfoContainer
										style={{
											display: "flex",
											alignItems: "center",
											alignContent: "center",
											justifyContent: "flex-start",
											gap: "10px",
										}}
									>
										<Form.Item name="pointOfSaleName">
											<InfoText>{initialValues.pointOfSaleName}:</InfoText>
										</Form.Item>
										<Form.Item name="pointOfSaleAddress">
											<InfoText>{initialValues.pointOfSaleAddress} - </InfoText>
										</Form.Item>
										<Form.Item name="pointOfSaleLocality">
											<InfoText>
												{initialValues.pointOfSaleLocality} -{" "}
											</InfoText>
										</Form.Item>
										<Form.Item name="pointOfSaleProvince">
											<InfoText>{initialValues.pointOfSaleProvince}</InfoText>
										</Form.Item>
									</InfoContainer>

									<SubTitleContainer
										style={{
											display: "flex",
											alignContent: "center",
											alignItems: "center",
											justifyContent: "space-between",
											marginBottom: "-30px",
										}}
									>
										<Form.Item>
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												DETALLE DE ORDEN #{initialValues.orderNumId}
											</Typo>
										</Form.Item>
										<Form.Item name="name">
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												USUARIO: {initialValues?.name}
											</Typo>
										</Form.Item>
									</SubTitleContainer>
									<LineBreak />

									<InfoContainer>
										<Form.Item name="created">
											<InfoText>Creada el {initialValues.created}</InfoText>
										</Form.Item>
										<Form.Item name="distributor">
											<InfoText>
												Enviada a la Droguería:
												<InfoText style={{ color: COLORS.Primary }}>
													{initialValues.distributorName}
												</InfoText>
											</InfoText>
										</Form.Item>
									</InfoContainer>

									<InfoContainer>
										<Form.Item name="distributor">
											<InfoText>
												Cantidad de Productos: {initialValues.items.length}
											</InfoText>
										</Form.Item>
										<Form.Item name="distributor">
											<InfoText>
												Unidades totales: {initialValues.quantityItems}
											</InfoText>
										</Form.Item>
									</InfoContainer>

									<SubTitleContainer
										style={{
											display: "flex",
											alignContent: "center",
											alignItems: "center",
											justifyContent: "flex-start",
											gap: 30,
											marginBottom: "10px",
										}}
									>
										<Form.Item
											style={{
												display: "flex",
												height: "100%",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												ESTADO DE ORDEN:{" "}
												{initialValues.orderSubState
													? selectStateToShow(initialValues.orderSubState)
													: selectStateToShow(initialValues.orderState)}
											</Typo>
										</Form.Item>
										{initialValues.orderState !==
											CONSTANTS.ORDER_STATE_SENDED && (
											<ButtonContainer>
												{(initialValues.orderState ===
													CONSTANTS.ORDER_STATE_PENDING ||
													initialValues.orderState ===
														CONSTANTS.ORDER_STATE_CANCELED) && (
													<SignInTemplate.AntdButton
														type="Warning"
														color="white"
														bg="Success"
														loading={submitting || loading}
														onClick={handleActiveOrder}
													>
														Activar pedido
													</SignInTemplate.AntdButton>
												)}
												{(initialValues.orderState ===
													CONSTANTS.ORDER_STATE_ACTIVE ||
													initialValues.orderState ===
														CONSTANTS.ORDER_STATE_CANCELED) && (
													<SignInTemplate.AntdButton
														type="Warning"
														color="white"
														bg="Warning"
														loading={submitting || loading}
														onClick={handlePauseOrder}
													>
														Pausar pedido
													</SignInTemplate.AntdButton>
												)}
												{(initialValues.orderState ===
													CONSTANTS.ORDER_STATE_ACTIVE ||
													initialValues.orderState ===
														CONSTANTS.ORDER_STATE_PENDING) && (
													<SignInTemplate.AntdButton
														type="Warning"
														color="white"
														bg="Danger"
														loading={submitting || loading}
														onClick={handleCancelOrder}
													>
														Cancelar pedido
													</SignInTemplate.AntdButton>
												)}
											</ButtonContainer>
										)}
									</SubTitleContainer>

									<SubTitleContainer
										style={{
											display: "flex",
											alignContent: "center",
											alignItems: "center",
											justifyContent: "center",
											marginBottom: "-30px",
										}}
									>
										<Form.Item>
											<Typo
												fontWeight={600}
												fontSize={16}
												style={{ color: "#6e6d6d" }}
											>
												LISTADO DE PRODUCTOS
											</Typo>
										</Form.Item>
									</SubTitleContainer>
									<LineBreak />
									<Inner>
										<OrderTable data={initialValues} />
										<PriceContainer>
											<Container style={{ alignItems: "flex-end" }}>
												<Typo type="default" level={6}>
													Subtotal:{" "}
												</Typo>
												<Typo type="default" level={6}>
													{" "}
													$ {numberWithDotAndCommas(initialValues.subTotal)}
												</Typo>
											</Container>
											<Container style={{ alignItems: "flex-end" }}>
												<Typo type="default" level={6}>
													Descuento:{" "}
												</Typo>
												<Typo type="secondary" level={5}>
													${" "}
													{numberWithDotAndCommas(
														Number(
															Math.round(
																parseFloat(
																	((initialValues.subTotal -
																		initialValues.total) *
																		100) /
																		100 +
																		"e" +
																		2
																)
															) +
																"e-" +
																2
														).toFixed(2)
													)}
												</Typo>
											</Container>
											<Container style={{ alignItems: "flex-end" }}>
												<Typo type="default" level={6}>
													Total:{" "}
												</Typo>
												<Typo type="default" level={4}>
													$ {numberWithDotAndCommas(initialValues.total)}
												</Typo>
											</Container>
										</PriceContainer>
										<InfoIva>IVA no incluido</InfoIva>
									</Inner>
								</OrderData>

								<OptionsButtonsContainer>
									<Form.Item>
										<SignInTemplate.AntdButton
											type="Primary"
											color="white"
											bg="Primary"
											htmlType="submit"
											loading={submitting}
										>
											Volver
										</SignInTemplate.AntdButton>
									</Form.Item>
								</OptionsButtonsContainer>
							</InnerForm>
						</Form>
					)}
				</Main.Body>{" "}
			</Main>
		</div>
	);
}

import { DatePicker } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { BsDownload } from "react-icons/bs";
import { Link, useSearchParams } from "react-router-dom";
import { get_auditory } from "../../../../api/endpoints/auditory";
import {
	openNotificationWithIcon,
	TYPE,
} from "../../../../utils/notificationToast";
import Main from "../../../layout/main/Main";
import { Typo } from "../../../layout/theme";
import {
	AntdButton,
	Container,
	FilterContainer,
	ImageSection,
	Inner,
} from "./styles";

export default function LaboratoryAuditory({ clientId }) {
	const [searchParams] = useSearchParams();
	const uri = searchParams.get("uri");
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!selectedMonth) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Falta elegir un mes",
				"Elija un mes para poder descargar la auditoría mensual."
			);
			return;
		}

		setIsLoading(true);
		try {
			const data = {
				month: moment(selectedMonth).month(),
				year: moment(selectedMonth).year(),
				client: clientId,
			};
			const res = await get_auditory(data.month, data.year, data.client);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"Auditory_" +
				"Month_" +
				(data.month + 1) +
				"_Year_" +
				data.year +
				"_" +
				moment().format("DDMMYYYYhhmmss") +
				".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDownloadYear = async () => {
		if (!selectedYear) {
			openNotificationWithIcon(
				TYPE.ERROR,
				"Falta elegir un año",
				"Elija un año para poder descargar la auditoría anual."
			);
			return;
		}

		setIsLoading(true);
		try {
			const data = {
				month: "",
				year: moment(selectedYear).year(),
				client: clientId,
			};
			const res = await get_auditory(data.month, data.year, data.client);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			const fileName =
				"Auditory_" +
				"Year_" +
				data.year +
				"_" +
				moment().format("DDMMYYYYhhmmss") +
				".xlsx";
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						{uri ? (
							<Link to={"/" + uri}>Configuración General</Link>
						) : (
							<Link to="/">Home</Link>
						)}{" "}
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>Auditoria</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Inner>
					<ImageSection>
						<Typo type="primary" level={5}>
							Auditoria pedidos
						</Typo>
						<Container>
							<FilterContainer>
								<DatePicker.MonthPicker
									onChange={(e) => setSelectedMonth(e)}
									placeholder="Seleccionar mes"
									format="MMMM"
								/>
							</FilterContainer>
							<AntdButton onClick={(e) => handleSubmit(e)} loading={isLoading}>
								<BsDownload
									style={{ position: "relative", top: "0.8px", left: "-5px" }}
								/>{" "}
								Descargar Auditoria Mensual
							</AntdButton>
						</Container>
						<Container>
							<DatePicker.YearPicker
								onChange={(e) => setSelectedYear(e)}
								placeholder="Seleccionar año"
								format="YYYY"
							/>
							<AntdButton
								onClick={(e) => handleDownloadYear(e)}
								loading={isLoading}
							>
								<BsDownload
									style={{ position: "relative", top: "0.8px", left: "-5px" }}
								/>{" "}
								Descargar Auditoria Anual
							</AntdButton>
						</Container>
					</ImageSection>
				</Inner>
			</Main.Body>
		</Main>
	);
}

import styled from "styled-components";
import { COLORS } from "../../../../../../layout/theme";

export const Inner = styled.div`
	display: flex;
	gap: 50px;
`;
export const UserData = styled.div`
	flex: 1 1 80%;
`;
export const PublishSettings = styled.div`
	flex: 1 1 20%;
`;

export const TableHeader = styled.div`
	width: 950px;
	height: 40px;
	background-color: ${COLORS.SkeletonGrey};
`;

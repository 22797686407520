import styled from "styled-components";
import { Table } from "antd";
import { COLORS, SIZES } from "../../../../../layout/theme";

export const Line = styled.span`
	position: relative;
	&::before {
		content: "";
		width: 120%;
		background-color: ${COLORS.Grey};
		height: 3px;
		position: absolute;
		z-index: 99;
		top: 50%;
		left: -2px;
	}
`;
export const OfferPrice = styled.span`
	margin-left: 10px;
	color: ${COLORS.Secondary};
	font-size: ${SIZES.level11};
`;
export const Price = styled.span``;

export const ActionButton = styled.button`
	border: none;
	border-radius: 5px;
	background-color: transparent;
	padding: 5px;
	cursor: pointer;
	color: ${COLORS.Primary};

	&:hover {
		background-color: ${COLORS.Grey};
	}
`;

export const AntdTable = styled(Table)`
	& .noBackground,
	& .noBackground:hover {
		background-color: ${COLORS.LightGrey} !important;
		border: none;
	}

	.ant-table-thead > tr > th {
		background: ${COLORS.Primary};
	}

	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(
			.ant-table-row-expand-icon-cell
		):not([colspan])::before {
		background-color: ${COLORS.White};

		div {
			color: ${COLORS.White};
		}
	}

	thead {
		border-color: ${COLORS.White};
		border: 1px solid ${COLORS.White};
	}
`;

export const Container = styled.div`
	cursor: pointer;
`;

import React from "react";
import ReactGA from "react-ga4";

const UseAnalyticsEventTracker = (category, action, label) => {
	ReactGA.event({
		category: category,
		action: action,
		label: label,
	});
};

export { UseAnalyticsEventTracker };

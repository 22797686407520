import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { FaShoppingCart } from "react-icons/fa";

import React, { useState } from "react";
import Image from "../../common/image/Image";
import OfferSlide from "../../common/slides/product-slide/ProductSlide";
import { useCart } from "../../../contexts/cartContext";
import { useAuth } from "../../../contexts/authContext";
import { Button, Col, Input, Modal, Row } from "antd";
import { COLORS, SIZES, Typo, WEIGHT } from "../../layout/theme";
import {
	CenterHeaderContainer,
	Container,
	HeaderLeft,
	HeaderRight,
	InfoUnit,
	ItemsQty,
	LeftHeaderContainer,
	Line,
	MarketingStar,
	MinQtyContainer,
	OffContainer,
	PslContainer,
	RightHeaderContainer,
	SectionOffer,
	SpecialOfferContainer,
	StarContainer,
	TitleContainer,
	TopHeaderContainer,
	TriggerQtyContainer,
} from "./styles/style";
import ProductModal from "../../common/product-modal/ProductModal";
import { numberWithDotAndCommas } from "../../../utils/numberWithDotAndComas";
import { IoMegaphoneOutline } from "react-icons/io5";
import { add_user_interaction } from "../../../api/endpoints/users";
import { add_view } from "../../../api/endpoints/products";
import { compareWithToday } from "../../../utils/compareDates";
import { UseAnalyticsEventTracker } from "../../../hooks/useAnalyticsEventTracker";

export default function OfferSlideContainer({
	id,
	title,
	price,
	offerPrice,
	offerTo,
	discount,
	image,
	labImage,
	qtyInCart,
	clientName,
	minQuantity = 1,
	maxQuantity = 999,
	productPresentation,
	productQty,
	productUnit,
	marketing,
	clientTypeOfSale,
	triggerQty,
	triggerDiscount,
	setFlag = null,
	setSelectedPointsOfSale = null,
	setProducts = null,
	setSliderData = null,
	isComboProduct = false,
	productNotes = null,
	eventCategory = "Test category",
	eventAction = "Add_to_cart",
}) {
	const { addToCart, cartItems } = useCart();
	const { userdata } = useAuth();
	const [hover, setHover] = useState(false);
	const [quantity, setQuantity] = useState(
		qtyInCart ? qtyInCart : minQuantity > 0 ? minQuantity : 1
	);
	const [quantityInCart, setQuantityInCart] = useState(
		cartItems && cartItems[id]
			? cartItems[id].quantity
			: qtyInCart
			? qtyInCart
			: 0
	);
	const [submitting, setSubmitting] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showAddToCart, setShowAddToCart] = useState(qtyInCart ? true : false);

	const handleDecrement = async () => {
		setSubmitting(true);
		let res;
		let newQuantity = quantity;
		if (quantity > minQuantity && quantity > 1) {
			newQuantity -= 1;
			setQuantity((prev) => prev - 1);
		}
		if (newQuantity <= minQuantity) {
			res = await addToCart(id, minQuantity, false);
		} else {
			res = await addToCart(id, 1, false);
		}
		if (res === 0) {
			setQuantityInCart(res);
			setQuantity(minQuantity > 0 ? minQuantity : 1);
			setShowAddToCart(false);
		} else {
			setQuantityInCart(res);
			setQuantity(res);
			setShowAddToCart(true);
		}

		setSubmitting(false);
	};

	const handleAddToCart = async () => {
		setSubmitting(true);
		const res = await addToCart(id, quantity);
		setSelectedPointsOfSale && setSelectedPointsOfSale(null);
		setProducts && setProducts(null);
		setSliderData && setSliderData(null);
		setFlag && setFlag((prev) => !prev);
		setQuantityInCart(res);
		setQuantity(minQuantity);
		setShowAddToCart(true);
		UseAnalyticsEventTracker(
			eventCategory,
			eventAction,
			userdata.firstName + " " + userdata.lastName
		);
		setSubmitting(false);
	};

	const handleChange = (e) => {
		if (e.target.value <= minQuantity) {
			e.target.value = minQuantity;
			e.target.setSelectionRange(0, 0);
			e.target.focus();
			e.target.select();
		}
		if (e.target.value >= maxQuantity) e.target.value = maxQuantity;
		const onlyPositiveInteger = /^[1-9\b]+$/;
		if (onlyPositiveInteger.test(e.target.value))
			setQuantity(parseInt(e.target.value));
	};

	const handleIncrement = async (e) => {
		setSubmitting(true);
		setQuantity((prev) => prev + 1);
		if (quantity >= maxQuantity) {
			setQuantity((prev) => prev - 1);
		} else {
			const res = await addToCart(id, 1);
			setQuantityInCart(res);
			setQuantity(res);
			setShowAddToCart(true);
			setSubmitting(false);
		}
	};

	const handleFocus = (event) => event.target.select();

	const handleDetail = async () => {
		setIsModalVisible(true);
		if (!userdata?.isSeller && !userdata?.isOneMarket) {
			await add_view(id);
			await add_user_interaction({ productId: id, inCart: false });
		}
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<>
			<OfferSlide>
				<OfferSlide.SectionContainer onClick={handleDetail}>
					<OfferSlide.Header>
						<HeaderLeft>
							<Container>
								<OfferSlide.InfoLab>
									<Image src={labImage} />
								</OfferSlide.InfoLab>
								<Typo level={8} type="muted">
									{clientName?.slice(0, 20).toUpperCase()}
								</Typo>
							</Container>
							<TitleContainer>
								<Typo
									type="primary"
									lineHeight={1}
									texto="medium"
									fontSize={13}
								>
									{title}{" "}
									<Typo
										type="muted"
										texto="medium"
										lineHeight={1}
										fontSize={13}
									>
										{productPresentation}
									</Typo>
								</Typo>
							</TitleContainer>
							{/* <Typo
								style={{ textTransform: "capitalize" }}
								level={9}
								type="primary"
							>
								{title.length > 30 ? title.slice(0, 28) + "..." : title}
							</Typo> */}
						</HeaderLeft>
						{/* <HeaderRight>
							{(productPresentation === "Comprimidos" ||
								productPresentation === "Comprimido") &&
								productQty && (
									<InfoUnit>
										{productQty} {productUnit}
									</InfoUnit>
								)}
						</HeaderRight> */}
					</OfferSlide.Header>
				</OfferSlide.SectionContainer>
				<OfferSlide.Main
					onClick={handleDetail}
					style={{ position: "relative" }}
				>
					<TopHeaderContainer>
						<LeftHeaderContainer>
							{/* {marketing && (
								<MarketingStar>
									<IoMegaphoneOutline size={15} />
								</MarketingStar>
							)} */}
						</LeftHeaderContainer>
						<CenterHeaderContainer>
							<ItemsQty>
								{quantityInCart === 0
									? " "
									: quantityInCart === 1
									? `${quantityInCart} agregado`
									: `${quantityInCart} agregados`}
							</ItemsQty>
						</CenterHeaderContainer>
						<RightHeaderContainer>
							{discount > 0 &&
								offerTo &&
								compareWithToday(offerTo) &&
								!isComboProduct && (
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											zIndex: 4,
											overflow: "visible",
											position: "absolute",
										}}
									>
										<PslContainer>PSL</PslContainer>
										<PslContainer
											style={{
												fontSize: SIZES.level4,
												fontWeight: WEIGHT.ExtraBold,
												marginTop: "-7px",
											}}
										>
											-{discount.toFixed(0)}%
										</PslContainer>
									</div>
								)}
							{isComboProduct && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										zIndex: 4,
										overflow: "visible",
										position: "absolute",
									}}
								>
									<PslContainer>Hasta</PslContainer>
									<PslContainer
										style={{
											fontSize: SIZES.level4,
											fontWeight: WEIGHT.ExtraBold,
											marginTop: "-7px",
										}}
									>
										-{productNotes?.split(" ")[1]}
									</PslContainer>
								</div>
							)}
						</RightHeaderContainer>
					</TopHeaderContainer>
					<Image height={150} width="100%" src={image} />
					<SectionOffer
						style={{
							backgroundColor:
								triggerQty > 0
									? COLORS.Success
									: minQuantity > 1
									? COLORS.White
									: COLORS.White,
							color: COLORS.White,
						}}
					>
						{triggerQty > 0 ? (
							<TriggerQtyContainer style={{ color: COLORS.White }}>
								Comprando {triggerQty} o más
							</TriggerQtyContainer>
						) : minQuantity > 1 ? (
							<MinQtyContainer>Compra mínima {minQuantity} un.</MinQtyContainer>
						) : (
							` `
						)}
						{triggerQty > 0 && (
							<SpecialOfferContainer>
								<OffContainer translate="no">OFF</OffContainer>
								<Typo level={5} type="white">
									{`${numberWithDotAndCommas(
										(
											100 -
											((offerPrice - (offerPrice * triggerDiscount) / 100) *
												100) /
												price
										).toFixed(2)
									)}%`}
								</Typo>
							</SpecialOfferContainer>
						)}
					</SectionOffer>
				</OfferSlide.Main>
				<Modal
					title={
						<div style={{ height: 30 }}>
							<Image
								src={labImage}
								style={{ objectFit: "contain", width: 30 }}
							/>{" "}
							<Typo level={8} type="muted">
								{clientName?.slice(0, 20).toUpperCase()}
							</Typo>
						</div>
					}
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					width={900}
					centered={true}
					footer={null}
					destroyOnClose
				>
					<ProductModal
						id={id}
						quantityInCart={quantityInCart}
						setQuantityInCart={setQuantityInCart}
						setIsModalVisible={setIsModalVisible}
						minQuantity={minQuantity}
						maxQuantity={maxQuantity}
						offerTo={offerTo}
						marketing={marketing}
						setFlag={setFlag}
						setSelectedPointsOfSale={setSelectedPointsOfSale}
						setProducts={setProducts}
						setSliderData={setSliderData}
					/>
				</Modal>
				<OfferSlide.Footer
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					{hover &&
					((userdata.p_shop && !userdata.isSeller) ||
						(userdata.p_shop && userdata.isOnemarket)) ? (
						submitting === true ? (
							<OfferSlide.SpinnerSection>
								<OfferSlide.Spinner />
							</OfferSlide.SpinnerSection>
						) : (
							<OfferSlide.FooterInputs toggle={showAddToCart}>
								{!showAddToCart ? (
									<Button
										type="primary"
										onClick={handleAddToCart}
										loading={submitting}
										style={{ borderRadius: "0 0 6px 6px" }}
									>
										<FaShoppingCart style={{ fontSize: "15px" }} />
									</Button>
								) : (
									<>
										<Button
											type="primary"
											onClick={handleDecrement}
											style={{ borderRadius: "0 0 0 6px" }}
										>
											<MinusOutlined />
										</Button>
										<Input
											value={quantity}
											onChange={handleChange}
											onFocus={handleFocus}
											style={{
												pointerEvents: "none",
											}}
										/>
										<Button
											type="primary"
											onClick={handleIncrement}
											style={{ borderRadius: "0 0 6px 0" }}
										>
											<PlusOutlined />
										</Button>
									</>
								)}
							</OfferSlide.FooterInputs>
						)
					) : (
						<OfferSlide.FooterContainer onClick={handleDetail}>
							<OfferSlide.FooterPrices>
								{offerPrice !== price ? (
									<Row justify="space-between" align="middle">
										{isComboProduct && (
											<Col md={24} xl={24}>
												<OfferSlide.FooterPricesLeft>
													{/* <OfferSlide.FooterPricesLeftTextContainer> */}
													<OfferSlide.FooterPricesLeftTextBox>
														<Typo
															level={8}
															type="primary"
															lineHeight={1.2}
															fontSize={9}
															id="clientType"
														>
															{clientTypeOfSale}
														</Typo>
													</OfferSlide.FooterPricesLeftTextBox>
													{/* </OfferSlide.FooterPricesLeftTextContainer> */}
													<Typo
														level={8}
														type="primary"
														lineHeight={1.2}
														fontSize={10}
														id="symbol"
													>
														$
													</Typo>
													<Line
														level={7}
														type="primary"
														fontSize={19}
														style={{ position: "relative" }}
													>
														{numberWithDotAndCommas(price)}
													</Line>
												</OfferSlide.FooterPricesLeft>
											</Col>
										)}
										{!isComboProduct && (
											<Col md={12} xl={12}>
												<OfferSlide.FooterPricesLeft>
													{/* <OfferSlide.FooterPricesLeftTextContainer> */}
													<OfferSlide.FooterPricesLeftTextBox>
														<Typo
															level={8}
															type="primary"
															lineHeight={1.2}
															fontSize={9}
															id="clientType"
														>
															{clientTypeOfSale}
														</Typo>
													</OfferSlide.FooterPricesLeftTextBox>
													{/* </OfferSlide.FooterPricesLeftTextContainer> */}
													<Typo
														level={8}
														type="primary"
														lineHeight={1.2}
														fontSize={10}
														id="symbol"
													>
														$
													</Typo>
													<Line
														level={7}
														type="primary"
														fontSize={19}
														style={{ position: "relative" }}
													>
														{numberWithDotAndCommas(price)}
													</Line>
												</OfferSlide.FooterPricesLeft>
											</Col>
										)}
										{!isComboProduct && (
											<Col md={12} xl={12}>
												<OfferSlide.FooterPricesRight>
													<OfferSlide.FooterPriceTextOff translate="no">
														OFF
													</OfferSlide.FooterPriceTextOff>
													<Typo
														level={4}
														style={{
															paddingLeft: "10px",
															//color: "#1FD655",
															color: COLORS.Secondary,
														}}
													>
														{`${numberWithDotAndCommas(
															(100 - (offerPrice * 100) / price).toFixed(2)
														)}%`}
													</Typo>
												</OfferSlide.FooterPricesRight>
											</Col>
										)}
										<Col md={0} xl={0}></Col>
									</Row>
								) : (
									<OfferSlide.FooterPricesCenter>
										<Typo
											level={8}
											type="primary"
											lineHeight={1.2}
											fontSize={9}
										>
											{clientTypeOfSale}
										</Typo>
										<div style={{ display: "flex", alignItems: "center" }}>
											<span
												style={{
													fontSize: 10,
													color: COLORS.Primary,
													lineHeight: 1.2,
												}}
											>
												$
											</span>
											<Typo
												level={4}
												type="primary"
												style={{ position: "relative" }}
											>
												{numberWithDotAndCommas(price)}
											</Typo>
										</div>
									</OfferSlide.FooterPricesCenter>
								)}
							</OfferSlide.FooterPrices>
						</OfferSlide.FooterContainer>
					)}
				</OfferSlide.Footer>
			</OfferSlide>
		</>
	);
}

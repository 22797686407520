import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
	download_file_content,
	get_generated_file_list,
} from "../../../../api/endpoints/auditory";
import Main from "../../../layout/main/Main";
import { COLORS, Typo } from "../../../layout/theme";
import {
	AntdTable,
	Container,
	ImageSection,
	Inner,
	TableContainer,
} from "./styles";
import { get_distributors_name_list_checkpos } from "../../../../api/endpoints/distributors";
import { get_megaDistributors_name_list_checkpos } from "../../../../api/endpoints/megaDistributors";
import { CONSTANTS } from "../../../../utils/constants";
import {
	TYPE,
	openNotificationWithIcon,
} from "../../../../utils/notificationToast";
import moment from "moment";
import { get_clients_name_list_filtered } from "../../../../api/endpoints/clients";

const { Option } = Select;

export default function SendedFiles() {
	const [isLoading, setIsLoading] = useState(false);
	const [distributors, setDistributors] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const [selectedDistributor, setSelectedDistributor] = useState(null);
	const [generatedFileList, setGeneratedFileList] = useState(null);
	const [folderState, setFolderState] = useState("enviados");
	const [clients, setClients] = useState(null);
	const [selectedClient, setSelectedClient] = useState(null);
	const [distributorFolderInfo, setDistributorFolderInfo] = useState(null);

	const fetch_distributors = async () => {
		const res = await get_distributors_name_list_checkpos();
		setDistributors(res);
	};

	const fetch_mega_distributors = async () => {
		const res = await get_megaDistributors_name_list_checkpos();
		setDistributors(res);
	};

	const fetch_client_list = async () => {
		const res = await get_clients_name_list_filtered();
		setClients(res.filter((c) => c.isApproved && c.isSeller));
	};

	const fetch_generated_file_list = async () => {
		setIsLoading(true);
		try {
			const distributor = distributors.find(
				(d) => d.id === selectedDistributor
			);
			let distributorSendType;
			let folderPath;
			if (selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS) {
				distributorSendType = JSON.parse(distributor.mobileTypeOfArchive).send;
				folderPath =
					distributorSendType === CONSTANTS.TYPE_OF_SEND[0]
						? `${selectedType}/${selectedDistributor}/${distributorSendType}/mobile/${selectedClient}`
						: `${selectedType}/${selectedDistributor}/${distributorSendType}/mobile`;
			} else {
				distributorSendType = JSON.parse(distributor.mobileTypeOfArchive).send;
				folderPath = `${selectedType}/${selectedDistributor}/${distributorSendType}/mobile`;
			}
			const res = await get_generated_file_list(folderPath, folderState);
			setGeneratedFileList(res.generatedFileList);
		} catch (error) {
			setGeneratedFileList(null);
			if (error?.response?.status === 404) {
				return openNotificationWithIcon(
					TYPE.ERROR,
					"Directorio vacío",
					"El criterio de búsqueda no posee archivos para mostrar"
				);
			} else {
				return openNotificationWithIcon(
					TYPE.ERROR,
					"Error de búsqueda",
					"Ha ocurrido un error al intentar realizar la búsqueda, por favor contacte con soporte a soporte@onetransfer.com"
				);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setDistributorFolderInfo(null);
		selectedType &&
			selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
			fetch_distributors();
		selectedType &&
			selectedType === CONSTANTS.FILE_FOLDER_MEGADISTRIBUTORS &&
			fetch_mega_distributors();
		selectedType &&
			selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
			fetch_client_list();
	}, [selectedType]);

	useEffect(() => {
		if (
			(selectedDistributor &&
				selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
				distributorFolderInfo === CONSTANTS.TYPE_OF_SEND[0] &&
				selectedClient) ||
			(selectedDistributor &&
				selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
				distributorFolderInfo !== CONSTANTS.TYPE_OF_SEND[0]) ||
			(selectedDistributor &&
				selectedType === CONSTANTS.FILE_FOLDER_MEGADISTRIBUTORS)
		)
			fetch_generated_file_list();
	}, [selectedDistributor, selectedClient]);

	const columns = [
		{
			title: () => <div style={{ color: COLORS.White }}>Fecha creación</div>,
			dataIndex: "created",
			key: "created",
			width: 200,
			render: (value, record, index) => {
				return (
					<Typo level={6} type="primary">
						{moment(value).format("DD-MM-YYYY")}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}>Nombre archivo</div>,
			dataIndex: "name",
			key: "name",
			render: (value, record, index) => {
				const parts = value.split("/");
				const fileName = parts[parts.length - 1];
				return (
					<Typo level={6} type="primary">
						{fileName}
					</Typo>
				);
			},
		},
		{
			title: () => <div style={{ color: COLORS.White }}></div>,
			dataIndex: "download",
			key: "download",
			width: 100,
			align: "center",
			render: (text, record, index) => (
				<div
					onClick={(e) => handleDownload(record.name)}
					style={{ cursor: "pointer" }}
				>
					<BsDownload color={COLORS.Secondary} />
				</div>
			),
		},
	];

	const handleDownload = async (filePath) => {
		setIsLoading(true);
		try {
			const parts = filePath.split("/");
			const fileName = parts[parts.length - 1];
			const res = await download_file_content(filePath);
			const href = URL.createObjectURL(res);

			const link = document.createElement("a");
			link.href = href;

			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Main>
			<Main.Header>
				<Main.Breadcrumb>
					<Main.BreadcrumbItem>
						<Link to="/">Home</Link>
					</Main.BreadcrumbItem>
					<Main.BreadcrumbSeparator />
					<Main.ActiveBreadcrumbItem>
						Archivos generados
					</Main.ActiveBreadcrumbItem>
				</Main.Breadcrumb>
			</Main.Header>
			<Main.Body>
				<Inner>
					<ImageSection>
						<Typo type="primary" level={5}>
							Archivos Generados
						</Typo>
						<Container>
							<Select
								style={{ width: "200px" }}
								onChange={(e) => {
									setSelectedType(e);
									setSelectedDistributor(null);
									setGeneratedFileList(null);
								}}
								value={selectedType}
								placeholder="Elegir tipo"
							>
								<Option key={1} value={CONSTANTS.FILE_FOLDER_DISTRIBUTORS}>
									Droguería
								</Option>
								<Option key={2} value={CONSTANTS.FILE_FOLDER_MEGADISTRIBUTORS}>
									Distribuidor
								</Option>
							</Select>
							{distributors && selectedType && (
								<Select
									style={{ width: "200px" }}
									onChange={(e) => {
										const distributor = distributors.find((d) => d.id === e);
										const sendType = JSON.parse(
											distributor.mobileTypeOfArchive
										).send;
										setDistributorFolderInfo(sendType);
										setSelectedDistributor(e);
										setSelectedClient(null);
									}}
									placeholder={
										selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS
											? "Elegir droguería"
											: "Elegir distribuidor"
									}
									value={selectedDistributor}
								>
									{distributors?.map((i, idx) => (
										<Option key={`distributor_${idx}`} value={i.id}>
											{i.name}
										</Option>
									))}
								</Select>
							)}
							{distributors &&
								selectedType &&
								selectedDistributor &&
								selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
								distributorFolderInfo === CONSTANTS.TYPE_OF_SEND[0] && (
									<Select
										style={{ width: "200px" }}
										onChange={(e) => {
											setSelectedClient(e);
										}}
										placeholder={"Elegir cliente"}
										value={selectedClient}
									>
										{clients?.map((i, idx) => (
											<Option key={`client_${idx}`} value={i.id}>
												{i.name}
											</Option>
										))}
									</Select>
								)}
						</Container>
						{((selectedDistributor &&
							selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
							distributorFolderInfo === CONSTANTS.TYPE_OF_SEND[0] &&
							selectedClient) ||
							(selectedDistributor &&
								selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
								distributorFolderInfo !== CONSTANTS.TYPE_OF_SEND[0]) ||
							(selectedDistributor &&
								selectedType === CONSTANTS.FILE_FOLDER_MEGADISTRIBUTORS)) && (
							<Container>
								<Typo>Tipo de envío: {distributorFolderInfo}</Typo>
							</Container>
						)}
						{((selectedDistributor &&
							selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
							distributorFolderInfo === CONSTANTS.TYPE_OF_SEND[0] &&
							selectedClient) ||
							(selectedDistributor &&
								selectedType === CONSTANTS.FILE_FOLDER_DISTRIBUTORS &&
								distributorFolderInfo !== CONSTANTS.TYPE_OF_SEND[0]) ||
							(selectedDistributor &&
								selectedType === CONSTANTS.FILE_FOLDER_MEGADISTRIBUTORS)) && (
							<TableContainer>
								<AntdTable
									columns={columns}
									loading={isLoading}
									dataSource={generatedFileList}
									pagination={false}
									scroll={{
										y: 500,
									}}
								/>
							</TableContainer>
						)}
					</ImageSection>
				</Inner>
			</Main.Body>
		</Main>
	);
}

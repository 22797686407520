import { Route, Routes } from "react-router-dom";
import LayoutContainer from "../containers/layout/LayoutContainer";
import {
	BuyerStartGuide,
	Categories,
	Home,
	HotSale,
	Offers,
	RedirectPage,
	SellerStartGuide,
	UsersPage,
} from "../pages/common";
import Search from "../pages/common/search/Search";
import Cart from "../pages/buyer/cart/Cart";
import {
	BrandList,
	CreateBrand,
	CreateProduct,
	DashboardSeller,
	ProductList,
	UpdateBrand,
	UpdateProduct,
	SellerAccountInformation,
	Sales,
	SaleDetail,
	Items,
	Prices,
	PriceHistoryDetail,
	MarketingList,
	LaboratoryStore,
	CreateMarketing,
	UpdateMarketing,
	Billing,
	InteractionReport,
	LaboratoryAuditory,
	LaboratoryCouponsList,
	LaboratoryDistributorResponse,
	GeneralConfig,
	ModuleMarketplace,
	BannerRequestList,
	CreateBannerRequest,
	CreateCombo,
	UpdateCombo,
} from "../pages/seller";
import {
	PointOfSaleList,
	CreatePointOfSale,
	UpdatePointOfSale,
	UpdateTemplates,
	TemplatesList,
	CreateTemplates,
	MyBuys,
	OrderDetail,
	DashboardBuyer,
	PublicityCampaigns,
	BuyedList,
	BookPage,
} from "../pages/buyer";
import AccountInformation from "../pages/buyer/account/update/AccountInformation";
import Stores from "../pages/common/stores/list/Stores";
import Store from "../pages/common/stores/store/Store";
import ProductDetail from "../pages/seller/product-detail/ProductDetail";
import UserProfile from "../pages/common/user-profile/UserProfile";
import {
	ActiveIngredientsList,
	BannersPage,
	CampaignList,
	CategoriesList,
	CreateActiveIngredient,
	CreateCampaign,
	CreateCategory,
	CreateClient,
	CreateDistributor,
	CreateMarketProduct,
	CreateMegaDistributor,
	CreateSaleDepartment,
	DistributorList,
	IndexPage,
	MarketProductsIndex,
	RequestList,
	SaleDepartmentList,
	UpdateActiveIngredient,
	UpdateCampaign,
	UpdateCategory,
	UpdateClient,
	UpdateDistributor,
	UpdateLaboratory,
	UpdateMarketProduct,
	UpdateMegaDistributor,
	UpdateRequest,
	UpdateSaleDepartment,
	Import,
	DashboardOneMarket,
	ClientContract,
	ContractReport,
	MarketClientsIndex,
	Auditory,
	HotSalesPage,
	MarketUsersPage,
	MarketOrders,
	MarketOrderDetail,
	OneMarketSellerPage,
	MobileRequestList,
	UpdateMobileRequest,
	BillersList,
	CreateBiller,
	UpdateBiller,
	ProductPresentationsList,
	CreateMarketProductPresentation,
	UpdateMarketProductPresentation,
	AsignPage,
	MarketPointOfSalePage,
	ClientPosList,
	UpdateClientPointOfSale,
	CreateClientPointOfSale,
	ReportProducts,
	FeaturedIndex,
	GeneralPointsOfSale,
	PointOfSaleHistoryDetail,
	ImportGeneralPos,
	MarketRoutesPage,
	MarketUserRoutesPage,
	ReportBanners,
	MarketUserDiscountsPage,
	SalesReport,
	SendedFiles,
	AuthUsersOneMarket,
	DistributorResponse,
	CreateFeaturedProduct,
	UpdateFeaturedProduct,
	MarketProductsDiscountsPage,
	MarketWebSellersDiscountsPage,
	MarketExpoEventList,
	MarketCreateExpoEvent,
	MarketUpdateExpoEvent,
	MarketCouponsList,
	MarketCreateCoupon,
	MarketUpdateCoupon,
	MarketMobileConfigsPage,
	AccessTypesList,
	CreateAccessType,
	UpdateAccessType,
	AdministrationKeysList,
	CreateAdministrationKey,
	UpdateAdministrationKey,
	MarketGeneralConfig,
	MarketGeneralPos,
	MarketGeneralPosToValidate,
	MarketBannerRequestList,
	CreateMarketComboProduct,
	UpdateMarketComboProduct,
	DistributorResponseCT,
	ModuleOrderStatesCheckpos,
	SendedFilesCT,
	AuditoryCT,
} from "../pages/admin";
import ProtectedRoute from "./ProtectedRoute";
import { useFilter } from "../../contexts/filterContext";

export default function Main({ userdata, clientUser }) {
	const { setUserSellerId } = useFilter();

	const getRedirectPath = () => {
		if (userdata.isSeller && userdata.p_client && userdata.isAdmin) {
			return "/generalConfig";
		}
		if (
			userdata.isOneMarket &&
			!userdata.p_client &&
			!userdata.p_users &&
			!userdata.p_products &&
			userdata.p_dashboard &&
			userdata.p_sales &&
			userdata.p_shop
		) {
			setUserSellerId(clientUser?.id);
		}
		return null;
	};

	const redirectPath = getRedirectPath();

	return (
		<LayoutContainer>
			<Routes>
				<Route
					path="/"
					element={
						<ProtectedRoute
							isAllowed={!redirectPath}
							redirectPath={redirectPath || "/"}
						>
							<Home />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/homeMarketplace"
					element={<ProtectedRoute isAllowed={userdata.isSeller} />}
				>
					<Route index element={<Home />} />
				</Route>

				<Route
					path="/search"
					element={
						<ProtectedRoute
							isAllowed={
								(userdata.p_products && userdata.isSeller) ||
								userdata.p_shop ||
								userdata.isOneMarket
							}
						/>
					}
				>
					<Route index element={<Search />} />
					<Route path="/search/:s" element={<Search />} />
				</Route>

				<Route path="/product">
					<Route path=":id" element={<ProductDetail />} />
				</Route>

				<Route
					path="/hotSale"
					element={
						<ProtectedRoute
							isAllowed={
								(!userdata.isOneMarket && userdata.p_products) ||
								(!userdata.isOneMarket && userdata.p_shop) ||
								(userdata.isOneMarket &&
									userdata.p_products &&
									userdata.p_dashboard &&
									userdata.p_sales)
							}
						/>
					}
				>
					<Route index element={<HotSale />} />
				</Route>

				<Route
					path="/offers"
					element={
						<ProtectedRoute
							isAllowed={
								(userdata.p_products && userdata.isSeller) ||
								userdata.p_shop ||
								userdata.isOneMarket
							}
						/>
					}
				>
					<Route index element={<Offers />} />
					<Route path="/offers/:s" element={<Offers />} />
				</Route>

				<Route path="/categories">
					<Route index element={<Categories />} />
				</Route>

				<Route
					path="/stores"
					element={
						<ProtectedRoute
							isAllowed={
								(userdata.p_products && userdata.isSeller) ||
								userdata.p_shop ||
								userdata.isOneMarket
							}
						/>
					}
				>
					<Route index element={<Stores />} />
					<Route path="/stores/:slug" element={<Store />} />
				</Route>

				<Route
					path="/startGuide"
					element={<ProtectedRoute isAllowed={userdata} />}
				>
					<Route
						index
						element={
							userdata.isOneMarket ? (
								<Home />
							) : userdata.isSeller ? (
								<SellerStartGuide userdata={userdata} />
							) : (
								<BuyerStartGuide userdata={userdata} />
							)
						}
					/>
				</Route>

				<Route
					path="/generalConfig"
					element={
						<ProtectedRoute
							isAllowed={
								userdata &&
								userdata.p_client &&
								(userdata.p_products || userdata.p_users)
							}
						/>
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket ? (
								<MarketGeneralConfig userdata={userdata} />
							) : userdata.isSeller ? (
								<GeneralConfig
									userdata={userdata}
									clientContract={clientUser?.client?.contract}
									clientId={userdata?.clientId}
									clientName={clientUser?.client?.slug}
								/>
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/marketplace"
					element={
						<ProtectedRoute
							isAllowed={userdata && userdata.p_dashboard && userdata.p_sales}
						/>
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket ? (
								<Home />
							) : userdata.isSeller ? (
								<ModuleMarketplace
									userdata={userdata}
									clientContract={clientUser?.client?.contract}
									clientId={userdata?.clientId}
									clientUser={clientUser}
								/>
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/generalPosConfig"
					element={
						<ProtectedRoute isAllowed={userdata && userdata.isOneMarket} />
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket &&
							((userdata.p_client && userdata.p_products) ||
								(!userdata.p_client &&
									!userdata.p_products &&
									userdata.p_dashboard)) ? (
								<MarketGeneralPos userdata={userdata} />
							) : userdata.isSeller ? (
								<Home />
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route
					path="/generalPosToValidate"
					element={
						<ProtectedRoute isAllowed={userdata && userdata.isOneMarket} />
					}
				>
					<Route
						index
						element={
							userdata.isOneMarket &&
							((userdata.p_client && userdata.p_products) ||
								(!userdata.p_client &&
									!userdata.p_products &&
									userdata.p_dashboard)) ? (
								<MarketGeneralPosToValidate userdata={userdata} />
							) : userdata.isSeller ? (
								<Home />
							) : (
								<Home />
							)
						}
					/>
				</Route>

				<Route path="/redirect" element={<RedirectPage />}>
					<Route index path=":uri" element={<RedirectPage />} />
				</Route>

				{/* <Route
					path="/book"
					element={
						<ProtectedRoute
							isAllowed={
								!userdata.isOneMarket &&
								userdata.p_products &&
								!userdata.isSeller
							}
						/>
					}
				>
					<Route index element={<BookPage userdata={userdata} />} />
					<Route
						exact
						path="smartbuy"
						element={
							<ProtectedRoute
								redirectPath="/book"
								isAllowed={userdata && userdata.p_shop}
							>
								<SmartBuy />
							</ProtectedRoute>
						}
					/>
				</Route> */}

				<Route
					exact
					path="/cart"
					element={
						<ProtectedRoute
							redirectPath="/"
							isAllowed={userdata && userdata.p_shop}
						>
							<Cart />
						</ProtectedRoute>
					}
				/>

				<Route
					exact
					path="/marketSellerPage"
					element={
						<ProtectedRoute
							redirectPath="/"
							isAllowed={userdata.isOneMarket && userdata.p_shop}
						>
							<OneMarketSellerPage />
						</ProtectedRoute>
					}
				/>

				<Route exact path="/profile" element={<UserProfile />} />

				<Route
					path="/admin/*"
					element={
						<Routes>
							<Route
								path="/items"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<Items />} />
							</Route>

							<Route
								path="/products"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<ProductList />} />
								<Route exact path="create" element={<CreateProduct />} />
								<Route path=":id" element={<UpdateProduct />} />
								<Route
									exact
									path="createCombo"
									element={<CreateCombo clientId={userdata?.clientId} />}
								/>
								<Route
									path="/products/combo/:id"
									element={<UpdateCombo clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/prices"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<Prices />} />
								<Route path=":id" element={<PriceHistoryDetail />} />
							</Route>

							<Route
								path="/store"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<LaboratoryStore />} />
							</Route>

							<Route
								path="/brands"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_products}
									/>
								}
							>
								<Route index element={<BrandList />} />
								<Route exact path="create" element={<CreateBrand />} />
								<Route path=":id" element={<UpdateBrand />} />
							</Route>

							<Route
								path="/ventas"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_sales}
									/>
								}
							>
								<Route
									index
									element={<Sales clientId={userdata?.clientId} />}
								/>
								<Route
									path=":id"
									element={<SaleDetail clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/laboratoryAuditory"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_sales}
									/>
								}
							>
								<Route
									index
									element={<LaboratoryAuditory clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/interactionReport"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_sales}
									/>
								}
							>
								<Route
									index
									element={<InteractionReport clientId={userdata?.clientId} />}
								/>
							</Route>

							<Route
								path="/templates"
								element={
									<ProtectedRoute
										isAllowed={!userdata.isSeller && userdata.p_shop}
									/>
								}
							>
								<Route index element={<TemplatesList />} />
								<Route exact path="create" element={<CreateTemplates />} />
								<Route path=":id" element={<UpdateTemplates />} />
							</Route>

							<Route
								path="/pointsofsale"
								element={
									<ProtectedRoute
										isAllowed={!userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={<PointOfSaleList clientUser={clientUser} />}
								/>
								<Route
									exact
									path="create"
									element={<CreatePointOfSale clientUser={clientUser} />}
								>
									<Route
										path=":uri"
										element={<CreatePointOfSale clientUser={clientUser} />}
									/>
								</Route>
								<Route
									path=":id"
									element={<UpdatePointOfSale clientUser={clientUser} />}
								/>
							</Route>

							<Route
								path="/users"
								element={
									<ProtectedRoute isAllowed={userdata && userdata.p_users} />
								}
							>
								<Route
									index
									element={<UsersPage clientId={userdata.clientId} />}
								/>
							</Route>

							<Route
								path="/compras"
								element={
									<ProtectedRoute
										isAllowed={!userdata.isSeller && userdata.p_sales}
									/>
								}
							>
								<Route index element={<MyBuys />} />
								<Route path=":id" element={<OrderDetail />} />
							</Route>

							<Route
								path="/buyedList"
								element={
									<ProtectedRoute
										isAllowed={!userdata.isSeller && userdata.p_shop}
									/>
								}
							>
								<Route index element={<BuyedList />} />
							</Route>

							<Route
								path="/dashboard"
								element={
									<ProtectedRoute
										isAllowed={
											userdata &&
											((!userdata.isOneMarket &&
												userdata.p_dashboard &&
												userdata.p_sales) ||
												(userdata.isOneMarket &&
													userdata.p_products &&
													userdata.p_dashboard &&
													userdata.p_sales) ||
												(userdata.isOneMarket &&
													userdata.p_client &&
													userdata.p_products &&
													userdata.p_sales) ||
												(userdata.isOneMarket &&
													userdata.p_dashboard &&
													userdata.p_sales &&
													!userdata.p_shop &&
													!userdata.p_mobile))
										}
									/>
								}
							>
								<Route
									index
									element={
										userdata.isOneMarket ? (
											<DashboardOneMarket />
										) : userdata.isSeller ? (
											<DashboardSeller
												clientId={userdata?.clientId}
												clientUser={clientUser}
											/>
										) : (
											<DashboardBuyer clientId={userdata?.clientId} />
										)
									}
								/>
							</Route>

							<Route
								path="/productReport"
								element={
									<ProtectedRoute
										isAllowed={
											userdata && userdata.p_dashboard && userdata.p_sales
										}
									/>
								}
							>
								<Route index element={<ReportProducts />} />
							</Route>

							<Route
								path="/bannerReport"
								element={
									<ProtectedRoute
										isAllowed={
											userdata &&
											userdata.p_dashboard &&
											userdata.p_sales &&
											userdata.isOneMarket
										}
									/>
								}
							>
								<Route index element={<ReportBanners />} />
							</Route>

							<Route
								path="/salesReport"
								element={
									<ProtectedRoute
										isAllowed={
											userdata && userdata.p_dashboard && userdata.p_sales
										}
									/>
								}
							>
								<Route index element={<SalesReport />} />
							</Route>

							<Route path="/marketing">
								<Route
									index
									element={
										userdata.isSeller ? (
											<ProtectedRoute
												redirectPath="/"
												isAllowed={
													userdata && userdata.p_users && userdata.p_products
												}
											>
												<MarketingList />
											</ProtectedRoute>
										) : (
											<ProtectedRoute
												redirectPath="/"
												isAllowed={userdata && userdata.p_shop}
											>
												<PublicityCampaigns />
											</ProtectedRoute>
										)
									}
								/>
								<Route exact path="create" element={<CreateMarketing />} />
								<Route path=":id" element={<UpdateMarketing />} />
							</Route>

							<Route path="/bannerRequest">
								<Route
									index
									element={
										<ProtectedRoute
											redirectPath="/"
											isAllowed={
												userdata &&
												userdata.isSeller &&
												userdata.p_users &&
												userdata.p_products
											}
										>
											<BannerRequestList clientId={userdata?.clientId} />
										</ProtectedRoute>
									}
								/>
								<Route
									exact
									path="create"
									element={
										<CreateBannerRequest clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route path="/marketBannerRequests">
								<Route
									index
									element={
										<ProtectedRoute
											redirectPath="/"
											isAllowed={userdata && userdata.isOneMarket}
										>
											<MarketBannerRequestList />
										</ProtectedRoute>
									}
								/>
							</Route>

							<Route
								path="/account"
								element={
									<ProtectedRoute isAllowed={userdata && userdata.p_client} />
								}
							>
								<Route
									index
									element={
										userdata.isSeller ? (
											<SellerAccountInformation />
										) : (
											<AccountInformation />
										)
									}
								/>
								<Route
									exact
									path="/account/billing"
									element={
										userdata?.isSeller ? (
											<Billing clientId={userdata?.clientId} />
										) : (
											<Home />
										)
									}
								/>
							</Route>

							<Route
								path="/distributors"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<DistributorList />} />
								<Route exact path="create" element={<CreateDistributor />} />
								<Route path=":id" element={<UpdateDistributor />} />
								<Route exact path="asign" element={<AsignPage />} />
							</Route>

							<Route
								path="/generalPointsOfSale"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_products &&
											userdata.p_dashboard
										}
									/>
								}
							>
								<Route index element={<GeneralPointsOfSale />} />
								<Route path=":id" element={<PointOfSaleHistoryDetail />} />
							</Route>

							<Route
								path="/saledepartments"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<SaleDepartmentList />} />
								<Route exact path="create" element={<CreateSaleDepartment />} />
								<Route path=":id" element={<UpdateSaleDepartment />} />
							</Route>

							<Route
								path="/categories"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<CategoriesList />} />
								<Route exact path="create" element={<CreateCategory />} />
								<Route path=":id" element={<UpdateCategory />} />
							</Route>

							<Route
								path="/activeIngredients"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<ActiveIngredientsList />} />
								<Route
									exact
									path="create"
									element={<CreateActiveIngredient />}
								/>
								<Route path=":id" element={<UpdateActiveIngredient />} />
							</Route>

							<Route
								path="/billers"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<BillersList />} />
								<Route exact path="create" element={<CreateBiller />} />
								<Route path=":id" element={<UpdateBiller />} />
							</Route>

							<Route
								path="/administrationAccess"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<AccessTypesList />} />
								<Route exact path="create" element={<CreateAccessType />} />
								<Route path=":id" element={<UpdateAccessType />} />
							</Route>

							<Route
								path="/administrationKeys"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<AdministrationKeysList />} />
								<Route
									exact
									path="create"
									element={<CreateAdministrationKey />}
								/>
								<Route path=":id" element={<UpdateAdministrationKey />} />
							</Route>

							<Route
								path="/marketProductsIndex"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketProductsIndex />} />
								<Route
									exact
									path="createMarketProduct"
									element={<CreateMarketProduct />}
								/>
								<Route
									exact
									path="createMarketComboProduct"
									element={<CreateMarketComboProduct />}
								/>
								<Route
									exact
									path="/marketProductsIndex/marketProduct/:id"
									element={<UpdateMarketProduct />}
								/>
								<Route
									exact
									path="/marketProductsIndex/marketComboProduct/:id"
									element={<UpdateMarketComboProduct />}
								/>
							</Route>

							<Route
								path="/presentations"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<ProductPresentationsList />} />
								<Route
									exact
									path="create"
									element={<CreateMarketProductPresentation />}
								/>
								<Route
									path=":id"
									element={<UpdateMarketProductPresentation />}
								/>
							</Route>

							<Route
								path="/clients"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket && userdata.p_users) ||
											(userdata.isOneMarket &&
												userdata.p_client &&
												userdata.p_products &&
												userdata.p_sales)
										}
									/>
								}
							>
								<Route index element={<MarketClientsIndex />} />
								<Route exact path="create" element={<CreateClient />} />
								<Route path=":id" element={<UpdateClient />} />
								<Route
									exact
									path="/clients/contractEdit/:id"
									element={<ClientContract />}
								/>
								<Route
									exact
									path="/clients/contractReport/:id"
									element={<ContractReport />}
								/>
							</Route>

							<Route
								path="/marketOrders"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_sales}
									/>
								}
							>
								<Route index element={<MarketOrders />} />
								<Route path=":id" element={<MarketOrderDetail />} />
							</Route>

							<Route
								path="/marketPos"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket && userdata.p_users) ||
											(userdata.isOneMarket &&
												userdata.p_client &&
												userdata.p_products &&
												userdata.p_sales)
										}
									/>
								}
							>
								<Route index element={<MarketPointOfSalePage />} />
								<Route
									exact
									path="/marketPos/clientPos/:id"
									element={<ClientPosList />}
								/>
								<Route
									exact
									path="/marketPos/clientPointOfSale/:id"
									element={<UpdateClientPointOfSale />}
								/>
								<Route
									exact
									path="/marketPos/clientPointOfSale/create"
									element={<CreateClientPointOfSale />}
								/>
							</Route>

							<Route
								path="/marketRequests"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											((userdata.p_client && userdata.p_products) ||
												(!userdata.p_client &&
													!userdata.p_products &&
													userdata.p_dashboard))
										}
									/>
								}
							>
								<Route index element={<RequestList />} />
								<Route path=":id" element={<UpdateRequest />} />
							</Route>

							<Route
								path="/mobileRequests"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											((userdata.p_client && userdata.p_products) ||
												(!userdata.p_client &&
													!userdata.p_products &&
													userdata.p_dashboard))
										}
									/>
								}
							>
								<Route index element={<MobileRequestList />} />
								<Route path=":id" element={<UpdateMobileRequest />} />
							</Route>

							<Route
								path="/marketUsers"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket && userdata.p_users) ||
											(userdata.isOneMarket &&
												userdata.p_client &&
												userdata.p_products &&
												userdata.p_sales)
										}
									/>
								}
							>
								<Route
									index
									element={
										<MarketUsersPage
											userdata={userdata}
											clientUser={clientUser}
										/>
									}
								/>
							</Route>

							<Route
								path="/import"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<Import />} />
							</Route>

							<Route
								path="/marketWebSellers"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketWebSellersDiscountsPage />} />
							</Route>

							<Route
								path="/importGeneralPos"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_products &&
											userdata.p_dashboard
										}
									/>
								}
							>
								<Route index element={<ImportGeneralPos />} />
							</Route>

							<Route
								path="/marketRoutes"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketRoutesPage />} />
							</Route>

							<Route
								path="/marketUserRoutes"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketUserRoutesPage />} />
							</Route>

							<Route
								path="/marketUserDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketUserDiscountsPage />} />
							</Route>

							<Route
								path="/marketProductDiscounts"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketProductsDiscountsPage />} />
							</Route>

							<Route
								path="/marketMobileConfigs"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketMobileConfigsPage />} />
							</Route>

							<Route
								path="/laboratoryCoupons"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryCouponsList clientId={userdata?.clientId} />
									}
								/>
							</Route>

							<Route
								path="/featuredSettings"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<FeaturedIndex />} />
								<Route
									exact
									path="/featuredSettings/createFeaturedProducts"
									element={<CreateFeaturedProduct />}
								/>
								<Route
									exact
									path="/featuredSettings/featuredProduct/:id"
									element={<UpdateFeaturedProduct />}
								/>
							</Route>

							<Route
								path="/advertising/*"
								element={
									<Routes>
										<Route
											path="/campaigns"
											element={
												<ProtectedRoute
													isAllowed={userdata.isOneMarket && userdata.p_client}
												/>
											}
										>
											<Route index element={<CampaignList />} />
											<Route
												exact
												path="createCampaign"
												element={<CreateCampaign />}
											/>
											<Route path=":id" element={<UpdateCampaign />} />
										</Route>

										<Route path="*" element={<div>404</div>} />
									</Routes>
								}
							/>

							<Route
								path="/distributorsIndex"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<IndexPage />} />
								<Route
									exact
									path="createMegaDistributor"
									element={<CreateMegaDistributor />}
								/>
								<Route
									exact
									path="/distributorsIndex/megaDistributor/:id"
									element={<UpdateMegaDistributor />}
								/>
								<Route
									exact
									path="/distributorsIndex/laboratory/:id"
									element={<UpdateLaboratory />}
								/>
							</Route>

							<Route
								path="/marketBannersIndex"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<BannersPage />} />
							</Route>

							<Route
								path="/marketHotSalesIndex"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<HotSalesPage />} />
							</Route>

							<Route
								path="/auditory"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket &&
												userdata.p_products &&
												userdata.p_dashboard &&
												userdata.p_sales) ||
											(userdata.isOneMarket &&
												userdata.p_dashboard &&
												userdata.p_sales &&
												!userdata.p_shop &&
												!userdata.p_mobile)
										}
									/>
								}
							>
								<Route index element={<Auditory />} />
							</Route>

							<Route
								path="/auditoryCT"
								element={
									<ProtectedRoute
										isAllowed={
											(userdata.isOneMarket &&
												userdata.p_products &&
												userdata.p_dashboard &&
												userdata.p_sales) ||
											(userdata.isOneMarket &&
												userdata.p_dashboard &&
												userdata.p_sales &&
												!userdata.p_shop &&
												!userdata.p_mobile)
										}
									/>
								}
							>
								<Route index element={<AuditoryCT />} />
							</Route>

							<Route
								path="/sendedFiles"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<SendedFiles />} />
							</Route>

							<Route
								path="/sendedFilesCT"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<SendedFilesCT />} />
							</Route>

							<Route
								path="/marketAuths"
								element={
									<ProtectedRoute
										isAllowed={
											userdata.isOneMarket &&
											userdata.p_client &&
											userdata.p_users
										}
									/>
								}
							>
								<Route index element={<AuthUsersOneMarket />} />
							</Route>

							<Route
								path="/distributorResponse"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<DistributorResponse />} />
							</Route>

							<Route
								path="/distributorResponseCT"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<DistributorResponseCT />} />
							</Route>

							<Route
								path="/orderStateCT"
								element={<ProtectedRoute isAllowed={userdata.isOneMarket} />}
							>
								<Route index element={<ModuleOrderStatesCheckpos />} />
							</Route>

							<Route
								path="/laboratoryDistributorResponse"
								element={
									<ProtectedRoute
										isAllowed={userdata.isSeller && userdata.p_client}
									/>
								}
							>
								<Route
									index
									element={
										<LaboratoryDistributorResponse
											clientId={userdata?.clientId}
										/>
									}
								/>
							</Route>

							<Route
								path="/expoEventOT"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketExpoEventList />} />
								<Route
									exact
									path="create"
									element={<MarketCreateExpoEvent />}
								/>
								<Route path=":id" element={<MarketUpdateExpoEvent />} />
							</Route>

							<Route
								path="/marketCoupons"
								element={
									<ProtectedRoute
										isAllowed={userdata.isOneMarket && userdata.p_client}
									/>
								}
							>
								<Route index element={<MarketCouponsList />} />
								<Route exact path="create" element={<MarketCreateCoupon />} />
								<Route path=":id" element={<MarketUpdateCoupon />} />
							</Route>

							<Route path="*" element={<div>404</div>} />
						</Routes>
					}
				/>

				<Route
					path="*"
					element={
						<main style={{ padding: "1rem" }}>
							<p>There's nothing here!</p>
						</main>
					}
				/>
			</Routes>
		</LayoutContainer>
	);
}

import React from "react";
import { AntdTable } from "./styles";
import LoadingSkeleton from "../loading-skeleton/LoadingSkeleton";

export default function OrdersTable({ data, columns, ...props }) {
	if (!data) return <LoadingSkeleton />;

	return (
		<>
			<AntdTable columns={columns} dataSource={data} {...props} />
		</>
	);
}

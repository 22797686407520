import axiosInstance from "../axiosInstance";

const CONTROLLER = "Auditory";

const get_auditory = async (month, year, client) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetAuditory?month=${month}&year=${year}&client=${client}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_generated_file_list = async (folderPath, folderName) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetGeneratedFileList?folderPath=${folderPath}&folderName=${folderName}`
	);
	return response.data;
};

const download_file_content = async (filePath) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/DownloadFileContent?filePath=${filePath}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

const get_auditory_checkpos = async (month, year, client) => {
	const response = await axiosInstance.get(
		`${CONTROLLER}/GetAuditoryCT?month=${month}&year=${year}&client=${client}`,
		{
			responseType: "blob",
		}
	);
	return response.data;
};

export {
	get_auditory,
	get_generated_file_list,
	download_file_content,
	get_auditory_checkpos,
};

import React from "react";
import Box from "../box/Box";
import { COLORS, Typo } from "../../../../../layout/theme";
import { BiCart, BiCoinStack, BiDollar, BiPurchaseTag } from "react-icons/bi";
import { MdSafetyDivider } from "react-icons/md";
import {
	AntdTable,
	BigChartContainer,
	Container,
	Dashboard1Container,
	DoubleChartContainer,
	FilterContainer,
	HeaderContainer,
	Section,
} from "./styles";
import { Button, DatePicker, Select } from "antd";
import { numberWithDotAndCommas } from "../../../../../../utils/numberWithDotAndComas";
import { CONSTANTS } from "../../../../../../utils/constants";
import { Link } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

export default function Dashboard4({
	dashboard,
	distributors,
	selectDistributor,
	optionsPie,
	provinces,
	selectProvince,
	laboratories,
	selectLaboratory,
	pharmacies,
	selectPharmacy,
	handleDateChange,
	deleteFilters,
	isLoading,
	selectedLaboratory,
	selectedDistributor,
	selectedProvince,
	selectedPharmacy,
	timeTotalSales,
	laboratoryUnitsGraph,
	laboratorySalesGraph,
	laboratoryGraphSelector,
	selectLaboratoryGraph,
	productUnitsGraph,
	productSalesGraph,
	productGraphSelector,
	selectProductGraph,
	selectDealsInfo,
	dealsInfoSelector,
	pharmaciesTable,
	rangeDate,
	setMarketOrdersPharmacy,
	handleDownloadPharmacyTable,
}) {
	const columns = [
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Nombre</div>,
			width: 350,
			dataIndex: "pharmacyName",
			render: (value, record) =>
				selectedPharmacy ? (
					<div
						style={{ cursor: "pointer" }}
						onClick={() => {
							setMarketOrdersPharmacy(record.pharmacyId);
						}}
					>
						<Link to="/admin/marketOrders">
							<Typo level={5}>{value}</Typo>
						</Link>
					</div>
				) : (
					<div
						style={{ cursor: "pointer" }}
						onClick={() => {
							selectPharmacy(record.pharmacyId, { label: value });
							window.scrollTo(0, 0);
						}}
					>
						<Typo level={5}>{value}</Typo>
					</div>
				),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Provincia</div>,
			width: 400,
			dataIndex: "provinceName",
			render: (value, record) => (
				<Typo level={5} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => <div style={{ color: COLORS.DarkGrey }}>Localidad</div>,
			width: 400,
			dataIndex: "localityName",
			render: (value, record) => (
				<Typo level={5} type="secondary">
					{value}
				</Typo>
			),
		},
		{
			title: () => (
				<div style={{ color: COLORS.DarkGrey }}>Ordenes totales</div>
			),
			dataIndex: "totalOrders",
			render: (value, record) => (
				<Typo type="primary" level={3}>
					{value}
				</Typo>
			),
		},
		// {
		// 	title: () => (
		// 		<Section>
		// 			<div style={{ color: COLORS.DarkGrey }}>Mobile</div>
		// 			<DownloadOutlined
		// 				onClick={handleDownloadPharmacyTable}
		// 				style={{ color: COLORS.Warning, cursor: "pointer" }}
		// 			/>
		// 		</Section>
		// 	),
		// 	dataIndex: "totalMobileOrders",
		// 	render: (value, record) => (
		// 		<Typo type="muted" level={3}>
		// 			{value}
		// 		</Typo>
		// 	),
		// },
		// {
		// 	title: () => <div style={{ color: COLORS.DarkGrey }}>Web</div>,
		// 	dataIndex: "totalWebOrders",
		// 	render: (value, record) => (
		// 		<Typo type="muted" level={3}>
		// 			{value}
		// 		</Typo>
		// 	),
		// },
	];

	return (
		<Dashboard1Container>
			<FilterContainer>
				<Container>
					<Typo type="primary" level={6}>
						Fecha (desde/hasta)
					</Typo>
					{/* <DatePicker.RangePicker onChange={(e) => handleDateChange(e)} /> */}
					{rangeDate ? (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							value={rangeDate}
							onChange={(e) => handleDateChange(e)}
						/>
					) : (
						<DatePicker.RangePicker
							format="DD/MM/YYYY"
							onChange={(e) => handleDateChange(e)}
						/>
					)}
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Droguería
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Droguería"
						optionFilterProp="children"
						value={selectedDistributor}
						// defaultValue={selectedDistributor ?? null}
						onChange={(e, o) => selectDistributor(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={distributors}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Provincia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Provincia"
						optionFilterProp="children"
						value={selectedProvince}
						// defaultValue={selectedProvince ?? null}
						onChange={(e, o) => selectProvince(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={provinces}
						style={{ width: 220 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Farmacia
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Farmacia"
						optionFilterProp="children"
						value={selectedPharmacy}
						// defaultValue={selectedPharmacy ?? null}
						onChange={(e, o) => selectPharmacy(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={pharmacies}
						style={{ width: 180 }}
					/>
				</Container>
				<Container>
					<Typo type="primary" level={6}>
						Laboratorio
					</Typo>
					<Select
						showSearch
						//allowClear
						placeholder="Laboratorio"
						optionFilterProp="children"
						value={selectedLaboratory}
						// defaultValue={selectedLaboratory ?? null}
						onChange={(e, o) => selectLaboratory(e, o)}
						filterOption={(input, option) =>
							(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
						}
						options={laboratories}
						style={{ width: 180 }}
					/>
				</Container>
				<Container style={{ justifyContent: "flex-end", width: "160px" }}>
					<Button type="primary" onClick={deleteFilters}>
						Borrar filtros
					</Button>
				</Container>
			</FilterContainer>
			<HeaderContainer>
				<Box
					data={
						dealsInfoSelector === "SALES"
							? numberWithDotAndCommas(dashboard?.totalSales?.toFixed(0))
							: dealsInfoSelector === "DEALS"
							? numberWithDotAndCommas(dashboard?.totalDeals?.toFixed(0))
							: 0
					}
					dataPrefix={"$"}
					secondData={
						dealsInfoSelector === "SALES"
							? numberWithDotAndCommas(dashboard?.actualMonthSales?.toFixed(0))
							: dealsInfoSelector === "DEALS"
							? numberWithDotAndCommas(dashboard?.actualMonthDeals?.toFixed(0))
							: 0
					}
					secondDataPrefix={"$"}
					monthName={CONSTANTS.CALENDAR_MONTHS[dashboard?.actualMonth]}
					isLoading={isLoading}
					type="headerBoxPharmacy"
					headerSubTitle1={
						dealsInfoSelector === "SALES"
							? "Compras: "
							: dealsInfoSelector === "DEALS"
							? "Ahorros: "
							: ""
					}
					headerSubTitle2={"Mes Actual: "}
					selectDealsInfo={selectDealsInfo}
					dealsInfo={dealsInfoSelector}
					iconOption={<BiCart size={20} />}
					iconOption2={<BiPurchaseTag size={20} />}
				/>
				<Box
					isLoading={isLoading}
					graphData={timeTotalSales}
					type="headerBoxPharmacy"
					title={
						<Typo texto="light" type="primary">
							Histórico compras:{" "}
						</Typo>
					}
				/>
				<Box
					type="pieHeaderBoxPharmacy"
					options={optionsPie}
					isLoading={isLoading}
					prefix={<MdSafetyDivider />}
					title={
						<Typo texto="light" type="primary">
							Droguerías: pedidos
						</Typo>
					}
				/>
			</HeaderContainer>
			<DoubleChartContainer>
				<Box
					type="timeChartMultipleLaboratory"
					options={laboratorySalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={laboratoryUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					selectLaboratoryGraph={selectLaboratoryGraph}
					laboratoryGraph={laboratoryGraphSelector}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{laboratoryGraphSelector === "SALES"
								? "Compras por laboratorio"
								: laboratoryGraphSelector === "UNITS"
								? "Unidades por laboratorio"
								: ""}
						</Typo>
					}
				/>
				<Box
					type="timeChartMultipleProduct"
					options={productSalesGraph}
					iconOption={<BiDollar size={20} />}
					option2={productUnitsGraph}
					iconOption2={<BiCoinStack size={20} />}
					selectProductGraph={selectProductGraph}
					productGraph={productGraphSelector}
					isLoading={isLoading}
					title={
						<Typo texto="light" type="primary">
							{productGraphSelector === "SALES"
								? "Compras por producto"
								: productGraphSelector === "UNITS"
								? "Unidades por producto"
								: ""}
						</Typo>
					}
				/>
			</DoubleChartContainer>
			<BigChartContainer>
				<AntdTable
					dataSource={pharmaciesTable}
					loading={isLoading}
					columns={columns}
					pagination={false}
					scroll={{ y: "1020px" }}
				/>
			</BigChartContainer>
		</Dashboard1Container>
	);
}
